import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { reference } from 'store/utils/reference'
import { SkillLevel } from '../skill-levels'

export const Outcome = baseModel('outcomes')
  .props({
    content: types.string,
    listPosition: types.optional(types.number, 1000000, [null, undefined]),
    skillLevel: reference(SkillLevel),
  })
  .views((self) => ({
    get level() {
      return self.skillLevel?.level
    },
    get skill() {
      return self.skillLevel?.skill
    },
    get skillVariant() {
      return self.skillLevel?.skillVariant
    },
  }))

export interface Outcome extends Instance<typeof Outcome> {}
export interface OutcomeAttributes extends SnapshotIn<typeof Outcome> {}

export type ModifiableOutcomeAttributes = {
  content: string
  listPosition?: number
  skillLevel?: string
}

type OutcomeFilters = {
  level?: number | number[]
  skill_level_id?: string | string[]
  updated_since?: Date | null
}

type OutcomeStoreOptions = {
  CreateAttributes: ModifiableOutcomeAttributes
  Filters: OutcomeFilters
  UpdateAttributes: Partial<ModifiableOutcomeAttributes>
}

export const OutcomeStore = createStore<typeof Outcome, OutcomeStoreOptions>(
  'Outcome',
  Outcome
).views((store) => ({
  forLevelAndSkillVariant(level: number, skillVariantId: string) {
    return store
      .filtered(
        (outcome) =>
          outcome.level === level && outcome.skillVariant?.id === skillVariantId
      )
      .sort((a, b) => {
        return a.listPosition === b.listPosition
          ? a.createdAt < b.createdAt
            ? -1
            : 1
          : a.listPosition < b.listPosition
          ? -1
          : 1
      })
  },
}))
