import * as React from 'react'
import cn from 'classnames'
import styles from './Avatar.module.scss'

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type AvatarShape = 'circle' | 'square'

export type AvatarProps = {
  size?: AvatarSize
  shape?: AvatarShape
  fallbackText: string
  isInactive?: boolean
  fallbackWrapperProps?: React.ComponentPropsWithoutRef<'div'>
  src?: string | null
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'children' | 'src'>

export const Avatar: React.VFC<AvatarProps> = (props) => {
  const {
    className,
    size = 'md',
    shape = 'circle',
    fallbackText,
    isInactive,
    alt,
    src,
    fallbackWrapperProps,
    ...restProps
  } = props

  const sharedClasses = cn(
    styles.avatar,
    styles[size],
    styles[shape],
    isInactive && styles.isInactive,
    className
  )

  return (
    <div className="relative">
      <div
        {...fallbackWrapperProps}
        className={cn(
          sharedClasses,
          styles.fallback,
          fallbackWrapperProps?.className
        )}
      >
        {alt && !src && <span className="sr-only">{alt}</span>}
        <span aria-hidden>{fallbackText}</span>
      </div>
      {src && (
        <img
          data-fallback={fallbackText}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.style.display = 'none'
          }}
          className={cn(sharedClasses, styles.img)}
          src={src}
          alt={alt}
          {...restProps}
        />
      )}
    </div>
  )
}
