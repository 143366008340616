import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'
import { User } from '../users'

export const FeedbackRequest = baseModel('feedback_requests').props({
  content: types.string,
  creator: reference(User, { required: true }),
  skills: types.array(reference(Skill, { required: true })),
  users: types.array(reference(User, { required: true })),
})

type FeedbackRequestCreateAttributes = {
  content: string
  skills?: string[]
  users: string[]
}

export type FeedbackRequestFilters = {
  created_at_from?: string
  created_at_to?: string
  updated_since?: Date | null
}

export interface FeedbackRequest extends Instance<typeof FeedbackRequest> {}
export interface FeedbackRequestAttributes
  extends SnapshotIn<typeof FeedbackRequest> {}

type FeedbackRequestStoreOptions = {
  CreateAttributes: FeedbackRequestCreateAttributes
  Filter?: FeedbackRequestFilters
}

export const FeedbackRequestStore = createStore<
  typeof FeedbackRequest,
  FeedbackRequestStoreOptions
>('FeedbackRequest', FeedbackRequest)
