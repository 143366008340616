import * as React from 'react'
import cn from 'classnames'
import type { DivPropsWithoutChildren } from '../../../types/helpers'
import { EditableFormContext } from './EditableFormContext'
import styles from './EditableForm.module.scss'

export type InsertContentProps = {
  children: React.ReactNode
} & DivPropsWithoutChildren

export const InsertContent: React.VFC<InsertContentProps> = (props) => {
  const { children, className, ...restProps } = props

  const { mode, disabled } = React.useContext(EditableFormContext)

  if (mode !== 'insert' || disabled) return null

  return (
    <div className={cn(styles.insertContent, className)} {...restProps}>
      {children}
    </div>
  )
}
