import { z } from 'zod'

export type SchemaVersions = Record<string, string>
export const fetchSchemaVersions = async (): Promise<SchemaVersions> => {
  const response = await fetch('/api/v1/schema_versions')
  if (!response.ok) return {}
  const json = await response.json()
  const parsed = z.record(z.string()).safeParse(json)

  return parsed.success ? parsed.data : {}
}
