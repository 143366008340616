import * as currencies from '@dinero.js/currencies'

const currencyCodes = { ...currencies }

export class Currency {
  static dollarsFromCents(
    code: keyof typeof currencyCodes,
    cents?: number | null
  ) {
    const { exponent, base: bases } = currencyCodes[code]
    const base = Array.isArray(bases) ? bases[0] : bases
    return cents ? cents / base ** exponent : undefined
  }

  static centsFromDollars(
    code: keyof typeof currencyCodes,
    dollars?: number | null
  ) {
    if (!dollars) return dollars
    const { exponent, base: bases } = currencyCodes[code]
    const base = Array.isArray(bases) ? bases[0] : bases
    return dollars * base ** exponent
  }

  constructor(
    public readonly code: keyof typeof currencyCodes,
    public cents?: number | null
  ) {}

  get dollars() {
    return Currency.dollarsFromCents(this.code, this.cents)
  }

  formatted() {
    if (!this.dollars) return ''
    const minimumFractionDigits = this.dollars % 1 == 0 ? 0 : 2
    const maximumFractionDigits = minimumFractionDigits == 0 ? 0 : undefined
    return new Intl.NumberFormat(navigator.language || 'en-GB', {
      style: 'currency',
      currency: this.code,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }).format(this.dollars)
  }
}
