import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { reference } from 'store/utils/reference'
import { Position } from '../positions'
import { Skill } from '../skills'
import { SkillVariant } from '../skill-variants'

export const PositionSkill = baseModel('position_skills').props({
  categoryName: types.maybeNull(types.string),
  level: types.number,
  position: reference(Position, {
    required: true,
  }),
  skill: reference(Skill, { required: true }),
  skillVariant: reference(SkillVariant, { required: true }),
})

export interface PositionSkill extends Instance<typeof PositionSkill> {}

export interface PositionSkillAttributes
  extends SnapshotIn<typeof PositionSkill> {}

type PositionSkillStoreOptions = {
  Include: Array<'skill' | 'skill_variant' | 'position'>
}

export const PositionSkillStore = createStore<
  typeof PositionSkill,
  PositionSkillStoreOptions
>('PositionSkill', PositionSkill)
  .actions((store) => ({
    async fetchForPosition(positionId: string) {
      await store.fetchAll(
        { include: ['skill', 'skill_variant'] },
        { namespace: ['positions', positionId] }
      )
    },
  }))
  .views((store) => ({
    forPosition(positionId: string) {
      return store.filtered(
        (positionSkill) => positionSkill.position.id === positionId
      )
    },
  }))
