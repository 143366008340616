import * as React from 'react'

export type DrawerContextValue = {
  activeDrawerId: null | string
  activeDrawerProps: null | unknown
  openDrawer: <Props extends Record<string, unknown>>(
    id: string,
    props?: Props
  ) => void
  closeDrawer(): void
  toggleDrawer: (id: string) => void
  getDrawerProps<T extends Record<string, unknown>>(
    drawerId: string
  ): T | { [k in any]: never }
}

export const DrawerContext = React.createContext<DrawerContextValue>(
  {} as DrawerContextValue
)

export const useDrawerContext = () => {
  const context = React.useContext(DrawerContext)

  if (!context)
    throw new Error(
      'useDrawerContext must be used within a DrawerContextValue or RootProvider'
    )

  return context
}
