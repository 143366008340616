import * as React from 'react'
import type { TCollection, TSkill } from '../../../../../types/entities'
import type { SkillType } from '../../SkillsDrawer/SkillsDrawer.types'

type DragAndDropContextProps = {
  draggedSkillId: string | null
  draggedSkillVariantId: string | null
  draggedCategoryId: string | null
  draggedDrawerSkill: { skill: TSkill; skillType?: SkillType } | null
  draggedCollection: TCollection | null
}

export const DragAndDropContext = React.createContext(
  {} as DragAndDropContextProps
)

export const useDragAndDrop = () => {
  const context = React.useContext(DragAndDropContext)

  if (!context)
    throw new Error(
      'useDragAndDrop must be used within a DragAndDropContext provider'
    )

  return context
}
