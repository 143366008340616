import cn from 'classnames'
import * as React from 'react'
import styles from './InputGroup.module.scss'

type RootProps = {
  children: React.ReactNode
} & Omit<React.HTMLProps<HTMLDivElement>, 'children'>

const Root: React.VFC<RootProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <div className={cn(styles.inputGroup, className)} {...restProps}>
      {children}
    </div>
  )
}

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

const Label: React.VFC<LabelProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <label className={cn(styles.label, className)} {...restProps}>
      {children}
    </label>
  )
}

type ErrorMessageProps = React.HTMLAttributes<HTMLParagraphElement>

const ErrorMessage = React.forwardRef<HTMLParagraphElement, ErrorMessageProps>(
  (props, ref) => {
    const { children, className, ...restProps } = props

    if (!children) return null

    return (
      <p
        ref={ref}
        className={cn(styles.errorMessage, className)}
        {...restProps}
      >
        {children}
      </p>
    )
  }
)

ErrorMessage.displayName = 'ErrorMessage'

export { Root, Label, ErrorMessage }
export type { RootProps, LabelProps, ErrorMessageProps }
