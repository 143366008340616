import {
  BriefcaseOutline,
  CheckmarkCircle2Outline,
  PlusCircleOutline,
} from '@easy-eva-icons/react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import * as React from 'react'
import cn from 'classnames'
import { CardProps } from '../../../atoms/Card'
import { MappedTags } from '../../../molecules/Tags/MappedTags'
import { pluralize } from '../../../../utils/string-manipulation'
import { Tag } from '../../../atoms/Tag'
import { TSkillWithOrg } from '../../../../types/entities'
import * as ConfirmationDialog from '../../../molecules/ConfirmationDialog'
import * as EntityCard from '../../EntityCard'
import * as OverflowMenu from '../../../atoms/OverflowMenu'
import styles from './SkillCard.module.scss'
import type { PropsWithoutChildren } from '../../../../types/helpers'
import type { SkillType } from '../skills'
import { MinusCircle } from '@phosphor-icons/react'
import { IconButton } from '../../../atoms/IconButton'

export type SkillCardProps = {
  skill: TSkillWithOrg
  onAddSkill?(skill: TSkillWithOrg): void
  onRemoveSkillFromTeam?(skill: TSkillWithOrg): void
  allowEdit?: boolean
  isDragOverlay?: boolean
  disableAnimation?: boolean
  skillType?: SkillType
  inCurrentFramework?: boolean
} & PropsWithoutChildren<CardProps>

export const SkillCard = React.forwardRef<HTMLAnchorElement, SkillCardProps>(
  (props, ref) => {
    const {
      skill,
      className,
      onClick,
      onAddSkill,
      onRemoveSkillFromTeam,
      allowEdit,
      skillType = '',
      isDragOverlay = false,
      disableAnimation = false,
      inCurrentFramework,
      ...restProps
    } = props

    const canAddSkill =
      onAddSkill &&
      !inCurrentFramework &&
      ['library', 'org'].includes(skillType)

    const canRemoveSkillFromTeam =
      allowEdit && onRemoveSkillFromTeam && skillType === 'team'

    const skillAlreadyAdded =
      onAddSkill && inCurrentFramework && ['library', 'org'].includes(skillType)

    const teamText = `Used in ${pluralize(
      skill.frameworkCount || 0,
      'team',
      'teams'
    )}`

    const positionCount = skill.positionCount || 0
    const selectedVariant = skill.skillVariants?.find(
      (v) => v.inCurrentFramework && !v.default
    )
    return (
      <motion.div layout={disableAnimation ? false : 'position'}>
        <EntityCard.Root
          {...restProps}
          ref={ref}
          onClick={(e) => {
            e.preventDefault()
            onClick?.(e)
          }}
          showHover={!!onClick && !skillAlreadyAdded}
          href={onClick ? '#' : undefined}
          aria-label={skill.name}
          className={cn(
            styles.card,
            isDragOverlay && styles.skillOverlay,
            skillAlreadyAdded && styles.skillAlreadyAdded,
            className
          )}
          {...restProps}
        >
          <div className={styles.imageContentWrapper}>
            {skill.imageUrl && (
              <div className={styles.imageContainer}>
                <LazyLoadImage className={styles.image} src={skill.imageUrl} />
              </div>
            )}
            <div className={styles.contentWrapper}>
              <EntityCard.Header className={styles.header}>
                <EntityCard.Title>
                  {skill.name}
                  {selectedVariant && <span> ({selectedVariant.name})</span>}
                </EntityCard.Title>
                {skillAlreadyAdded && (
                  <span title="Skill already added">
                    <CheckmarkCircle2Outline className={styles.icon} />
                  </span>
                )}
                {canAddSkill && (
                  <button
                    className={styles.addableSkill}
                    onClick={(e) => {
                      e.stopPropagation()
                      onAddSkill?.(skill)
                    }}
                    title="Add skill"
                  >
                    <PlusCircleOutline aria-hidden className={styles.icon} />
                  </button>
                )}
                {canRemoveSkillFromTeam && (
                  <ConfirmationDialog.Root
                    body={`Removing this skill from the team will remove requirements from ${pluralize(
                      positionCount,
                      'position',
                      'positions'
                    )}. The skill will still be available in your org skills library.`}
                    onCancel={(e) => {
                      e.stopPropagation()
                    }}
                    onConfirm={(e) => {
                      e.stopPropagation()
                      onRemoveSkillFromTeam?.(skill)
                    }}
                    title="Are you sure you want to remove this skill from the team?"
                  >
                    <ConfirmationDialog.Trigger asChild>
                      <IconButton
                        hideBorder
                        title={`Delete`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <MinusCircle
                          role="button"
                          className={styles.deleteSkill}
                        />
                        <span className="sr-only">Remove from team</span>
                      </IconButton>
                    </ConfirmationDialog.Trigger>
                  </ConfirmationDialog.Root>
                )}
              </EntityCard.Header>
              {skill.description && !onAddSkill && (
                <EntityCard.Body className={styles.description}>
                  <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: skill.description }}
                  />
                </EntityCard.Body>
              )}
              <EntityCard.Footer className={styles.footer}>
                {skillType == 'library' && skill.org && (
                  <div>
                    By <Tag variant="paper">{skill.org.name}</Tag>
                  </div>
                )}
                {skillType == 'org' && (
                  <span title={teamText}>
                    <BriefcaseOutline />
                    <Tag variant="paper">{skill.frameworkCount || 0}</Tag>
                  </span>
                )}
                {onAddSkill && skill.tags && skill.tags.length > 0 && (
                  <EntityCard.Separator />
                )}
                {onAddSkill &&
                  skill.tags &&
                  (skillType == 'org' ? (
                    <MappedTags tags={skill.tags} characterLimit={16} />
                  ) : (
                    <MappedTags
                      tags={skill.tags}
                      characterLimit={
                        skill.org ? 16 - skill.org.name.length : 25
                      }
                    />
                  ))}
                {!onAddSkill && (
                  <span>
                    Used in <span className="bold">{positionCount}</span>{' '}
                    {positionCount === 1 ? 'position' : 'positions'}
                  </span>
                )}
              </EntityCard.Footer>
            </div>
          </div>
        </EntityCard.Root>
      </motion.div>
    )
  }
)

SkillCard.displayName = 'SkillCard'
