import * as React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cn from 'classnames'
import { idWithPrefix } from './drag-and-drop'
import styles from './Grid.module.scss'
import { useSkills } from './skills'
import {
  SkillVariantCard as BaseSkillVariantCard,
  SkillVariantCardProps as BaseSkillCardVariantProps,
} from '../SkillVariantCard'

export type SkillVariantCardProps = {
  skillVariantId: string
  isDragOverlay?: boolean
  inLockedCategory?: boolean
}

/**
 * Exported skill card - will render a draggable skill card as long as the card isn't a drag overlay and the user has permission to edit
 */
export const SkillVariantCard = (props: SkillVariantCardProps) => {
  const { skillVariantId, isDragOverlay = false, inLockedCategory } = props

  const { permissions } = useSkills()

  const allowDragging =
    !isDragOverlay &&
    permissions?.allowEdit &&
    (!inLockedCategory || permissions.allowLockingCategories)
  if (allowDragging) return <DraggableCard skillVariantId={skillVariantId} />

  return (
    <Card
      allowEdit={permissions?.allowEdit}
      isDragOverlay={
        isDragOverlay &&
        (!inLockedCategory || permissions?.allowLockingCategories)
      }
      skillVariantId={skillVariantId}
    />
  )
}

/**
 * Wrapper around the Card presentational component used to assign draggable attributes
 */
const DraggableCard = (props: SkillVariantCardProps) => {
  const { skillVariantId } = props

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({
      id: idWithPrefix(skillVariantId, 'skillVariant'),
      data: { type: 'skillVariant' },
    })

  const style = {
    transform: CSS.Transform.toString(transform),
  }

  return (
    <Card
      skillVariantId={skillVariantId}
      ref={setNodeRef}
      style={style}
      className={cn(isDragging && styles.draggedCard)}
      allowEdit
      {...attributes}
      {...listeners}
    />
  )
}

type CardProps = SkillVariantCardProps &
  Omit<BaseSkillCardVariantProps, 'skill' | 'skillVariant'>

/**
 * Base presentational skill card
 */
const Card = React.forwardRef<HTMLAnchorElement, CardProps>((props, ref) => {
  const {
    skillVariantId,
    allowEdit = false,
    isDragOverlay = false,
    ...restProps
  } = props
  const { getSkill, getSkillVariant, getCategory, eventHandlers, permissions } =
    useSkills()

  if (!skillVariantId) return null

  const skillVariant = getSkillVariant(skillVariantId)

  if (!skillVariant) return null
  if (!skillVariant.skillId) return null
  const skill = getSkill(skillVariant.skillId.toString())

  if (!skill) return null

  const category =
    skillVariant.categoryId !== null
      ? getCategory(skillVariant.categoryId.toString())
      : null

  return (
    <BaseSkillVariantCard
      onClick={() =>
        eventHandlers?.onClickSkill?.(skill, skillVariant?.id.toString())
      }
      onRemoveSkillVariantFromTeam={() =>
        eventHandlers?.onRemoveSkillVariantFromTeam?.(skillVariant)
      }
      className={cn(isDragOverlay && styles.cardOverlay)}
      ref={ref}
      disableAnimation
      skillType="team"
      skillVariant={skillVariant}
      skill={skill}
      allowEdit={
        allowEdit && (!category?.locked || permissions?.allowLockingCategories)
      }
      {...restProps}
    />
  )
})

Card.displayName = 'Card'
