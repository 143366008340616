import * as React from 'react'
import cn from 'classnames'
import styles from './Count.module.scss'
import { PropsWithoutChildren } from '../../../types/helpers'

export type CountProps = {
  children: number
  boxBg?: 'white' | 'grey'
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'span'>>

export const Count: React.VFC<CountProps> = (props) => {
  const { children, className, boxBg = 'grey', ...restProps } = props

  return (
    <span className={cn(styles.count, styles[boxBg], className)} {...restProps}>
      {children}
    </span>
  )
}
