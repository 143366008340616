import * as React from 'react'
import cn from 'classnames'
import { IconButton, IconButtonProps } from '../../atoms/IconButton'
import type { PropsWithoutChildren } from '../../../types/helpers'
import styles from './EditableForm.module.scss'
import { Checkmark } from '@easy-eva-icons/react'

export type ConfirmProps = PropsWithoutChildren<IconButtonProps>

export const Confirm: React.VFC<ConfirmProps> = (props) => {
  const { title = 'Submit', className, type = 'submit', ...restProps } = props

  return (
    <IconButton
      title={title}
      hideBorder
      className={cn(styles.icon, className)}
      type={type}
      {...restProps}
    >
      <Checkmark />
    </IconButton>
  )
}
