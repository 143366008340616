import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'

export const Category = baseModel('categories').props({
  listPosition: types.optional(types.number, 1000000, [null, undefined]),
  name: types.string,
  locked: types.optional(types.boolean, false),
})

export interface Category extends Instance<typeof Category> {}
export type CategoryModelAttributes = SnapshotIn<typeof Category>

export const CategoryStore = createStore<typeof Category>('Category', Category)
