import * as React from 'react'
import type { PropsWithoutChildren } from '../../../types/helpers'
import styles from './Item.module.scss'
import { SortContext } from './SortContext'
import { motion, Variants } from 'framer-motion'
import { ArrowDownwardOutline } from '@easy-eva-icons/react'
import { composeEventHandlers } from '../../../utils/compose-event-handlers'
import { getNextSort, isActiveSort } from './utils'

export type ItemProps = {
  /**
   * Required ID for the item, this is used for storing in state which sort is active along with the direction
   * @example "date-added"
   */
  id: string
  /**
   * Required children for the item - this is a string for simplicity reasons since we use it directly for accessibility text ie `Sort by Date Added in descending order` where children is "Date Added"
   * @example "Date Added"
   */
  children: string
} & Omit<
  PropsWithoutChildren<React.ComponentPropsWithRef<'button'>>,
  'type' | 'id'
>

const variants: Variants = {
  asc: { rotate: 0 },
  desc: { rotate: -180 },
  bg: { backgroundColor: 'rgba(243,242,241,1)' },
  nobg: { backgroundColor: 'rgba(243,242,241,0)' },
}

export const Item = React.forwardRef<HTMLButtonElement, ItemProps>(
  (props, ref) => {
    const { className, children, id, onClick, ...restProps } = props

    const { activeSort, setActiveSort, onSortChange, allowDefaultSort } =
      React.useContext(SortContext)

    const isActive = isActiveSort(activeSort, id)

    const newSort = getNextSort(activeSort, id, allowDefaultSort)

    const onClickHandler = React.useCallback(() => {
      setActiveSort(newSort)
      onSortChange?.(newSort)
    }, [newSort])

    const accessibleTitle = React.useCallback((): string => {
      if (newSort === null) return 'Sort using the default sort order'

      return `Sort by ${children.toLowerCase()} in ${
        newSort.direction
      }ending order`
    }, [newSort, children])

    return (
      <button
        aria-label={accessibleTitle()}
        aria-live="polite"
        className={className}
        onClick={composeEventHandlers(onClick, onClickHandler)}
        ref={ref}
        {...restProps}
      >
        <motion.div
          initial={false}
          animate={isActive ? 'bg' : 'nobg'}
          variants={variants}
          transition={{ duration: 0.2 }}
          className={styles.item}
        >
          <span aria-hidden>{children}</span>
          {isActive && (
            <motion.div
              className={styles.arrow}
              initial={false}
              animate={activeSort.direction}
              variants={variants}
              transition={{ duration: 0.2 }}
              aria-hidden
            >
              <ArrowDownwardOutline />
            </motion.div>
          )}
        </motion.div>
      </button>
    )
  }
)

Item.displayName = 'Sort.Item'
