import { compareDesc, isAfter, isBefore, isEqual } from 'date-fns'
import type { Activity, ActivityRecordType } from './models'

export type RecordTypeFilter = ActivityRecordType | 'Note'

export type ActivityTypeFilters = {
  from_datetime?: string
  record_type?: RecordTypeFilter | RecordTypeFilter[]
  skill_id?: string | string[]
  user_id?: string | string[]
  author_id?: string | string[]
  to_datetime?: string
  updated_since?: Date | null
  story?: string
  feedback_type?: string | string[]
}

export const filterByFromDatetime =
  (fromDatetime?: string) => (activity: Activity) => {
    if (!fromDatetime) return true

    const date = new Date(fromDatetime)

    if (activity.recordType === 'Action') {
      return (
        isAfter(activity.createdAt, date) ||
        isEqual(activity.createdAt, date) ||
        (activity.record.completedAt &&
          (isAfter(activity.record.completedAt, date) ||
            isEqual(activity.record.completedAt, date)))
      )
    } else {
      return (
        isAfter(activity.createdAt, date) || isEqual(activity.createdAt, date)
      )
    }
  }

export const filterBySkillId =
  (skillIds?: string | string[]) => (activity: Activity) => {
    if (!skillIds) return true

    const skillIdsArray = Array.isArray(skillIds) ? skillIds : [skillIds]
    switch (activity.recordType) {
      case 'Action':
      case 'FeedbackItem':
      case 'Win':
        return activity.record.skills.some((skill) =>
          skillIdsArray.includes(skill.id)
        )
      case 'Checkin':
      case 'FeedbackRequest':
      case 'PositionChange':
        return false
      case 'SkillsUser':
        return skillIdsArray.includes(activity.record?.id)
    }
  }

export const filterByRecordType =
  (recordType?: RecordTypeFilter | RecordTypeFilter[]) =>
  (activity: Activity) => {
    if (!recordType) return true

    const recordTypeArray = Array.isArray(recordType)
      ? recordType
      : [recordType]

    if (activity.recordType === 'Win') {
      const winCategory = activity.record.winCategory?.title
      return winCategory ? recordTypeArray.includes(winCategory) : false
    } else {
      return recordTypeArray.includes(activity.recordType)
    }
  }

export const filterByToDatetime =
  (toDatetime?: string) => (activity: Activity) => {
    if (!toDatetime) return true

    const date = new Date(toDatetime)

    if (activity.recordType === 'Action') {
      return (
        isBefore(activity.createdAt, date) ||
        isEqual(activity.createdAt, date) ||
        (activity.record.completedAt &&
          (isBefore(activity.record.completedAt, date) ||
            isEqual(activity.record.completedAt, date)))
      )
    } else {
      return (
        isBefore(activity.createdAt, date) || isEqual(activity.createdAt, date)
      )
    }
  }

export const filterByUserId =
  (userIds?: string | string[], feedbackType?: string | string[]) =>
  (activity: Activity) => {
    if (!userIds) return true

    const userIdsArray = [userIds].flat()
    const feedbackTypes = [feedbackType].flat()

    switch (activity.recordType) {
      case 'Action':
      case 'PositionChange':
        return userIdsArray.includes(activity.record.user.id)
      case 'Checkin':
        if (activity.finalised) {
          return userIdsArray.some((userId) =>
            activity.record.users.some((user) => user.id === userId)
          )
        } else if (activity.assessorApproved) {
          return activity.record.assessor
            ? userIdsArray.includes(activity.record.assessor.id)
            : false
        } else {
          return userIdsArray.includes(activity.record.author.id)
        }
      case 'FeedbackItem':
        if (feedbackTypes.includes('itemGiven')) {
          return userIdsArray.includes(activity.record.author.id)
        } else {
          return userIdsArray.includes(activity.record.receiver.id)
        }
      case 'FeedbackRequest':
        if (feedbackTypes.includes('requestReceived')) {
          return userIdsArray.some((userId) =>
            activity.record.users.some((user) => user.id === userId)
          )
        } else {
          return userIdsArray.includes(activity.record.creator.id)
        }
      case 'SkillsUser':
        return userIdsArray.includes(activity.creator.id)
      case 'Win':
        return userIdsArray.some((userId) =>
          activity.record.winners.some((user) => user.id === userId)
        )
    }
  }

export const filterByAuthorId =
  (authorIds?: string | string[]) => (activity: Activity) => {
    if (!authorIds) return true

    const authorIdsArray = [authorIds].flat()

    switch (activity.recordType) {
      case 'Win':
        return authorIdsArray.includes(activity.record?.reporter.id)
      default:
        return false
    }
  }

export const sort = (activities: Activity[]) =>
  activities.sort((a, b) => {
    return compareDesc(a.createdAt, b.createdAt)
  })
