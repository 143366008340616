import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { Checkin } from '../checkins'
import { createStore } from 'store/utils/create-store'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'

const OutcomeAssociatedData = types.model('outcome_associated_data', {
  content: types.string,
  id: types.string,
  listPosition: types.optional(types.number, 1000000, [null, undefined]),
  skillLevelId: types.maybeNull(types.string),
})

const SkillLevelAssociatedData = types.model('skill_level_associated_data', {
  id: types.string,
  level: types.number,
  name: types.string,
  outcomes: types.optional(
    types.array(OutcomeAssociatedData),
    [],
    [null, undefined]
  ),
  skillVariantId: types.maybeNull(types.string),
})

const SkillAssociatedData = types.model('skill_associated_data', {
  category: types.string,
  categoryId: types.maybeNull(types.string),
  categoryListPosition: types.number,
  description: types.maybeNull(types.string),
  id: types.string,
  levels: types.array(SkillLevelAssociatedData),
  name: types.string,
  skillVariantId: types.maybeNull(types.string),
})

const AssociatedData = types.model('associated_data', {
  skill: SkillAssociatedData,
})

export const CheckinSkill = baseModel('checkin_skills')
  .props({
    assessorDescription: types.maybeNull(types.string),
    assessorLevel: types.maybeNull(types.number),
    assessorSkipped: types.maybeNull(types.boolean),
    associatedData: AssociatedData,
    categoryName: types.maybeNull(types.string),
    checkin: reference(Checkin, { required: true }),
    description: types.maybeNull(types.string),
    finalDescription: types.maybeNull(types.string),
    finalLevel: types.maybeNull(types.number),
    finalSkipped: types.maybeNull(types.boolean),
    initiallySkipped: types.boolean,
    level: types.maybeNull(types.number),
    requiredLevel: types.number,
    skill: reference(Skill, { required: true }),
    status: types.union(
      types.literal('exceeding'),
      types.literal('meeting'),
      types.literal('not_checked_in'),
      types.literal('working_towards')
    ),
    userSkipped: types.boolean,
  })
  .views((self) => ({
    get associatedSkill() {
      return self.associatedData.skill
    },
    get associatedSkillLevel() {
      return this.associatedSkill.levels.find(
        (level) => level.level === self.requiredLevel
      )
    },
    get associatedSkillLevelOutcomes() {
      if (!this.associatedSkillLevel) return []

      return [...this.associatedSkillLevel.outcomes].sort(
        (a, b) => a.listPosition - b.listPosition
      )
    },
  }))

export type CheckinSkillModelAttributes = SnapshotIn<typeof CheckinSkill>

export interface CheckinSkill extends Instance<typeof CheckinSkill> {}

export const CheckinSkillStore = createStore<typeof CheckinSkill>(
  'CheckinSkill',
  CheckinSkill
)
