import * as React from 'react'
import cn from 'classnames'
import { CardGroup } from '../../molecules/CardGroup'
import { DragHandle } from '../../atoms/DragHandle'
import { EditableTitle } from '../EditableTitle'
import { EmptyPositionCard } from '../EmptyPositionCard'
import { PositionCard } from '../PositionCard'
import { usePositionsTable } from '../../../hooks/use-positions-table'
import * as ConfirmationDialog from '../../molecules/ConfirmationDialog'
import * as OverflowMenu from '../../atoms/OverflowMenu'
import styles from './DisciplineSection.module.scss'
import type { DivPropsWithoutChildren } from '../../../types/helpers'
import type { PositionColumns } from '../../../hooks/use-positions-table/use-positions-table.types'
import type { TDiscipline } from '../../../types/entities'

export type DisciplineSectionProps = {
  id: string
  discipline?: TDiscipline
  dragHandleProps?: DivPropsWithoutChildren
  firstOfType?: boolean
} & Omit<DivPropsWithoutChildren, 'id'>

export const DisciplineSection = React.forwardRef<
  HTMLDivElement,
  DisciplineSectionProps
>((props, ref) => {
  const {
    discipline,
    className,
    dragHandleProps = {},
    firstOfType,
    ...restProps
  } = props

  const [
    permissions,
    getPositionColumns,
    getSeniorityLevels,
    eventHandlers,
    disciplines,
  ] = usePositionsTable((s) => [
    s.permissions,
    s.getPositionColumns,
    s.getSeniorityLevels,
    s.eventHandlers,
    s.disciplines,
  ])

  const positionColumns: PositionColumns = React.useMemo(
    () => getPositionColumns(discipline?.id || 0),
    [discipline?.id, getPositionColumns, disciplines]
  )

  const seniorityLevels = React.useMemo(() => {
    return getSeniorityLevels(discipline?.id || 0)
  }, [discipline?.id, getSeniorityLevels, disciplines])

  if (!discipline) return null

  return (
    <section
      className={cn(styles.disciplineSection, className)}
      key={discipline.name}
      {...restProps}
      ref={ref}
    >
      <div className={styles.titleWrapper}>
        {permissions.allowEditDiscipline && (
          <DragHandle show className={styles.dragHandle} {...dragHandleProps} />
        )}
        <div className={styles.disciplineTitleWrapper}>
          <EditableTitle
            headingLevel={2}
            title={discipline.name}
            altTitle={`Edit discipline name: ${discipline.name}`}
            allowEdit={Boolean(permissions.allowEditDiscipline)}
            onEdit={(newTitle) =>
              eventHandlers.onEditDiscipline?.(discipline, newTitle)
            }
            titleClassName={styles.disciplineTitle}
            inputClassName={styles.disciplineTitle}
          />
        </div>
        <div className={styles.disciplinePositionsCount}>
          {discipline?.positions?.length}
        </div>
        {permissions.allowEditDiscipline && (
          <ConfirmationDialog.Root
            body={`This will also delete positions in the ${discipline.name} discipline`}
            onConfirm={() => eventHandlers.onDeleteDiscipline?.(discipline)}
            title="Are you sure?"
          >
            <OverflowMenu.Root
              className={styles.overflowMenu}
              contentClassName={styles.overflowMenuContent}
              testId={`${discipline.name
                .toLowerCase()
                .replace(/ /g, '-')}-overflow-menu`}
            >
              <OverflowMenu.Button
                onClick={() =>
                  eventHandlers.onDuplicateDiscipline?.(discipline)
                }
              >
                Duplicate
              </OverflowMenu.Button>
              <OverflowMenu.Button
                onClick={() => eventHandlers.onMoveDiscipline?.(discipline)}
              >
                Move to<span aria-hidden>..</span>
              </OverflowMenu.Button>
              <ConfirmationDialog.Trigger asChild>
                <OverflowMenu.Button variant="destructive">
                  Delete
                </OverflowMenu.Button>
              </ConfirmationDialog.Trigger>
            </OverflowMenu.Root>
          </ConfirmationDialog.Root>
        )}
      </div>

      <CardGroup className={styles.disciplineRow}>
        {seniorityLevels.map((seniorityLevel, seniorityIndex) => {
          if (!positionColumns.get(seniorityLevel))
            return (
              <EmptyPositionCard
                key={`empty-position-card-${discipline.id}-${seniorityLevel}`}
                discipline={discipline}
                seniorityLevel={seniorityLevel}
                allowCreatePosition={permissions.allowCreatePosition}
                onCreatePosition={eventHandlers.onCreatePosition}
                showLabels={permissions.showLabels}
              />
            )

          return (
            <CardGroup
              orientation="vertical"
              key={`card-group-${discipline.id}-${seniorityLevel}`}
            >
              {positionColumns
                .get(seniorityLevel)
                ?.map((position, positionIndex) => (
                  <PositionCard
                    firstOfType={
                      firstOfType && seniorityIndex === 0 && positionIndex === 0
                    }
                    position={position}
                    parentDiscipline={discipline}
                    permissions={permissions}
                    key={`position-card-${discipline.id}-${seniorityLevel}-${positionIndex}`}
                  />
                ))}
            </CardGroup>
          )
        })}
      </CardGroup>
    </section>
  )
})

DisciplineSection.displayName = 'DisciplineSection'
