import * as React from 'react'
import {
  ModalContext,
  ModalContextValue,
} from '../../../contexts/modal-context'

export const ModalContextProvider: React.VFC<{ children: React.ReactNode }> = (
  props
) => {
  const { children } = props
  const [activeModalId, setActiveModalId] = React.useState<null | string>(null)
  const [activeModalProps, setActiveModalProps] = React.useState<
    Record<string, unknown>
  >({})

  const openModal: ModalContextValue['openModal'] = React.useCallback(
    (id, props) => {
      setActiveModalId(id)
      if (props) setActiveModalProps({ [id]: props })
    },
    []
  )

  const closeModal = React.useCallback(() => {
    setActiveModalId(null)
    setActiveModalProps({})
  }, [])

  const getModalProps = React.useCallback(
    function <T>(modalId: string): T {
      const props = activeModalProps[modalId]
      return props ? (props as T) : ({} as T)
    },
    [activeModalProps]
  )

  return (
    <ModalContext.Provider
      value={{
        activeModalId,
        activeModalProps,
        openModal,
        closeModal,
        getModalProps,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
