import * as React from 'react'
import cn from 'classnames'
import styles from './UserAvatarButtonGroup.module.scss'
import { EventHandlers } from '../../../hooks/use-positions-table/use-positions-table.types'
import { IconSize } from '../../atoms/Icon'
import { Tag } from '../../atoms/Tag'
import { Tooltip } from '../../atoms/Tooltip'
import { TUser } from 'src/design-system'
import { User } from '../../../models/User'
import { UserAvatarButton } from '../UserAvatarButton'
import { Avatar } from '../../atoms/Avatar'

export type UserAvatarButtonGroupProps = {
  avatarClassName?: string
  children?: React.ReactNode
  limit?: number
  limitClassName?: string
  limitStyle?: 'avatar' | 'tag'
  onClickTeamMember?: EventHandlers['onClickTeamMember']
  size?: IconSize
  users?: TUser[]
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

/**
 * A group of avatar buttons with limit and overflow count
 */
export const UserAvatarButtonGroup: React.VFC<UserAvatarButtonGroupProps> = (
  props
) => {
  const {
    avatarClassName,
    children,
    className,
    limit,
    limitClassName,
    limitStyle = 'tag',
    onClickTeamMember,
    size,
    users,
    ...restProps
  } = props

  const truncatedUsers = limit && users ? users.slice(limit) : []

  const userNames = (
    <>{truncatedUsers.map((user) => new User(user).name).join(', ')}</>
  )

  return (
    <div className={cn(styles.buttonGroup, className)} {...restProps}>
      {users?.map((user, index) =>
        (limit && index < limit) || !limit ? (
          <UserAvatarButton
            className={avatarClassName}
            user={user}
            key={user.id}
            onClick={(e) => {
              e.stopPropagation()
              onClickTeamMember?.(user)
            }}
            size={size}
          />
        ) : null
      )}
      {children}
      {truncatedUsers.length > 0 && (
        <Tooltip content={userNames}>
          <button>
            {limitStyle === 'tag' ? (
              <Tag variant="paper" className={styles.tag}>
                +{truncatedUsers.length}
              </Tag>
            ) : (
              <Avatar
                className={limitClassName}
                fallbackText={`+${truncatedUsers.length}`}
                size="xs"
              />
            )}
          </button>
        </Tooltip>
      )}
    </div>
  )
}
