import * as React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cn from 'classnames'
import { idWithPrefix } from '../SkillsGrid/drag-and-drop'
import { SkillCardProps as BaseSkillCardProps } from '../SkillCard'
import type {
  TCategory,
  TSkillVariant,
  TSkillWithOrg,
} from '../../../../types/entities'
import { CopyOutline } from '@easy-eva-icons/react'
import { useSkillsDrawer } from './context'
import { useDrawerContext } from '../../../../contexts/drawer-context'
import { SKILLS_DRAWER_ID } from './utils'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { Button } from '../../../atoms/Button'
import { SkillImage } from '../SkillImage'
import styles from './SkillCard.module.scss'
import { AnimatePresence } from 'framer-motion'

export type SkillCardProps = {
  source?: 'library' | 'org' | 'all'
  skill: TSkillWithOrg
  isDragOverlay?: boolean
  allowEdit: boolean
}

/**
 * Exported skill card - will render a draggable skill card as long as the card isn't a drag overlay and the user has permission to edit
 */
export const SkillCard = (props: SkillCardProps) => {
  const { source, skill, isDragOverlay = false, allowEdit = false } = props

  const baseProps = {
    skill,
    source,
    allowEdit,
  }

  const allowDragging = !isDragOverlay && allowEdit
  if (allowDragging) return <DraggableCard {...baseProps} />

  return <Card isDragOverlay={isDragOverlay} {...baseProps} />
}

/**
 * Wrapper around the Card presentational component used to assign draggable attributes
 */
const DraggableCard = (props: SkillCardProps) => {
  const { skill, ...restProps } = props

  const { inCurrentFramework } = useSkillsDrawer()

  const skillType = skill.cloneable ? 'library' : 'org'

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({
      id: idWithPrefix(skill.id.toString(), 'drawerSkill'),
      data: { type: 'drawerSkill', skill, skillType },
      disabled: inCurrentFramework(skill),
    })

  const style = {
    transform: CSS.Transform.toString(transform),
  }

  return (
    <div className={styles.cardWrapper}>
      {isDragging && (
        <div className={styles.draggedCardOverlay}>
          <CopyOutline className={styles.icon} />
        </div>
      )}
      <Card
        skillType={skillType}
        skill={skill}
        ref={setNodeRef}
        style={style}
        className={cn(isDragging && styles.draggedCard)}
        {...attributes}
        {...listeners}
        {...restProps}
      />
    </div>
  )
}

type CardProps = SkillCardProps & Omit<BaseSkillCardProps, 'skill'>

/**
 * Base presentational skill card
 */
const Card = React.forwardRef<HTMLAnchorElement, CardProps>((props, ref) => {
  const {
    skill,
    source,
    skillType,
    isDragOverlay = false,
    onClick,
    onAddSkill,
    allowEdit,
    className,
    ...restProps
  } = props

  const skillsDrawerContext = useSkillsDrawer()
  const { getDrawerProps } = useDrawerContext()

  // when this card is used in the drag overlay it's used outside of
  // the skills drawer context, so we need some extra checks here
  // this is due to us not being able to have multiple drag overlays
  // https://github.com/clauderic/dnd-kit/issues/490
  const onClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, variantId?: string) => {
      if (onClick) onClick(e)
      if (!skillsDrawerContext || isDragOverlay) return

      skillsDrawerContext?.eventHandlers.onClickSkill?.(skill, variantId)
    },
    [skillsDrawerContext, skill]
  )

  const variants = skill.skillVariants

  const showVariants = variants && variants.length > 1

  const inCurrentFramework = React.useMemo(() => {
    if (!skillsDrawerContext || isDragOverlay || showVariants) return

    return (
      skillsDrawerContext?.inCurrentFramework(skill) || skill.inCurrentFramework
    )
  }, [skillsDrawerContext, skill, showVariants])

  const inCurrentFrameworkVariant = (skillVariant: TSkillVariant) => {
    if (!skillsDrawerContext || isDragOverlay) return
    return skillsDrawerContext?.inCurrentFrameworkVariant(skillVariant)
  }

  const inCurrentFrameworkAnyVariant = React.useMemo(() => {
    if (!skillsDrawerContext || isDragOverlay || !showVariants) return false

    return skillsDrawerContext?.inCurrentFrameworkAnyVariant(variants)
  }, [skill, skillsDrawerContext])

  const skillVariantButtonText = (skillVariant: TSkillVariant) => {
    if (inCurrentFrameworkVariant(skillVariant)) return 'Added'
    if (inCurrentFrameworkAnyVariant) return 'Swap'

    return 'Add'
  }

  const onAddSkillHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, variantId?: string) => {
      e.preventDefault()
      e.stopPropagation()

      if (!skillsDrawerContext || isDragOverlay) return

      const { category } = getDrawerProps<{ category: TCategory | null }>(
        SKILLS_DRAWER_ID
      )

      skillsDrawerContext?.eventHandlers.onAddSkill?.(
        skill,
        category || undefined,
        variantId
      )
    },
    [skillsDrawerContext, skill, getDrawerProps]
  )

  const [open, setOpen] = React.useState(false)
  const Icon = open ? CaretUp : CaretDown
  const buttonText = inCurrentFramework
    ? 'Added'
    : source === 'library'
    ? 'Add to org'
    : 'Add'

  return (
    <>
      <article
        onClick={showVariants ? () => setOpen(!open) : onClickHandler}
        ref={ref}
        {...restProps}
        className={cn(
          styles.skillCard,
          isDragOverlay && styles.cardOverlay,
          inCurrentFramework && styles.added,
          className
        )}
      >
        {skill.imageUrl && (
          <SkillImage
            className={cn(inCurrentFramework && styles.addedSkillImage)}
            fallbackAvatarText={skill.org?.name}
            avatarUrl={skill.org?.avatarUrl}
            imageUrl={skill.imageUrl}
          />
        )}
        <div className={styles.skillCardContent}>
          <div className="flex flex-row items-center">
            <h3 title={skill.name} className={styles.title}>
              {skill.name}
            </h3>
          </div>
          {showVariants && (
            <>
              <div className={styles.tags}>
                <span className="text-gray-600">
                  {variants.length} variants
                </span>
              </div>
            </>
          )}
        </div>
        {allowEdit && !showVariants && (
          <Button
            disabled={inCurrentFramework}
            variant={source === 'library' ? 'outline' : 'default'}
            className={cn('ml-auto', styles.addButton)}
            onClick={onAddSkillHandler}
            size="sm"
          >
            {buttonText}
          </Button>
        )}
        {allowEdit && showVariants && (
          <div className="ml-auto">
            <Button variant="outline" size="sm" onClick={() => setOpen(!open)}>
              {buttonText}
            </Button>
          </div>
        )}
      </article>
      {open && showVariants && (
        <AnimatePresence>
          <div className="flex pl-4">
            <div className="w-7 flex-shrink-0 flex justify-center pb-4">
              <div className="w-0.5 h-full bg-gray-50"></div>
            </div>
            <div className="flex flex-col mb-4 w-full">
              {variants.map((variant: TSkillVariant) => (
                <article
                  className={styles.variant}
                  key={`skill-variant-${variant.id}`}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    onClickHandler?.(e, variant.id.toString())
                  }
                >
                  {skill.imageUrl && (
                    <SkillImage
                      className={cn(
                        inCurrentFramework && styles.addedSkillImage
                      )}
                      fallbackAvatarText={skill.org?.name}
                      avatarUrl={skill.org?.avatarUrl}
                      imageUrl={skill.imageUrl}
                    />
                  )}
                  <span
                    className={cn(
                      inCurrentFrameworkVariant(variant) &&
                        styles.inCurrentFrameworkText
                    )}
                  >
                    {variant.name}
                  </span>
                  <Button
                    className={cn('ml-auto', styles.addButton)}
                    size="sm"
                    disabled={inCurrentFrameworkVariant(variant)}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      onAddSkillHandler?.(e, variant.id.toString())
                    }
                  >
                    {skillVariantButtonText(variant)}
                  </Button>
                </article>
              ))}
            </div>
          </div>
        </AnimatePresence>
      )}
    </>
  )
})

Card.displayName = 'SkillCard'
