import { createStore } from 'store/utils/create-store'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { reference } from 'store/utils/reference'
import { Win } from '../wins'
import { Action } from '../actions'
import { FeedbackItem } from '../feedback-item'
import { Story } from '../stories'

export const StoryItem = baseModel('story_items').props({
  storyable: reference(types.union(Action, Win, FeedbackItem)),
  aasmState: types.maybeNull(
    types.union(
      types.literal('suggested'),
      types.literal('approved'),
      types.literal('rejected')
    )
  ),
  story: reference(Story, { required: true }),
})

export type StoryItemCreateAttributes = {
  storyableId?: string
  storyableType?: string
  story?: string
}

type StoryItemStoreOptions = {
  CreateAttributes: StoryItemCreateAttributes
}

export interface StoryItem extends Instance<typeof StoryItem> {}
export type StoryItemModelAttributes = SnapshotIn<typeof StoryItem>

export const StoryItemStore = createStore<
  typeof StoryItem,
  StoryItemStoreOptions
>('StoryItem', StoryItem)
