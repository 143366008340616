import { createStore } from 'store/utils/create-store'
import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { reference } from 'store/utils/reference'
import { DateType } from 'store/utils/date-type'
import { User } from '../users'
import { getWeek } from 'date-fns'

export const ExternalActivity = baseModel('external_activities').props({
  performedAt: types.maybeNull(DateType),
  externalType: types.union(
    types.literal('google_calendar_event'),
    types.literal('linear_app_issue_completed'),
    types.literal('linear_app_issue_started')
  ),
  title: types.string,
  user: reference(types.late((): IAnyModelType => User)),
  actors: types.array(reference(types.late((): IAnyModelType => User))),
})

export type ExternalActivityFilters = {
  performedAtFrom?: Date | null
  performedAtTo?: Date | null
  updatedSince?: Date | null
}

type ExternalActivityStoreOptions = {
  Filters: ExternalActivityFilters
  Include: Array<'actors' | 'user'>
}

export interface ExternalActivity extends Instance<typeof ExternalActivity> {}
export type ExternalActivityModelAttributes = SnapshotIn<
  typeof ExternalActivity
>

export const ExternalActivityStore = createStore<
  typeof ExternalActivity,
  ExternalActivityStoreOptions
>('ExternalActivity', ExternalActivity).views((store) => ({
  getTypeForUserInCurrentWeek(type: string, userId: string) {
    return store.filtered((externalActivity) => {
      if (!externalActivity.performedAt) return false
      return (
        externalActivity.user.id == userId &&
        externalActivity.externalType === type &&
        getWeek(externalActivity?.performedAt) == getWeek(new Date())
      )
    })
  },
}))
