import { unprotect } from 'mobx-state-tree'
import { RootModel } from './modules/root'

let store: RootModel
export type Store = RootModel
export { store }

const enableDebugging = async () => {
  // eslint-disable-next-line import/no-extraneous-dependencies
  const makeInspectable = await import('mobx-devtools-mst')
  makeInspectable.default(store)
}

const createGlobalStore = async () => {
  if (typeof window !== 'undefined') {
    if (!window.store) {
      window.store = RootModel.create()
    }
    store = window.store
  } else {
    store = RootModel.create()
  }
  if (store.debug) enableDebugging()
  if (process.env.NODE_ENV === 'test' && !process.env.ACCEPTANCE_SPEC) {
    unprotect(store)
  }
  document.dispatchEvent(new CustomEvent('storeLoaded', { detail: store }))
}

createGlobalStore()
