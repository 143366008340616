import { Instance, types } from 'mobx-state-tree'
import { Action } from '../actions'
import { Checkin } from '../checkins'
import { createActivity } from './factory'
import { FeedbackItem } from '../feedback-item'
import { FeedbackRequest } from '../feedback-request'
import { getRootStore } from 'store/utils/get-root-store'
import { PositionChange } from '../position-changes'
import { Skill } from '../skills'
import { Win } from '../wins'
import { reference } from 'store/utils/reference'

// Win Created
export const WinCreatedActivity = createActivity(
  reference(Win, {
    required: true,
    onInvalidated({ parent }) {
      getRootStore(parent).activities.unload(parent.id)
    },
  }),
  'Win'
)
export interface WinCreatedActivity
  extends Instance<typeof WinCreatedActivity> {}

// Action Completed
export const ActionCompletedActivity = createActivity(
  reference(Action, {
    required: true,
    onInvalidated({ parent }) {
      getRootStore(parent).activities.unload(parent.id)
    },
  }),
  'Action'
)
export interface ActionCompletedActivity
  extends Instance<typeof ActionCompletedActivity> {}

// Check-in
export const CheckinActivity = createActivity(
  reference(Checkin, { required: true }),
  'Checkin'
).views((self) => ({
  get assessorApproved() {
    if (this.finalised) return false
    if (!self.record.assessorApprovedAt) return false

    return self.createdAt >= self.record.assessorApprovedAt
  },
  get finalised() {
    if (!self.record.finalisedAt) return false

    return self.createdAt >= self.record.finalisedAt
  },
}))

export interface CheckinActivity extends Instance<typeof CheckinActivity> {}

// Feedback Received
export const FeedbackReceivedActivity = createActivity(
  reference(FeedbackItem, { required: true }),
  'FeedbackItem'
)

export interface FeedbackReceivedActivity
  extends Instance<typeof FeedbackReceivedActivity> {}

// Feedback Requested
export const FeedbackRequestedActivity = createActivity(
  reference(FeedbackRequest, { required: true }),
  'FeedbackRequest'
)
export interface FeedbackRequestedActivity
  extends Instance<typeof FeedbackRequestedActivity> {}

// Position Change
export const PositionChangeActivity = createActivity(
  reference(PositionChange, { required: true }),
  'PositionChange'
)
export interface PositionChangeActivity
  extends Instance<typeof PositionChangeActivity> {}

// Skill Focused
export const FocusSkillActivity = createActivity(
  reference(Skill, { required: true }),
  'SkillsUser'
)
export interface FocusSkillActivity
  extends Instance<typeof FocusSkillActivity> {}

export const Activity = types.union(
  ActionCompletedActivity,
  CheckinActivity,
  FeedbackReceivedActivity,
  FeedbackRequestedActivity,
  FocusSkillActivity,
  PositionChangeActivity,
  WinCreatedActivity
)

export type Activity = Instance<typeof Activity>
export type ActivityRecordType = Activity['recordType']
