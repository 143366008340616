import {
  compareAsc,
  compareDesc,
  format,
  isBefore,
  isWithinInterval,
} from 'date-fns'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { DateType } from 'store/utils/date-type'
import { getRootStore } from 'store/utils/get-root-store'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'
import { User } from '../users'
import { UserSkill } from '../user_skills'

export const Action = baseModel('actions')
  .props({
    completed: types.boolean,
    completedAt: types.maybeNull(DateType),
    content: types.string,
    createdFromId: types.maybeNull(types.string),
    createdFromType: types.maybeNull(types.string),
    creator: reference(User, { required: true }),
    dueDate: types.maybeNull(DateType),
    skills: types.array(reference(Skill, { required: true })),
    user: reference(User, { required: true }),
  })
  .views((self) => ({
    get formattedDueDate() {
      return self.dueDate ? format(self.dueDate, 'yyyy-MM-dd') : undefined
    },
    get formattedCreatedDate() {
      return self.createdAt ? format(self.createdAt, 'yyyy-MM-dd') : undefined
    },
    get formattedCompletedDate() {
      return self.completedAt
        ? format(self.completedAt, 'yyyy-MM-dd')
        : undefined
    },
    get overdue() {
      if (self.completed) return false
      if (!self.dueDate) return false

      return isBefore(self.dueDate, new Date())
    },
    get skillIds() {
      return self.skills.reduce(
        (arr, skill) => (skill ? [...arr, skill.id] : arr),
        [] as string[]
      )
    },
    get sortedUserSkills(): UserSkill[] {
      return getRootStore(self).userSkills.sortedByFocusedForSkillsAndUser(
        this.skillIds,
        self.user.id
      )
    },
  }))

export interface Action extends Instance<typeof Action> {}
export interface ActionAttributes extends SnapshotIn<typeof Action> {}

type ActionCreateAttributes = {
  content?: string
  createdFromId?: string
  createdFromType?: string
  dueDate?: Date | null
  skills?: string[]
  updated_since?: Date | null
  user?: string
}

type ActionUpdateAttributes = {
  completed?: boolean
  content?: string
  dueDate?: Date | null
  skills?: string[]
}

export type ActionFilters = {
  completed_at_from?: string
  completed_at_to?: string
  completed?: boolean
  created_at_from?: string
  created_at_to?: string
  created_from_id?: string | string[]
  created_from_type?: string | string[]
  skill_id?: string | string[]
  user_id?: string | string[]
}

type ActionIncludes = 'skills' | 'user'

type ActionStoreOptions = {
  CreateAttributes: ActionCreateAttributes
  Filter: ActionFilters
  Include: ActionIncludes[]
  UpdateAttributes: ActionUpdateAttributes
}

export const ActionStore = createStore<typeof Action, ActionStoreOptions>(
  'Action',
  Action
)
  .actions((store) => ({
    async fetchForUser(
      userId: string | string[],
      filters: ActionFilters = {},
      pageSize = 100
    ) {
      await store.fetchAll({
        filter: { ...filters, user_id: userId },
        include: ['skills', 'user'],
        page: { size: pageSize },
      })
    },
  }))
  .views((store) => ({
    createdSinceDateTime(
      dateTime: 'all time' | Date,
      filters?: {
        skillId?: string | null
        userIds?: string[] | null
      },
      dateTo: Date = new Date()
    ) {
      return this.sorted(
        store.filtered((action) => {
          if (filters?.skillId && !action.skillIds.includes(filters.skillId))
            return false

          if (filters?.userIds && !filters.userIds.includes(action.user.id))
            return false

          if (dateTime === 'all time') return true

          return isWithinInterval(new Date(action.createdAt), {
            start: dateTime,
            end: dateTo,
          })
        })
      )
    },
    completedCreatedSinceDateTime(
      dateTime: 'all time' | Date,
      filters?: {
        skillId?: string | null
        userIds?: string[] | null
      },
      dateTo: Date = new Date()
    ) {
      const createdSince = this.createdSinceDateTime(dateTime, filters, dateTo)
      const completedSince = createdSince.filter((action) => action.completed)

      return completedSince
    },
    completedSinceDateTime(
      dateTime: 'all time' | Date,
      filters?: {
        skillId?: string | null
        userIds?: string[] | null
      },
      dateTo: Date = new Date()
    ) {
      return this.sorted(
        store.filtered((action) => {
          if (filters?.skillId && !action.skillIds.includes(filters.skillId))
            return false

          if (filters?.userIds && !filters.userIds.includes(action.user.id))
            return false

          if (!action.completedAt) return false

          if (dateTime === 'all time') return true

          return isWithinInterval(new Date(action.completedAt), {
            start: dateTime,
            end: dateTo,
          })
        })
      )
    },
    forCreatedFromAndUser(
      createdFromId: string,
      createdFromType: string,
      userId: string
    ) {
      return this.sorted(
        store.filtered(
          (action) =>
            action.createdFromId === createdFromId &&
            action.createdFromType === createdFromType &&
            action.user.id === userId
        )
      )
    },
    forSkillAndUser(skillId: string, userId: string) {
      return this.sorted(
        store.filtered(
          (action) =>
            action.skillIds.includes(skillId) && action.user.id === userId
        )
      )
    },
    forSkillsAndUser(skillIds: string[], userId: string) {
      return this.sorted(
        store.filtered(
          (action) =>
            action.skillIds.some((r) => skillIds.includes(r)) &&
            action.user.id === userId
        )
      )
    },
    forUsers(userIds: string[]) {
      return this.sorted(
        store.filtered((action) => userIds.includes(action.user.id))
      )
    },
    incompleteForUser(userId: string) {
      return this.sorted(
        store.filtered(
          (action) => action.user.id === userId && !action.completed
        )
      )
    },
    completedForUser(userId: string) {
      return this.sorted(
        store.filtered(
          (action) => action.user.id === userId && action.completed
        )
      )
    },
    incompleteWithPartialCompletedForUser(
      numberOfCompleted: number,
      userId: string
    ) {
      return this.sorted(
        store.all.reduce<{ completedCount: number; total: Action[] }>(
          (acc, action) => {
            if (action.user.id === userId) {
              if (action.completed) {
                if (acc.completedCount < numberOfCompleted) {
                  acc.total.push(action)
                  acc.completedCount += 1
                }
              } else {
                acc.total.push(action)
              }
            }

            return acc
          },
          { completedCount: 0, total: [] }
        ).total
      )
    },
    sorted(actions: Action[]) {
      return actions.sort((a, b) => {
        // If both completed, return completed most recently
        if (a.completedAt && b.completedAt)
          return compareDesc(a.completedAt, b.completedAt)
        // Return incomplete first (b)
        if (a.completedAt && !b.completedAt) return 1
        // Return incomplete first (a)
        if (!a.completedAt && b.completedAt) return -1

        // If both without a due date, return updated most recently
        if (!a.dueDate && !b.dueDate)
          return compareDesc(a.updatedAt, b.updatedAt)
        // Return one with a due date first (a)
        if (a.dueDate && !b.dueDate) return -1
        // Return one with a due date first (b)
        if (!a.dueDate && b.dueDate) return 1

        // If both have a due date, return the one due first
        return compareAsc(a.dueDate as Date, b.dueDate as Date)
      })
    },
  }))
