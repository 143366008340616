import {
  IAnyModelType,
  Instance,
  SnapshotIn,
  tryReference,
  types,
} from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { DateType } from 'store/utils/date-type'
import { FrameworksSkill } from '../frameworks-skills'
import { getRootStore } from 'store/utils/get-root-store'
import { Org } from '../orgs'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'
import { Team } from '../teams'
import { UserSkill } from '../user_skills'
import { collectAllReports } from './utils'
import { formatDistanceToNowStrict } from 'date-fns'
import { Membership } from '../memberships'
import { Checkin } from '../checkins'

export type Package = 'grow' | 'platform'

export const User = baseModel('users')
  .props({
    avatarUrl: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    fname: types.string,
    currentSignInAt: types.maybeNull(DateType),
    lname: types.maybeNull(types.string),
    manager: reference(types.late((): IAnyModelType => User)),
    org: reference(Org),
    orgRole: types.optional(
      types.union(types.literal('admin'), types.literal('member')),
      'member',
      [null]
    ),
    packages: types.array(
      types.enumeration([
        'grow',
        'platform',
        'skill_variants',
        'multiple_salary_bands',
      ])
    ),
    position: reference(Position),
    slug: types.string,
    state: types.union(
      types.literal('active'),
      types.literal('archived'),
      types.literal('not_invited')
    ),
    team: reference(Team),
    membership: reference(Membership),
    goalPosition: reference(Position),
  })
  .views((self) => ({
    get suggestedGoalPosition(): Position | null {
      if (!self.position?.seniorityLevel) return null
      return (
        getRootStore(self).positions.filtered((position: Position) =>
          position.disciplineIds.filter((disciplineId) =>
            self.position?.disciplineIds.includes(disciplineId)
          ).length > 0 && self.position?.seniorityLevel
            ? position.seniorityLevel === self.position?.seniorityLevel + 1
            : false
        )[0] || null
      )
    },
    get currentSignInAtWords() {
      return self.currentSignInAt
        ? formatDistanceToNowStrict(new Date(self.currentSignInAt), {
            unit: 'day',
            roundingMethod: 'ceil',
          })
        : 'Never'
    },
    get actionsUrl() {
      return `/users/${self.slug}/actions`
    },
    get fullName() {
      return `${self.fname} ${self.lname || `(${self.email})`}`
    },
    get initials() {
      return self.fname[0] + (self.lname ? self.lname[0] : '')
    },
    get profileUrl() {
      return `/users/${self.slug}`
    },
    get editUrl() {
      return `/users/${self.slug}/edit`
    },
    get isActive() {
      return self.state === 'active'
    },
    get isAdmin() {
      return self.orgRole === 'admin'
    },
    get isAdminOrEditor() {
      const orgId = self.org?.id

      return !!orgId && (this.isAdminOfOrg(orgId) || this.isEditorOfOrg(orgId))
    },
    get status() {
      if (self.state === 'active') return 'Active'
      if (self.state === 'archived') return 'Archived'
      if (self.state === 'not_invited') return 'Not invited'
    },
    get editableTeams() {
      const editableTeams: Team[] =
        getRootStore(self).teams.filtered((team: Team) =>
          team.hasEditor(self.id)
        ) || []

      return editableTeams
    },
    get isCurrentUser(): boolean {
      return getRootStore(self).currentUser?.id === self.id
    },
    get isManager() {
      return this.directReports.length > 0
    },
    get isAssessor(): boolean {
      return getRootStore(self).checkins.all.some((checkin: Checkin) => {
        return checkin.assessor?.id === self.id && checkin.author.id !== self.id
      })
    },
    get isMember() {
      return self.orgRole === 'member'
    },
    get hasSignedIn() {
      return self.currentSignInAt !== null
    },
    get isOwnManager() {
      return tryReference(() => self.manager)?.id === self.id
    },
    get directReports(): User[] {
      return getRootStore(self).users.active.filter(
        (user: User) => tryReference(() => user.manager)?.id === self.id
      )
    },
    get nonDirectReports(): User[] {
      const directReportIds = this.directReports.map((report) => report.id)

      return this.allReports.filter(
        (report) => !directReportIds.includes(report.id)
      )
    },
    get allReports(): User[] {
      return collectAllReports(this)
    },
    isAdminOfOrg(orgId: string): boolean {
      return this.isAdmin && self.org?.id === orgId
    },
    isEditorOfOrg(orgId: string): boolean {
      return getRootStore(self)
        .teams.filtered((team) => team.org?.id === orgId)
        .some((team) => team.hasEditor(self.id))
    },
    get skills(): Skill[] {
      const { userSkills } = getRootStore(self)
      return userSkills.forUser(self.id).map((userSkill) => userSkill.skill)
    },
    sortedSkills(): Skill[] {
      return this.sortedUserSkills().map((userSkill) => userSkill.skill)
    },
    sortedUserSkills(): UserSkill[] {
      const { userSkills } = getRootStore(self)

      if (self.team) {
        return userSkills.sortedByListPositionForUser(self.id)
      } else {
        return userSkills.forUser(self.id)
      }
    },
    get frameworksSkills(): FrameworksSkill[] {
      const { frameworksSkills } = getRootStore(self)
      const framework = self.team?.framework
      if (!framework) return []

      return frameworksSkills.all
        .filter((frameworkSkill) => {
          return frameworkSkill.framework?.id === framework.id
        })
        .sort((a, b) => a.listPosition - b.listPosition)
    },
    hasPackage(pkg: Package) {
      return self.packages.includes(pkg)
    },
  }))
  .actions((self) => ({
    async reload(includes: UserIncludes[] = []) {
      const root = getRootStore(self)
      await root.users.fetchOne(self.id, { include: includes })
    },
  }))

export interface User extends Instance<typeof User> {}
export type UserModelAttributes = SnapshotIn<typeof User>

export type ModifiableUserAttributes = {
  goalPosition?: string | null
  orgRole?: 'admin' | 'member'
  state?: 'active' | 'archived'
}

type UserIncludes =
  | 'manager'
  | 'org'
  | 'org.onboarding_survey'
  | 'position'
  | 'position.disciplines'
  | 'team'
  | 'membership'

type UserFilters = {
  updated_since?: Date | null
  manager_id?: string | null
  state?: string | null
}

type UserStoreOptions = {
  Include: UserIncludes[]
  Filters: UserFilters
  UpdateAttributes: Partial<ModifiableUserAttributes>
}

const activeStates = ['active', 'not_invited']

export const UserStore = createStore<typeof User, UserStoreOptions>(
  'User',
  User
).views((store) => ({
  get active() {
    return store.filtered((user) => activeStates.includes(user.state))
  },
  get activeInvited() {
    return store.filtered((user) => user.state === 'active')
  },
  get managers() {
    return store.filtered((user) => user.isManager)
  },
  withPackage(pkg: Package) {
    return store.filtered(
      (user) => activeStates.includes(user.state) && user.hasPackage(pkg)
    )
  },
}))
