import * as React from 'react'
import cn from 'classnames'
import { PropsWithoutChildren } from '../../../types/helpers'
import styles from './PrimaryNav.module.scss'

export type LinkProps = {
  /**
   * Required children prop for the link, this can just be text like 'Dashboard'
   */
  children: React.ReactNode
  /**
   * Whether the link is active, used to indicate the current page you're on
   * @example active={location.pathname.includes("/teams")}
   */
  active?: boolean
  /**
   * Whether or not to apply external link props including `target="_blank"` and `rel="noopener noreferrer"`
   */
  external?: boolean
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'a'>>

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const {
      className,
      children,
      href,
      active = typeof location !== 'undefined' && location.pathname === href,
      external = false,
      ...restProps
    } = props

    const externalProps = external
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : null

    return (
      <a
        href={href}
        className={cn(
          styles.link,
          { [styles.active]: active },
          'group',
          className
        )}
        aria-current={active ? 'page' : undefined}
        {...externalProps}
        {...restProps}
        ref={ref}
      >
        {children}
      </a>
    )
  }
)

Link.displayName = 'PrimaryNavLink'
