import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { Comment } from '../comments'
import { compactArray } from 'store/utils/compact-array'
import { createStore } from 'store/utils/create-store'
import { Discipline } from '../disciplines'
import { Framework } from '../frameworks'
import { getRootStore } from 'store/utils/get-root-store'
import { reference } from 'store/utils/reference'
import { Requirement } from '../requirements'
import { Salary } from '../salaries'
import { Skill } from '../skills'
import { Team } from '../teams'
import { User } from '../users'

export const Position = baseModel('positions')
  .props({
    customLabel: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    disciplines: compactArray(reference(Discipline)),
    framework: reference(types.late((): IAnyModelType => Framework)),
    hiringLink: types.maybeNull(types.string),
    name: types.string,
    origin: reference(types.late((): IAnyModelType => Position)),
    requirements: compactArray(reference(Requirement)),
    requirementsCount: types.maybeNull(types.number),
    seniorityLevel: types.number,
    skills: compactArray(reference(Skill)),
    slug: types.string,
    team: reference(types.late((): IAnyModelType => Team)),
  })
  .views((self) => ({
    get users(): User[] {
      return getRootStore(self).users.filtered(
        (user) => user.state !== 'archived' && user.position?.id === self.id
      )
    },
    get salary() {
      return this.salaries[0]
    },
    get salaries(): Salary[] {
      return getRootStore(self).salaries.filtered(
        (salary) => salary.position?.id === self.id
      )
    },
    get comments(): Comment[] {
      return getRootStore(self).comments.filtered(
        (comment) => comment.commentable === self
      )
    },
    positionCode(disciplineInitials: string) {
      if (self.customLabel) {
        return `${self.customLabel}`
      } else {
        return `${disciplineInitials}${self.seniorityLevel}`
      }
    },
    hasDiscipline(discipline: Discipline): boolean {
      return this.disciplineIds.includes(discipline.id)
    },
    get disciplineIds() {
      return self.disciplines.map((d) => d.id)
    },
    url(disciplineId?: string) {
      if (!disciplineId) {
        return new URL(`/positions/${self.slug}`, window.location.href).href
      }

      return new URL(
        `/disciplines/${disciplineId}/positions/${self.slug}`,
        window.location.href
      ).href
    },
    get skillIds() {
      return self.skills.reduce<string[]>((ids, skill) => {
        if (skill) ids.push(skill.id)

        return ids
      }, [])
    },
  }))

type PositionIncludes =
  | 'disciplines'
  | 'framework'
  | 'framework.disciplines'
  | 'framework.frameworks_skills'
  | 'framework.frameworks_skills.category'
  | 'framework.frameworks_skills.skill_variant'
  | 'framework.frameworks_skills.skill'
  | 'framework.positions'
  | 'requirements'
  | 'requirements.skill_level'
  | 'requirements.skill_level.outcomes'
  | 'salaries'
  | 'skills'
  | 'skills.skill_variants'
  | 'skills.skill_variants.skill_levels'
  | 'skills.skill_variants.skill_levels.outcomes'
  | 'team'
  | 'team.org'
  | 'users'

export type DuplicatePositionAttributes = {
  origin: string
}

export type ModifiablePositionAttributes = {
  customLabel?: string | null
  description?: string | null
  disciplines: string[]
  framework: string
  hiringLink?: string | null
  name: string
  seniorityLevel: number
}

type PositionFilters = {
  updated_since?: Date | null
  discipline_id?: string | null
  user_id: string[]
}

type PositionStoreOptions = {
  Include: PositionIncludes[]
  Filters: PositionFilters
  CreateAttributes: DuplicatePositionAttributes | ModifiablePositionAttributes
  UpdateAttributes: Partial<ModifiablePositionAttributes>
}

export interface Position extends Instance<typeof Position> {}
export type PositionModelAttributes = SnapshotIn<typeof Position>

export const PositionStore = createStore<typeof Position, PositionStoreOptions>(
  'Position',
  Position,
  {
    defaultFilter: (item) => !item.team?.template,
    bootstrap: { relationships: ['requirements'] },
    hooks: {
      afterDestroy(self) {
        const root = getRootStore(self)
        self.requirements.forEach((requirement) => {
          root.requirements.unload(requirement.id)
        })
        const positionSkills = root.positionSkills.forPosition(self.id)
        positionSkills.forEach((positionSkill) => {
          root.positionSkills.unload(positionSkill.id)
        })
      },
    },
  }
).views((store) => ({
  get published() {
    return store.filtered((position) => position.team && !position.team.isDraft)
  },
  // we filter out positions on templates by default, but if we ever need them we can use this method
  get templatePositions() {
    return store.filtered((position) => !!position.team?.template, {
      useDefaultFilter: false,
    })
  },
  get forOrg() {
    const root = getRootStore(store)
    const currentOrgId = root.currentUser?.org?.id
    if (!currentOrgId) return []

    return store.all.filter(
      (position) => position.team?.org?.id === currentOrgId
    )
  },
}))
