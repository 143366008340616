/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAnyType, IMSTMap } from 'mobx-state-tree'
import type { RootModel } from 'store/modules/root'
import { BootstrapOptions, StoreOptions } from 'store/types'
import { SchemaVersions } from '../../api/fetch-schema-versions'
import { JSONAPIRequestParams, RequestResult } from '../../api/types'
import { getModelType } from '../../api/utils/get-model-type'
import { BaseModel, baseModel } from './base-model'
import { getRootStore } from './get-root-store'

// Skeleton interface of a store module
interface StoreModule {
  data: IMSTMap<ReturnType<typeof baseModel>>
  clear(): void
  all: BaseModel[]
  checksum(): Promise<string>
}

export class Bootstrapper<
  Model extends IAnyType,
  Config extends StoreOptions<Model>
> {
  static reset() {
    window.localStorage.removeItem('bootstrap_config')
  }

  static resetStore(model: IAnyType) {
    const storeName = getModelType(model).name
    const config = window.localStorage.getItem('bootstrap_config')
    if (!config) return

    const newConfig = JSON.stringify({
      ...JSON.parse(config),
      [storeName]: { ...JSON.parse(config)[storeName], updatedSince: null },
    })
    window.localStorage.setItem('bootstrap_config', newConfig)
  }

  constructor(
    private model: Model,
    private store: StoreModule,
    private newSchemaVersions: SchemaVersions,
    private options: BootstrapOptions<Config['Include']> | undefined
  ) {}
  private storeName = getModelType(this.model).name
  private root = getRootStore(this.store) as RootModel
  private allResources = [
    this.storeName,
    ...(this.options?.relationships || []),
  ]

  async call(
    makeRequest: (
      params: JSONAPIRequestParams<Config['Include'], Config['Filters']>
    ) => Promise<RequestResult>
  ) {
    const params = this.createDefaultParams()
    const updatedSince = this.updatedSince()

    if (!this.validSchemas) {
      if (this.root.debug)
        console.log('[bootstrapper]', `Invalid schemas in ${this.storeName}`)
      this.clearStores()
    } else if (updatedSince) {
      params.filter = { updated_since: updatedSince.toISOString() }
    }

    const { success, meta: { checksum } = {} } = await makeRequest(params)
    if (checksum && checksum !== (await this.store.checksum())) {
      if (this.root.debug)
        console.log('[bootstrapper]', `Checksum mismatch in ${this.storeName}`)
      this.clearStores()
      delete params.filter
      await makeRequest(params)
    }

    if (success) this.setUpdatedSince()
  }

  private get validSchemas() {
    return this.allResources.every((resource) => {
      const existingSchemaVersion =
        this.root._meta.data.get(resource)?.schemaVersion
      const newSchemaVersion = this.newSchemaVersions[resource]
      if (!newSchemaVersion || !existingSchemaVersion) return true

      return existingSchemaVersion === newSchemaVersion
    })
  }

  private clearStores() {
    this.store.clear()
    this.options?.relationships?.forEach((relationship) => {
      this.root[relationship as keyof typeof this.root]?.clear()
    })
    this.setUpdatedSince(null)
  }

  private updatedSince() {
    let config = window.localStorage.getItem('bootstrap_config')
    if (!config) {
      config = JSON.stringify({})
      window.localStorage.setItem('bootstrap_config', config)
    }

    const { updatedSince } = JSON.parse(config)[this.storeName] || {}
    return updatedSince ? new Date(updatedSince) : undefined
  }

  private setUpdatedSince(
    updatedSince: string | null = new Date().toISOString()
  ) {
    const existing = window.localStorage.getItem('bootstrap_config')
    if (!existing) return

    const config = JSON.parse(existing)
    const newConfig = JSON.stringify({
      ...config,
      [this.storeName]: { ...config[this.storeName], updatedSince },
    })
    window.localStorage.setItem('bootstrap_config', newConfig)
  }

  private createDefaultParams(): JSONAPIRequestParams<
    Config['Include'],
    Config['Filters']
  > {
    return {
      page: { size: 9999 },
      include: this.options?.relationships,
    }
  }
}
