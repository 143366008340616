import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { Category } from '../categories'
import { createStore } from 'store/utils/create-store'
import { Framework } from '../frameworks'
import { getRootStore } from 'store/utils/get-root-store'
import { reference } from 'store/utils/reference'
import { SkillVariant } from '../skill-variants'

export const FrameworksSkill = baseModel('frameworks_skills')
  .props({
    category: reference(Category),
    framework: reference(types.late(() => Framework)),
    listPosition: types.optional(types.number, 1000000, [null, undefined]),
    skillVariant: reference(SkillVariant),
  })
  .views((self) => ({
    get skill() {
      return self.skillVariant?.skill
    },
    get requirements() {
      return self.skillVariant?.requirements
    },
  }))

export type FrameworksSkillCreateAttributes = {
  category?: string
  framework: string
  skillVariant: string
}

type FrameworksSkillStoreOptions = {
  CreateAttributes: FrameworksSkillCreateAttributes
}

export interface FrameworksSkill extends Instance<typeof FrameworksSkill> {}
export type FrameworksSkillModelAttributes = SnapshotIn<typeof FrameworksSkill>

export const FrameworksSkillStore = createStore<
  typeof FrameworksSkill,
  FrameworksSkillStoreOptions
>('FrameworksSkill', FrameworksSkill, {
  hooks: {
    afterDestroy(self) {
      if (!self.requirements) return

      const root = getRootStore(self)
      self.requirements.forEach((requirement) => {
        root.requirements.unload(requirement.id)
      })
    },
  },
}).views((store) => ({
  forFrameworkAndSkill(frameworkId: string, skillId: string) {
    return store.all.find(
      (frameworksSkill) =>
        frameworksSkill.framework?.id === frameworkId &&
        frameworksSkill.skill?.id === skillId
    )
  },
  forFrameworkAndSkillVariant(frameworkId: string, skillVariantId: string) {
    return store.all.find(
      (frameworksSkill) =>
        frameworksSkill.framework?.id === frameworkId &&
        frameworksSkill.skillVariant?.id === skillVariantId
    )
  },
  forSkill(skillId: string) {
    return store.filtered(
      (frameworksSkill) => frameworksSkill.skill?.id === skillId
    )
  },
  forSkillVariant(skillVariantId: string) {
    return store.filtered(
      (frameworksSkill) => frameworksSkill.skillVariant?.id === skillVariantId
    )
  },
}))
