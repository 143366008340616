import { compareAsc } from 'date-fns'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { reference } from 'store/utils/reference'
import { User } from '../users'

export const PositionChange = baseModel('position_changes').props({
  positionFromName: types.maybeNull(types.string),
  positionToName: types.maybeNull(types.string),
  user: reference(User, { required: true }),
})

export interface PositionChange extends Instance<typeof PositionChange> {}

export interface PositionChangeAttributes
  extends SnapshotIn<typeof PositionChange> {}

type PositionChangeFilters = {
  change_type?: string | string[]
  user_id?: string | string[]
  updated_since?: Date | null
}

type PositionChangeStoreOptions = {
  Filter: PositionChangeFilters
  Include: ['user']
}

export const PositionChangeStore = createStore<
  typeof PositionChange,
  PositionChangeStoreOptions
>('PositionChange', PositionChange).views((store) => ({
  sortedForUser(userId: string) {
    return store
      .filtered((positionChange) => positionChange.user.id == userId)
      .sort((a, b) => {
        return compareAsc(a.createdAt, b.createdAt)
      })
  },
}))
