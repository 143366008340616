import * as React from 'react'
import { IconButton, IconButtonProps } from '../../atoms/IconButton'
import { TUser } from 'src/design-system'
import { Tooltip } from '../../atoms/Tooltip'
import { UserAvatar } from '../../atoms/UserAvatar'
import { User } from '../../../models/User'

export type UserAvatarButtonProps = {
  user: TUser
} & Omit<IconButtonProps, 'hideBorder' | 'children' | 'title'>

export const UserAvatarButton: React.VFC<UserAvatarButtonProps> = (props) => {
  const { className, user: rawUser, size, ...restProps } = props

  const user = new User(rawUser)

  const TooltipContent = () => (
    <>
      <span className="sr-only">Avatar for </span>
      {user.name}
      {user.inactiveText}
    </>
  )

  return (
    <Tooltip content={<TooltipContent />}>
      <IconButton
        size={size}
        hideBorder
        iconClassName="w-full h-full"
        {...restProps}
      >
        <UserAvatar
          className={className}
          showAltText={false}
          size={size || 'xs'}
          user={user.data}
        />
      </IconButton>
    </Tooltip>
  )
}
