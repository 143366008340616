import {
  IAnyModelType,
  IAnyType,
  isArrayType,
  isLateType,
  isModelType,
  isOptionalType,
  isReferenceType,
  isUnionType,
  types,
} from 'mobx-state-tree'

export const getModelType = (model: IAnyType): IAnyModelType => {
  const modelTypes = getModelTypes(model)
  return modelTypes[0]
}

export const getModelTypes = (model: IAnyType): IAnyModelType[] => {
  const found = decodeTypes(model).filter(isModelType)
  if (found.length > 0) return found
  throw new Error(`Unsupported model type: ${model.name}`)
}

export const decodeTypes = (model: IAnyType | IAnyType[]): IAnyType[] => {
  if (Array.isArray(model)) {
    return filter(model.flatMap((type) => decodeTypes(type)))
  }

  if (isOptionalType(model) as boolean) {
    // @ts-expect-error `getSubTypes` is an untyped, internal method.
    return decodeTypes(model.getSubTypes())
  }

  if (isUnionType(model) as boolean) {
    // @ts-expect-error `_types` is an untyped, internal property.
    return decodeTypes(model._types)
  }

  if (isArrayType(model) as boolean) {
    // @ts-expect-error `_subType` is an untyped, internal property.
    return decodeTypes(model._subType)
  }

  if (isReferenceType(model) as boolean) {
    // @ts-expect-error `targetType` is an untyped, internal property.
    return decodeTypes(model.targetType)
  }

  if (isLateType(model) as boolean) {
    // @ts-expect-error `_definition` an untyped, internal method.
    return decodeTypes(model._definition())
  }

  if (types.null.is(model)) return []
  return [model]
}

export const filter = (typeArray: IAnyType[]): IAnyType[] => {
  return typeArray.filter((item, index, arr) => {
    if (types.null.is(item)) return false // Remove null types
    return arr.indexOf(item) === index // Deduplicate types
  })
}
