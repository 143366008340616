import * as React from 'react'
import cn from 'classnames'
import styles from './CardGroup.module.scss'

export type CardGroupProps = {
  /**
   * Content to pass through to the card group. This will have vertical/horizontal spacing by default.
   * @example <CardGroup><CardOne /><CardTwo /></CardGroup>
   */
  children: React.ReactNode
  /**
   * Whether or not the cards should stack next to each other or below each other.
   * @example <CardGroup orientation="vertical" />
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * Whether or not the cards should stack below/next to each other when they run out of space in the parent container. By default this is set to false so cards will stack next to each other even after they run out of space.
   * @example <CardGroup shouldWrap />
   */
  shouldWrap?: boolean
} & React.HTMLAttributes<HTMLDivElement>

/**
 * The Card Group component can be used to stack multiple cards alongside each other either horizontally or vertically. By default this will keep stacking cards next to each other regardless of available space, so it's down to the parent container to define how overflowing content should be handled. You can optionally set the `shouldWrap` variable to enable cards to wrap when they run out of space.
 */
export const CardGroup: React.VFC<CardGroupProps> = (props) => {
  const {
    children,
    orientation = 'horizontal',
    shouldWrap = false,
    className,
    ...restProps
  } = props

  return (
    <div
      className={cn(
        styles.cardGroup,
        styles[orientation],
        shouldWrap && styles.wrap,
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  )
}
