import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'

export const OnboardingSurvey = baseModel('onboarding_survey').props({
  orgSize: types.maybeNull(types.string),
})

export interface OnboardingSurvey extends Instance<typeof OnboardingSurvey> {}
export type OnboardingSurveyModelAttributes = SnapshotIn<
  typeof OnboardingSurvey
>

export const OnboardingSurveyStore = createStore<typeof OnboardingSurvey>(
  'OnboardingSurvey',
  OnboardingSurvey
)
