import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { ContentLicenseGrant } from '../content-license-grants'
import { createStore } from 'store/utils/create-store'
import { getRootStore } from 'store/utils/get-root-store'
import { Org } from '../orgs'
import { reference } from 'store/utils/reference'
import { Requirement } from '../requirements'
import { SkillVariant } from '../skill-variants'
import { sortAlphaNumberValues } from 'app/packs/src/utils/sort-helpers'
import { Team } from '../teams'
import { User } from '../users'
import { Position } from '../positions'

export const Skill = baseModel('skills')
  .props({
    cloneable: types.maybeNull(types.boolean),
    clonedFrom: reference(types.late((): IAnyModelType => Skill)),
    contentLicenseGrant: reference(ContentLicenseGrant),
    description: types.maybeNull(types.string),
    editableBy: types.optional(
      types.union(types.literal('admin_editors'), types.literal('admin_only')),
      'admin_editors'
    ),
    image: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    name: types.string,
    org: reference(Org),
    skillGeneratorId: types.maybeNull(types.number),
    slug: types.string,
  })
  .views((self) => ({
    get defaultVariant() {
      return this.skillVariants.find((variant) => variant.default)
    },
    get editableAdminOnly() {
      return self.editableBy === 'admin_only'
    },
    get frameworkIds(): string[] {
      return getRootStore(self)
        .frameworksSkills.forSkill(self.id)
        .reduce<string[]>((frameworkIds, frameworksSkill) => {
          const framework = frameworksSkill.framework

          if (framework && !frameworkIds.includes(framework.id)) {
            frameworkIds.push(framework.id)
          }

          return frameworkIds
        }, [])
    },
    get nonDefaultSkillVariants(): SkillVariant[] {
      return getRootStore(self).skillVariants.filtered(
        (skillVariant) =>
          skillVariant.skill?.id === self.id && !skillVariant.default
      )
    },
    get requirements(): Requirement[] {
      return getRootStore(self).requirements.filtered(
        (requirement) => requirement.skill?.id === self.id
      )
    },
    get skillVariants(): SkillVariant[] {
      return getRootStore(self).skillVariants.filtered(
        (skillVariant) => skillVariant.skill?.id === self.id
      )
    },
    get teams(): Team[] {
      return getRootStore(self)
        .teams.filtered(
          (team) =>
            !!team.framework && this.frameworkIds.includes(team.framework.id)
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    get teamsAndTemplates(): Team[] {
      return getRootStore(self)
        .teams.filtered(
          (team) =>
            !!team.framework && this.frameworkIds.includes(team.framework.id),
          { useDefaultFilter: false }
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    get userSkillUrl() {
      return `/users/skills/${self.slug}`
    },
    get url() {
      return new URL(`/skills/${self.slug}`, window.location.href).href
    },
    get positions(): Position[] {
      return getRootStore(self).positions.filtered((position) =>
        position.skillIds.includes(self.id)
      )
    },
  }))

type SkillIncludes =
  | 'content_license_grant'
  | 'frameworks_skills'
  | 'frameworks_skills.category'
  | 'frameworks_skills.skill_variant'
  | 'frameworks_skills.framework'
  | 'frameworks_skills.framework.team'
  | 'frameworks_skills.framework.team.org'
  | 'requirements'
  | 'skill_variants'
  | 'skill_variants.skill_levels'
  | 'skill_variants.skill_levels.outcomes'

type SkillFilters = {
  updated_since?: Date | null
}

export type SkillCreateAttributes = {
  clonedFrom?: string
  description?: string
  name?: string
}

type SkillStoreOptions = {
  CreateAttributes: SkillCreateAttributes
  Filters: SkillFilters
  Include: SkillIncludes[]
}

export type ModifiableSkillAttributes = {
  description?: string | null
  name: string
}

export interface Skill extends Instance<typeof Skill> {}
export interface SkillAttributes extends SnapshotIn<typeof Skill> {}

export const SkillStore = createStore<typeof Skill, SkillStoreOptions>(
  'Skill',
  Skill,
  {
    hooks: {
      afterDestroy(self) {
        const root = getRootStore(self)
        root.frameworksSkills.forSkill(self.id).forEach((frameworksSkill) => {
          root.frameworksSkills.unload(frameworksSkill.id)
        })

        root.requirements
          .filtered((requirement) => requirement.skill?.id === self.id)
          .forEach((requirement) => {
            root.requirements.unload(requirement.id)
          })

        self.skillVariants?.forEach((skillVariant) => {
          root.skillVariants.unload(skillVariant.id)
        })
      },
    },
  }
).views((store) => {
  return {
    get forOrg() {
      const currentOrgId = getRootStore(store).currentUser?.org?.id

      return currentOrgId
        ? store.filtered((skill) => skill.org?.id === currentOrgId)
        : []
    },
    requiredForUsers(users: User[]) {
      return [
        ...new Set(
          users
            .reduce<Skill[]>((skills, user) => {
              if (user.position) skills.push(...user.position.skills)
              return skills
            }, [])
            .flat()
        ),
      ].sort((a, b) => sortAlphaNumberValues(a.name, b.name))
    },
    requiredForUsersViaUserSkills(...userIds: string[]) {
      const userSkills = userIds.flatMap((userId) =>
        getRootStore(store).userSkills.requiredForUser(userId)
      )

      const skillMap = new Map<string, Skill>()

      userSkills.forEach((userSkill) => {
        skillMap.set(userSkill.skill.id, userSkill.skill)
      })

      return Array.from(skillMap.values())
    },
  }
})
