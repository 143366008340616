import * as React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cn from 'classnames'
import { idWithPrefix } from './drag-and-drop'
import styles from './Grid.module.scss'
import { useSkills } from './skills'
import {
  SkillCard as BaseSkillCard,
  SkillCardProps as BaseSkillCardProps,
} from '../SkillCard'

export type SkillCardProps = {
  skillId: string
  isDragOverlay?: boolean
  inLockedCategory?: boolean
}

/**
 * Exported skill card - will render a draggable skill card as long as the card isn't a drag overlay and the user has permission to edit
 */
export const SkillCard = (props: SkillCardProps) => {
  const { skillId, isDragOverlay = false, inLockedCategory } = props

  const { permissions } = useSkills()

  const allowDragging =
    !isDragOverlay &&
    permissions?.allowEdit &&
    (!inLockedCategory || permissions.allowLockingCategories)
  if (allowDragging) return <DraggableCard skillId={skillId} />

  return (
    <Card
      allowEdit={permissions?.allowEdit}
      isDragOverlay={
        isDragOverlay &&
        (!inLockedCategory || permissions?.allowLockingCategories)
      }
      skillId={skillId}
    />
  )
}

/**
 * Wrapper around the Card presentational component used to assign draggable attributes
 */
const DraggableCard = (props: SkillCardProps) => {
  const { skillId } = props

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({
      id: idWithPrefix(skillId, 'skill'),
      data: { type: 'skill' },
    })

  const style = {
    transform: CSS.Transform.toString(transform),
  }

  return (
    <Card
      skillId={skillId}
      ref={setNodeRef}
      style={style}
      className={cn(isDragging && styles.draggedCard)}
      allowEdit
      {...attributes}
      {...listeners}
    />
  )
}

type CardProps = SkillCardProps & Omit<BaseSkillCardProps, 'skill'>

/**
 * Base presentational skill card
 */
const Card = React.forwardRef<HTMLAnchorElement, CardProps>((props, ref) => {
  const {
    skillId,
    allowEdit = false,
    isDragOverlay = false,
    ...restProps
  } = props
  const { getSkill, getCategory, eventHandlers, permissions } = useSkills()

  if (!skillId) return null

  const skill = getSkill(skillId)
  if (!skill) return null

  const category =
    skill.categoryId !== null ? getCategory(skill.categoryId.toString()) : null

  const selectedVariant = skill.skillVariants?.find((v) => v.inCurrentFramework)

  return (
    <BaseSkillCard
      onClick={() =>
        eventHandlers?.onClickSkill?.(skill, selectedVariant?.id.toString())
      }
      onRemoveSkillFromTeam={() =>
        eventHandlers?.onRemoveSkillFromTeam?.(skill)
      }
      className={cn(isDragOverlay && styles.cardOverlay)}
      ref={ref}
      disableAnimation
      skill={skill}
      skillType="team"
      allowEdit={
        allowEdit && (!category?.locked || permissions?.allowLockingCategories)
      }
      {...restProps}
    />
  )
})

Card.displayName = 'Card'
