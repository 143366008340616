import * as React from 'react'
import cn from 'classnames'
import styles from './DragHandle.module.scss'
import { DotsSixVertical } from '@phosphor-icons/react'

export type DragHandleProps = { show: boolean } & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'children'
>

export const DragHandle: React.VFC<DragHandleProps> = (props) => {
  const { className, show, ...restProps } = props

  return (
    <div
      aria-hidden
      className={cn(styles.dragHandle, { [styles.hidden]: !show }, className)}
      {...restProps}
    >
      <DotsSixVertical height="16px" width="16px" weight="bold" />
    </div>
  )
}
