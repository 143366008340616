import * as React from 'react'
import type { EventHandlers, Permissions } from '../SkillsGrid.types'
import type {
  TCategory,
  TSkill,
  TSkillVariant,
} from '../../../../../types/entities'

// this is what we expose via context
type SkillsContextProps = {
  eventHandlers?: EventHandlers
  permissions?: Permissions
  categoryIds: string[]
  getSkill: (skillId: string) => TSkill | null
  getSkillVariant: (skillVariantId: string) => TSkillVariant | null
  getCategory: (categoryId: string) => TCategory | null
  categorisedSkillIds: Map<string, string[]>
  categorisedSkillVariantIds: Map<string, string[]>
  getSkillIdsInCategory: (categoryId: string) => string[]
  getSkillVariantIdsInCategory: (categoryId: string) => string[]
  showUncategorisedColumn: boolean
  resetCategorisedSkillIds: () => void
  resetCategorisedSkillVariantIds: () => void
  reorderCategory: (id: string, targetId: string) => void
  moveSkill: (
    skillId: string,
    targetCategoryId: string,
    targetIndex: number
  ) => void
  moveSkillVariant: (
    skillVariantId: string,
    targetCategoryId: string,
    targetIndex: number
  ) => void
}

export const SkillsContext = React.createContext({} as SkillsContextProps)

export const useSkills = () => {
  const context = React.useContext(SkillsContext)

  if (!context)
    throw new Error('useSkills must be used within a skills context provider')

  return context
}
