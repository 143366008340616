import * as React from 'react'
import { ConfirmationDialogProps } from '../components/molecules/ConfirmationDialog'

export type OpenConfirmationDialogProps = Partial<
  Pick<
    ConfirmationDialogProps,
    'onConfirm' | 'onCancel' | 'title' | 'body' | 'confirmLabel' | 'cancelLabel'
  >
>

export type ConfirmationDialogContextValue = {
  activeConfirmationDialogId: null | string
  openConfirmationDialog: (
    id: string,
    props?: OpenConfirmationDialogProps
  ) => void
  closeConfirmationDialog(): void
  getConfirmationDialogProps(
    confirmationDialogId: string
  ): OpenConfirmationDialogProps
}

export const ConfirmationDialogContext =
  React.createContext<ConfirmationDialogContextValue | null>(null)

export const useConfirmationDialogContext = () => {
  const context = React.useContext(ConfirmationDialogContext)

  if (!context)
    throw new Error(
      'useConfirmationDialogContext must be used within a ConfirmationDialogContextProvider or RootProvider'
    )

  return context
}
