import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { QuestionMarkCircleOutline } from '@easy-eva-icons/react'
import { useMounted } from '../../../../hooks/use-mounted'
import { Button } from '../../../atoms/Button'
import { Tooltip } from '../../../atoms/Tooltip'
import * as InputGroup from '../../../molecules/InputGroup'
import styles from './CommentForm.module.scss'
import { Textarea } from '../../../atoms/Textarea'

export type CommentFormProps = Omit<
  React.HTMLAttributes<HTMLFormElement>,
  'children' | 'onSubmit'
> & {
  /**
   * Hook called when the user submits the comment
   * @returns optional error message
   */
  onSubmit?(comment: string, parentId?: number): Promise<string | void>
  /**
   * Text to display in the privacy notice
   */
  privacyNotice?: React.ReactNode
  /**
   * Initial value for the comment
   */
  initialValue?: string
  /**
   * parentId of a reply comment
   */
  parentId?: number
  /**
   * Optional hook to exit the form. Cancel button will only be shown
   * if this prop is present.
   */
  onCancel?: () => void
  /**
   * Label to show on the submit button
   * @default Add comment
   */
  submitLabel?: string
}

const ErrorMessage = motion(InputGroup.ErrorMessage)

export const CommentForm: React.VFC<CommentFormProps> = ({
  onSubmit,
  privacyNotice,
  initialValue = '',
  parentId,
  onCancel,
  submitLabel = 'Add comment',
  ...restProps
}) => {
  const mounted = useMounted()
  const [comment, setComment] = React.useState(initialValue)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const changeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setComment(e.target.value)
    setErrorMessage('')
  }

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!comment.length) return setErrorMessage('Please provide a comment')

    setErrorMessage('')
    setLoading(true)
    try {
      const error = await onSubmit?.(comment, parentId)
      if (!mounted.current) return
      if (error) setErrorMessage(error)
      else setComment('')
    } catch {
      setErrorMessage('Something went wrong, please try again')
    } finally {
      if (mounted.current) setLoading(false)
    }
  }

  return (
    <form {...restProps} onSubmit={submitHandler}>
      <InputGroup.Root>
        <Textarea
          autoFocus
          isInvalid={!!errorMessage}
          value={comment}
          onChange={changeHandler}
          name="comment"
          placeholder="Add a comment"
          aria-label="Add a comment"
          aria-describedby={errorMessage ? 'comment-form-error' : undefined}
        />
        <AnimatePresence>
          {errorMessage.length && (
            <ErrorMessage
              id="comment-form-error"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {errorMessage}
            </ErrorMessage>
          )}
        </AnimatePresence>
        <div className={styles.bottomBar}>
          {privacyNotice && (
            <Tooltip content={privacyNotice}>
              <button
                type="button"
                className={styles.privacyLink}
                aria-label="Who can see this?"
              >
                <QuestionMarkCircleOutline className={styles.privacyIcon} />
              </button>
            </Tooltip>
          )}
          {onCancel && (
            <Button
              variant="outline"
              onClick={onCancel}
              className={styles.button}
            >
              Cancel
            </Button>
          )}
          <Button disabled={loading} type="submit" className={styles.button}>
            {loading ? 'Loading' : submitLabel}
          </Button>
        </div>
      </InputGroup.Root>
    </form>
  )
}
