import * as React from 'react'
import cn from 'classnames'
import { TUser } from 'src/design-system'
import { User } from '../../../models/User'
import styles from './UserAvatar.module.scss'

export type UserAvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type UserAvatarProps = {
  size?: UserAvatarSize
  user: TUser
  showAltText?: boolean
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'children' | 'src'>

export const UserAvatar: React.VFC<UserAvatarProps> = (props) => {
  const {
    user: rawUser,
    className,
    showAltText = true,
    size = 'md',
    ...restProps
  } = props

  const user = new User(rawUser)

  const classes = cn(
    styles.avatar,
    styles[size],
    user.data.state !== 'active' && styles.isInactive,
    className
  )

  const altText = `Avatar for ${user.name}${user.inactiveText}`

  if (!user.data.avatarUrl)
    return (
      <div className={cn(classes, styles.noSrc)} {...restProps}>
        <span className="sr-only">{altText}</span>
        <span aria-hidden={true}>{user.initials}</span>
      </div>
    )

  return (
    <img
      className={classes}
      src={user.data.avatarUrl}
      alt={altText}
      {...restProps}
    />
  )
}
