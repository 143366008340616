import { types } from 'mobx-state-tree'
import { RequestError, RequestResult } from '../../../api/types'

export const Pollable = types.model().actions(() => ({
  async poll(
    fn: () => Promise<RequestResult>,
    validate: () => Promise<boolean> | boolean,
    interval: number,
    maxAttempts: number
  ): Promise<RequestResult> {
    let attempts = 0

    const executePoll = async (resolve: (result: RequestResult) => void) => {
      const result = await fn()
      attempts++

      const valid = await validate()
      if (valid) {
        return resolve(result)
      } else if (maxAttempts && attempts === maxAttempts) {
        return resolve({ success: false, message: 'Exceeded max attempts' })
      } else {
        setTimeout(executePoll, interval, resolve)
      }
    }

    return new Promise(executePoll)
  },
}))
