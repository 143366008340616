import { z } from 'zod'

export const Primitive = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
  z.date(),
  z.undefined(),
])

export const Complex = z.record(Primitive)

// our records can be very nested (checkins and async task APIs), so allow any, and rely on MST for type safety
export const NestedComplex = z.record(z.any())

export const Relationship = z.object({ type: z.string(), id: z.string() })

export const AttributeTypes = z.union([
  Primitive,
  z.array(Primitive),
  Complex,
  z.array(Complex),
  NestedComplex,
  z.array(NestedComplex),
])

export const RelationshipType = z.union([
  Relationship,
  z.array(Relationship),
  z.null(),
])

export const JSONAPIMeta = z
  .object({
    checksum: z.string().optional(),
    pages: z.number().optional(),
    schema_versions: z.record(z.string()).optional(),
    total: z.number().optional(),
    explain: z.string().nullable().optional(),
    answer: z.string().nullable().optional(),
  })
  .catchall(z.unknown())

export const JSONAPIData = z.object({
  id: z.string().optional(),
  type: z.string(),
  attributes: z.record(AttributeTypes),
  relationships: z
    .record(z.object({ data: RelationshipType.optional() }))
    .optional(),
})

export const JSONAPIDoc = z.object({
  data: z.union([JSONAPIData, z.array(JSONAPIData), z.null()]),
  included: z.array(JSONAPIData).optional(),
  meta: JSONAPIMeta.optional(),
  links: z
    .object({
      first: z.string().optional(),
      last: z.string().optional(),
      prev: z.string().optional(),
      next: z.string().optional(),
    })
    .optional(),
})

export const JSONAPIError = z.object({
  detail: z.string().optional(),
  title: z.string(),
})

export const JSONAPIErrorsSchema = z.object({
  errors: z.array(JSONAPIError),
  meta: JSONAPIMeta.optional(),
})
