import { Instance, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'

type ReactionsCreateAttributes = {
  content: string
  reactable_type: string
  reactable_id: string
}

type ReactionsStoreOptions = {
  CreateAttributes: ReactionsCreateAttributes
}

export const Reaction = baseModel('reactions').props({
  content: types.string,
  creatorId: types.string,
  creatorName: types.string,
  reactableId: types.string,
  reactableType: types.string,
})

export interface Reaction extends Instance<typeof Reaction> {}

export const ReactionStore = createStore<
  typeof Reaction,
  ReactionsStoreOptions
>('Reaction', Reaction).views((store) => ({
  forReactable(reactableId: string, reactableType: string) {
    return store.filtered(
      (reaction) =>
        reaction.reactableId === reactableId &&
        reaction.reactableType === reactableType
    )
  },
}))
