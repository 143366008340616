import create from 'zustand'
import { SkillsRadarChartDataPoint } from '../../components/molecules/SkillsRadarChart/skills-radar.types'
import type {
  TCategory,
  TDiscipline,
  TPosition,
  TRequirement,
  TSkill,
} from '../../types/entities'
import type { Permissions, EventHandlers } from './use-framework.types'

export type UseFrameworkProps = {
  sticky: boolean
  setSticky: (sticky: boolean) => void
  categories: TCategory[]
  disciplines: TDiscipline[]
  eventHandlers?: EventHandlers
  frameworkId?: number
  permissions?: Permissions
  requirements: TRequirement[]
  uncategorisedSkills: TSkill[]
  getMaxSkillLevel: (categories: TCategory[]) => number
  getPositionSkillsDataPoints: (
    categories: TCategory[],
    position: TPosition,
    requirements: TRequirement[]
  ) => SkillsRadarChartDataPoint[]
  getSkillLevelDescription: (skill: TSkill, level: number) => string | undefined
  getSortedPositionsByDiscipline: (discipline: TDiscipline) => TPosition[]
  reset: () => void
}

const initialState = {
  sticky: false,
  categories: [],
  disciplines: [],
  eventHandlers: {
    onAddPositionForEmptyDiscipline: () => undefined,
    onChangeRequirementLevel: () => undefined,
    onClickRadarChart: () => undefined,
    onClickTeamMember: () => undefined,
    positionClickHandler: () => undefined,
    requirementClickHandler: () => undefined,
    onDeleteRequirement: () => undefined,
    skillClickHandler: () => undefined,
    onClickAddSkill: () => undefined,
    onClickAddSkillCategory: () => undefined,
    onDeletePosition: () => undefined,
    onEditPosition: () => undefined,
    onViewPosition: () => undefined,
  },
  permissions: {
    allowChangeRequirement: false,
    allowCreateRequirement: false,
    allowDeleteRequirement: false,
    allowCreatePosition: false,
    allowAssignUserToPosition: false,
    allowAddSkill: false,
    allowAddSkillCategory: false,
    allowEditPosition: false,
    showLabels: true,
    showRadarChartTooltip: true,
  },
  requirements: [],
  uncategorisedSkills: [],
}

export const useFramework = create<UseFrameworkProps>((set) => ({
  ...initialState,
  setSticky: (sticky) => set({ sticky }),
  getMaxSkillLevel: (categories) => {
    const skills = categories.flatMap((category) => category.skills || [])
    const skillLevels = skills.flatMap((skill) => skill.skillLevels || [])

    return skillLevels.reduce(
      (max, skillLevel) => Math.max(max, skillLevel.level),
      0
    )
  },
  getPositionSkillsDataPoints: (categories, position, requirements) => {
    const skills = categories.flatMap((category) => category.skills || [])

    return requirements.reduce<SkillsRadarChartDataPoint[]>(
      (acc, requirement) => {
        if (requirement.positionId === position.id) {
          const skill = skills.find((skill) => skill.id === requirement.skillId)

          if (skill) {
            const category = categories.find((category) =>
              category.skills?.includes(skill)
            )

            if (category) {
              acc.push({
                id: skill.id.toString(),
                category: category.name,
                level: requirement.level,
                name: skill.name,
              })
            }
          }
        }

        return acc
      },
      []
    )
  },
  getSkillLevelDescription: (skill, level) => {
    return skill.skillLevels?.find((l) => l.level === level)?.name
  },
  getSortedPositionsByDiscipline: (discipline) => {
    const sortedPositions = discipline.positions?.sort(
      (prev, next) => prev.seniorityLevel - next.seniorityLevel
    )

    return sortedPositions || []
  },
  reset: () => set(initialState),
}))
