import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import cn from 'classnames'
import { CaretRight } from '@phosphor-icons/react'
import * as React from 'react'
import type { PropsWithoutChildren } from '../../../types/helpers'
import styles from './Dropdown.module.scss'

export type SubMenuTriggerProps = {
  /**
   * Required children prop for the link, this can just be text like 'Dashboard'
   */
  children: React.ReactNode
  /**
   * Where the text should be aligned in the dropdown
   * @default left
   */
  textAlign?: React.CSSProperties['textAlign']
} & PropsWithoutChildren<DropdownMenu.DropdownMenuSubTriggerProps>

export const SubMenuTrigger = React.forwardRef<
  HTMLDivElement,
  SubMenuTriggerProps
>((props, ref) => {
  const { className, textAlign, disabled, onClick, children, ...restProps } =
    props

  return (
    <DropdownMenu.SubTrigger
      ref={ref}
      className={cn(styles.link, className, {
        [styles.disabled]: disabled,
      })}
      style={{ textAlign }}
      {...restProps}
    >
      <span className={cn(styles.linkContent, styles.subMenuTrigger)}>
        {children}
        <CaretRight weight="bold" className={cn(styles.caret)} />
      </span>
    </DropdownMenu.SubTrigger>
  )
})

SubMenuTrigger.displayName = 'SubMenuTrigger'
