import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'

export const Operations = baseModel('operations')

export const OperationsStore = createStore<typeof Operations>(
  'Operations',
  Operations
).actions((store) => ({
  async classifyTextSkills(text: string, availableSkillsNames: string[]) {
    const result = await store.api.operations([
      {
        op: 'classify',
        data: {
          type: 'skills',
          attributes: {
            text: text,
            available_skills_names: availableSkillsNames,
          },
        },
      },
    ])

    return result
  },
}))
