import stringify from 'safe-stable-stringify'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ResponseData = { response: Response; json: any; cached?: boolean }

export class RequestCache {
  private data: Map<string, Promise<ResponseData>> = new Map()

  constructor(private readonly expiry: number = 1000 * 10) {}

  // Cache the in-progress request and return the promise, so that other concurrent
  // requests will receive the same promise and act on the payload when it resolves.
  // This way subsequent requests that start before the first one has resolved will
  // not make more network requests to the API.
  async performRequest(
    url: string,
    options: RequestInit,
    bypass?: boolean
  ): Promise<ResponseData> {
    if (options.method !== 'GET') {
      const result = await this.requestData(url, options)

      this.clearMatching(url) // It's a mutation, so invalidate matching requests
      return result
    }

    const key = `${url}|${stringify(options)}`
    let cached = true
    let promise = this.get(key)
    if (!promise || bypass) {
      // As we can only extract JSON from the response once, we need to cache
      // a promise which resolves with the response and the extracted JSON.
      promise = this.requestData(url, options)
      this.set(key, promise)
      cached = false
    }

    const { response, json } = await promise

    // If the request was successful, ensure future requests are fresh
    if (!this.successful(response)) {
      this.data.delete(key)
      cached = false
    }

    return { response, json, cached }
  }

  get(key: string) {
    return this.data.get(key)
  }

  private async set(key: string, response: Promise<ResponseData>) {
    this.data.set(key, response)

    // After ten seconds, clear the cache for this request
    setTimeout(() => {
      this.data.delete(key)
    }, this.expiry)
  }

  private async requestData(
    url: string,
    options: RequestInit
  ): Promise<ResponseData> {
    const response = await fetch(url, options)
    const json = response.status === 204 ? null : await response.json()
    return { response, json, cached: false }
  }

  private successful(response: Response) {
    return response.ok || response.status === 304
  }

  private clearMatching(url: string) {
    const baseUrl = url.split('?')[0]
    const keys = Array.from(this.data.keys()).filter((key) =>
      key.startsWith(baseUrl)
    )
    keys.forEach((key) => this.data.delete(key))
  }
}
