import * as React from 'react'
import { DropdownAlignment } from './shared'

export type DropdownContextProps = {
  open: boolean
  toggle: () => void
  align: DropdownAlignment
}

export const DropdownContext = React.createContext({} as DropdownContextProps)

export const useDropdownContext = () => {
  const context = React.useContext(DropdownContext)
  if (!context) {
    throw new Error('DropdownContext must be used within a DropdownProvider')
  }
  return context
}
