import { RootState, StoreModule } from 'store/modules/root'
import {
  getSnapshot,
  applySnapshot,
  SnapshotOut,
  Instance,
} from 'mobx-state-tree'

export const applyState = (
  store: Instance<RootState>,
  state: { [key in StoreModule]?: Record<string, unknown> }
) => {
  const existing = getSnapshot<SnapshotOut<RootState>>(store)
  const newKeys = Object.keys(state) as Exclude<StoreModule, '_meta'>[]
  const newState = newKeys.reduce((acc, key) => {
    const module = acc[key]
    if (!module || !('data' in module)) return acc
    return {
      ...acc,
      [key]: {
        ...module,
        data: { ...(module.data as Record<string, unknown>), ...state[key] },
      },
    }
  }, existing)

  applySnapshot(store, newState)
}
