import { PersonAddOutline } from '@easy-eva-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from '../../../types/helpers'
import { getInitials } from '../../../utils/get-initials'
import { IconButton } from '../../atoms/IconButton'
import { SkillsRadarChart } from '../../molecules/SkillsRadarChart/SkillsRadarChart'
import { Tag } from '../../atoms/Tag'
import { TDiscipline, TPosition, TUser } from '../../../types/entities'
import { useFramework } from '../../../hooks/use-framework'
import { UserAvatarButtonGroup } from '../../molecules/UserAvatarButtonGroup'
import * as Card from './Card'
import styles from './PositionCard.module.scss'
import * as OverflowMenu from '../../atoms/OverflowMenu'
import { Tooltip } from '../../atoms/Tooltip'
import { PositionClickHandlerProps } from '../../../hooks/use-framework/use-framework.types'

type PositionCardProps = {
  position: TPosition
  discipline: TDiscipline
  allowAssignUserToPosition?: boolean
  allowEditPosition?: boolean
  showLabels?: boolean
  maxSkillLevel: number
  onClickRadarChart?: (position?: TPosition) => void
  onClickTeamMember?: (user?: TUser) => void
  onAssignUserToPosition?: (position?: TPosition, source?: string) => void
  onEditPosition?: (props: PositionClickHandlerProps) => void
  onViewPosition?: (props: PositionClickHandlerProps) => void
  onDeletePosition?: (position: TPosition) => void
  showRadarChartTooltip?: boolean
} & DivPropsWithoutChildren

export const PositionCard: React.VFC<PositionCardProps> = (props) => {
  const {
    onClick,
    discipline,
    position,
    className,
    allowAssignUserToPosition,
    allowEditPosition,
    showLabels,
    maxSkillLevel,
    onClickRadarChart,
    onClickTeamMember,
    onAssignUserToPosition,
    onEditPosition,
    onViewPosition,
    onDeletePosition,
    showRadarChartTooltip,
    ...restProps
  } = props

  let avatarLimit = 5
  if (allowAssignUserToPosition) --avatarLimit

  const [categories, getPositionSkillsDataPoints, requirements, sticky] =
    useFramework((hook) => [
      hook.categories,
      hook.getPositionSkillsDataPoints,
      hook.requirements,
      hook.sticky,
    ])

  const series = React.useMemo(() => {
    const skills = getPositionSkillsDataPoints(
      categories,
      position,
      requirements
    )

    if (!position.name || skills.length === 0) return

    return { [position.name]: { skills } }
  }, [categories, position, requirements])

  if (sticky)
    return (
      <Card.Root
        showHover={!!onClick}
        className={cn(styles.collapsed, className)}
        onClick={onClick}
        data-element-id="position-card"
      >
        {showLabels && (
          <>
            {position.customLabel && (
              <Tag className={styles.positionTag}>{position.customLabel}</Tag>
            )}
            {!position.customLabel && (
              <Tag className={styles.positionTag}>
                {getInitials(discipline.name)}
                {position.seniorityLevel}
              </Tag>
            )}
          </>
        )}
        <h3 title={position.name}>{position.name}</h3>
        {allowEditPosition && (
          <PositionCardOverflowMenu
            position={position}
            discipline={discipline}
            onDeletePosition={onDeletePosition}
            onAssignUserToPosition={onAssignUserToPosition}
            onEditPosition={onEditPosition}
            onViewPosition={onViewPosition}
          />
        )}
      </Card.Root>
    )
  return (
    <Card.Root
      onClick={onClick}
      showHover={!!onClick}
      className={cn(styles.positionCard, className)}
      {...restProps}
      data-element-id="position-card"
    >
      <div>
        <div className="flex flex-row items-center gap-x-2 justify-between">
          {showLabels && (
            <>
              {position.customLabel && (
                <Tag className={styles.positionTag}>{position.customLabel}</Tag>
              )}
              {!position.customLabel && (
                <Tag className={styles.positionTag}>
                  {getInitials(discipline.name)}
                  {position.seniorityLevel}
                </Tag>
              )}
            </>
          )}
          {allowEditPosition && (
            <PositionCardOverflowMenu
              position={position}
              discipline={discipline}
              onDeletePosition={onDeletePosition}
              onEditPosition={onEditPosition}
              onViewPosition={onViewPosition}
              onAssignUserToPosition={onAssignUserToPosition}
            />
          )}
        </div>
        <h3>{position.name}</h3>
      </div>
      <div className="flex items-center justify-between mb-4">
        {series && (
          <Tooltip disabled={!showRadarChartTooltip} content="Compare position">
            <SkillsRadarChart
              className="!cursor-pointer"
              onClick={
                showRadarChartTooltip
                  ? (_nextState, e) => {
                      e.stopPropagation()
                      onClickRadarChart?.()
                    }
                  : undefined
              }
              maxLevel={maxSkillLevel}
              series={series}
              size={40}
            />
          </Tooltip>
        )}
        <UserAvatarButtonGroup
          className={styles.avatarRow}
          limit={avatarLimit}
          users={position?.activeUsers}
          onClickTeamMember={onClickTeamMember}
        >
          {allowAssignUserToPosition && (
            <IconButton
              title="Add a team member"
              onClick={(e) => {
                e.stopPropagation()
                onAssignUserToPosition?.(position)
              }}
            >
              <PersonAddOutline />
            </IconButton>
          )}
        </UserAvatarButtonGroup>
      </div>
    </Card.Root>
  )
}

const PositionCardOverflowMenu: React.VFC<
  Pick<
    PositionCardProps,
    | 'onAssignUserToPosition'
    | 'onDeletePosition'
    | 'onEditPosition'
    | 'onViewPosition'
    | 'position'
    | 'discipline'
  >
> = (props) => {
  const {
    onAssignUserToPosition,
    onDeletePosition,
    onEditPosition,
    onViewPosition,
    position,
    discipline,
  } = props
  return (
    <OverflowMenu.Root
      className={styles.overflowMenu}
      contentClassName={styles.overflowMenuContent}
      right
    >
      <OverflowMenu.Item
        onClick={() => {
          onViewPosition?.({ position, discipline })
        }}
      >
        View
      </OverflowMenu.Item>
      <OverflowMenu.Item
        onClick={() => {
          onEditPosition?.({ position, discipline })
        }}
      >
        Edit
      </OverflowMenu.Item>
      <OverflowMenu.Item
        onClick={() => {
          onAssignUserToPosition?.(position, 'overflow-menu')
        }}
      >
        Add someone to position
      </OverflowMenu.Item>
      <OverflowMenu.Item
        onClick={() => {
          onDeletePosition?.(position)
        }}
        variant="destructive"
      >
        Delete
      </OverflowMenu.Item>
    </OverflowMenu.Root>
  )
}
