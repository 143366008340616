import cn from 'classnames'
import * as React from 'react'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import { Card, CardProps } from '../../atoms/Card'
import styles from './EntityCard.module.scss'

export type RootProps = PropsWithRequiredChildren<Omit<CardProps, 'showHover'>>

export const Root = React.forwardRef<HTMLAnchorElement, RootProps>(
  (props, ref) => {
    const { onClick, children, className, ...restProps } = props

    return (
      <Card
        ref={ref}
        showHover={!!onClick}
        onClick={onClick}
        className={cn(
          styles.card,
          {
            [styles.showHover]: !!onClick,
          },
          className
        )}
        {...restProps}
      >
        {children}
      </Card>
    )
  }
)

Root.displayName = 'EntityCard'
