import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import styles from './Tooltip.module.scss'
import cn from 'classnames'

export type TooltipProps = {
  /**
   * This is what the tooltip will attach itself to, usually a button of some sort
   * @example <Tooltip><IconButton /></Tooltip>
   */
  children: React.ReactNode
  /**
   * This is the content that gets placed inside the tooltip
   * @example <Tooltip content="Cool tooltip!" />
   */
  content: React.ReactNode
  /**
   * Whether to show the tooltip open at first or not, useful for debugging and demonstration purposes
   * @example <Tooltip defaultOpen />
   */
  defaultOpen?: boolean
  /**
   * Optional side for the tooltip content to display on. This defaults to bottom but can be top/bottom/left/right
   */
  side?: TooltipPrimitive.TooltipContentProps['side']
  disabled?: boolean
  className?: string
  onOpenChange?: (open: boolean) => void
  portalled?: boolean
  open?: boolean
  contentClassName?: string
}

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (props, ref) => {
    const {
      content,
      children,
      side = 'bottom',
      disabled = false,
      defaultOpen = false,
      className,
      onOpenChange,
      portalled = true,
      open,
      contentClassName,
    } = props

    if (disabled) {
      return (
        <div ref={ref} className={className}>
          {children}
        </div>
      )
    }

    return (
      <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root
          defaultOpen={defaultOpen}
          delayDuration={300}
          onOpenChange={onOpenChange}
          open={open}
        >
          <TooltipPrimitive.Trigger asChild>
            <div ref={ref} className={cn(className, '!appearance-none')}>
              {children}
            </div>
          </TooltipPrimitive.Trigger>
          <TooltipPrimitive.Content
            // portalled={portalled}
            className={cn(styles.content, contentClassName)}
            sideOffset={2}
            side={side}
          >
            {content}
            <TooltipPrimitive.Arrow className={styles.arrow} />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    )
  }
)

Tooltip.displayName = 'Tooltip'
