import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from '../../../types/helpers'
import styles from './DisciplinesRow.module.scss'
import { PositionsRow } from './PositionsRow'
import { Spacer } from './Spacer'
import { TDiscipline, useFramework } from '../../..'

type DisciplinesRowProps = {
  disciplines: TDiscipline[]
} & DivPropsWithoutChildren

export const DisciplinesRow = React.forwardRef<
  HTMLDivElement,
  DisciplinesRowProps
>((props, ref) => {
  const { className, disciplines, ...restProps } = props

  const [sticky] = useFramework((hook) => [hook.sticky])

  return (
    <div
      ref={ref}
      className={cn(
        styles.disciplinesRow,
        sticky && styles.collapsed,
        className
      )}
      {...restProps}
    >
      <Spacer width="280px" className={styles.topLeftSpacer} />
      {disciplines.map((discipline) => {
        const positionsCount: number = discipline.positions?.length || 0
        const isLastDiscipline =
          discipline === disciplines[disciplines.length - 1]
        return (
          <React.Fragment key={discipline.name}>
            <div
              className={styles.disciplinesSection}
              data-element-id="disciplines-row"
            >
              <div className={cn(styles.discipline, className)}>
                <h2>
                  {discipline.name}
                  {positionsCount > 0 && (
                    <>
                      {' '}
                      <span aria-hidden>({positionsCount})</span>
                      <span className="sr-only">
                        {positionsCount} Positions in this discipline
                      </span>
                    </>
                  )}
                </h2>
              </div>
              <PositionsRow discipline={discipline} />
            </div>
            {!isLastDiscipline && <Spacer />}
          </React.Fragment>
        )
      })}
    </div>
  )
})

DisciplinesRow.displayName = 'DisciplinesRow'
