export const groupBy = <T, K extends keyof T>(
  array: T[],
  key: K,
  sortAttr?: keyof T
) => {
  return array.reduce((map, item) => {
    const itemKey = item[key] === undefined ? null : item[key]
    if (!map.has(itemKey)) {
      let grouped = array.filter((i) => i[key] === item[key])
      if (sortAttr) {
        grouped = sortBy(grouped, sortAttr)
      }
      map.set(itemKey, grouped)
    }

    return map
  }, new Map<T[K] | null, T[]>())
}

export const sortBy = <T>(array: T[], sortAttr: keyof T) => {
  return array.sort((a, b) => Number(a[sortAttr]) - Number(b[sortAttr]))
}

interface Identifiable {
  id: number | string
}

/**
 * Function for converting an array to a dictionary which uses ids as keys.
 * @example
 * arrayToDictionary([{ id: 1, name: "Cool" },{ id: 2, name: "Cats" }])
 * // { 1: { id: 1, name: "Cool" }, 2: { id: 2, name: "Cats" } }
 */
export const arrayToDictionary = <ItemType extends Identifiable>(
  items: ItemType[]
) =>
  items.reduce((hsh, item) => {
    return { ...hsh, [item.id]: item }
  }, {})

export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}
