import * as React from 'react'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { idWithPrefix, prefixedIds, useDragAndDrop } from './drag-and-drop'
import { SkillVariantCard } from './SkillVariantCard'
import styles from './Grid.module.scss'
import { useDroppable } from '@dnd-kit/core'
import { useSkills } from './skills/SkillsContext'
import { AddSkillsCard } from './AddSkillsCard'

export type SkillsListProps = {
  categoryId: string
  skillVariantIds: string[]
  lockedCategory?: boolean
  showAddSkills?: boolean
}

export const SkillsList = (props: SkillsListProps) => {
  const { skillVariantIds, categoryId, lockedCategory, showAddSkills } = props
  const { permissions, getCategory } = useSkills()

  const { draggedDrawerSkill, draggedCollection } = useDragAndDrop()
  const category = getCategory(categoryId)

  const { setNodeRef, isOver } = useDroppable({
    id: idWithPrefix(categoryId, 'skillList'),
    disabled:
      (!draggedDrawerSkill && !draggedCollection) ||
      (lockedCategory && !permissions?.allowLockingCategories),
  })

  return (
    <div className={styles.skillsList} ref={setNodeRef}>
      {isOver && <div className={styles.skillsListDropOverlay} />}
      <SortableContext
        id={idWithPrefix(categoryId, 'category')}
        items={prefixedIds(skillVariantIds, 'skillVariant')}
        strategy={verticalListSortingStrategy}
      >
        {skillVariantIds.map((skillVariantId) => (
          <SkillVariantCard
            inLockedCategory={lockedCategory}
            key={idWithPrefix(skillVariantId, 'skillVariant')}
            skillVariantId={skillVariantId}
          />
        ))}
      </SortableContext>
      {showAddSkills && <AddSkillsCard category={category} />}
    </div>
  )
}
