import { IAnyType, types } from 'mobx-state-tree'
import { DateType } from 'store/utils/date-type'
import { getRootStore } from 'store/utils/get-root-store'

export const AllowedNotificationTypes = [
  'ActionCompletedNotification' as const,
  'ActionCreatedNotification' as const,
  'CheckinAnswersUpdatedNotification' as const,
  'CheckinAssessorReviewReminderNotification' as const,
  'CheckinFinalisedNotification' as const,
  'CheckinFinalisedManagerNotification' as const,
  'CheckinInitiatedManagerNotification' as const,
  'CheckinJointReviewReminderNotification' as const,
  'CheckinLowActivityNotification' as const,
  'CheckinStageCompleteManagerNotification' as const,
  'CheckinStageCompleteNotification' as const,
  'CommentNotification' as const,
  'FeedbackItemCreatedNotification' as const,
  'FeedbackRequestCreatedNotification' as const,
  'ReportsHighActivityNotification' as const,
  'ReportsLowActivityNotification' as const,
  'WinCreatedManagerNotification' as const,
  'WinCreatedNotification' as const,
  'WinNudgeNotification' as const,
  'WinReactionNotification' as const,
]

export type NotificationType = typeof AllowedNotificationTypes[number]

export const createNotification = <
  AuthorType extends IAnyType,
  Type extends NotificationType
>(
  author: AuthorType,
  type: Type
) =>
  types
    .model('notifications', {
      id: types.identifier,
      author: author,
      content: types.maybeNull(types.string),
      resourceName: types.maybeNull(types.string),
      createdAt: DateType,
      notificationType: types.literal(type),
      read: types.boolean,
      readAt: types.maybeNull(DateType),
      url: types.string,
    })
    .actions((store) => ({
      async persistAsRead() {
        const root = getRootStore(store)
        await root.notifications.update(store.id, {
          id: store.id,
          type: 'notifications',
          read: true,
        })
      },
      markAsRead() {
        store.readAt = new Date()
        store.read = true
      },
    }))
