import * as React from 'react'

export type TimesProps = React.SVGProps<SVGSVGElement>

export const Times = (props: TimesProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4141 12L17.7071 7.707C18.0981 7.316 18.0981 6.684 17.7071 6.293C17.3161 5.902 16.6841 5.902 16.2931 6.293L12.0001 10.586L7.70713 6.293C7.31613 5.902 6.68413 5.902 6.29313 6.293C5.90213 6.684 5.90213 7.316 6.29313 7.707L10.5861 12L6.29313 16.293C5.90213 16.684 5.90213 17.316 6.29313 17.707C6.48813 17.902 6.74413 18 7.00013 18C7.25613 18 7.51213 17.902 7.70713 17.707L12.0001 13.414L16.2931 17.707C16.4881 17.902 16.7441 18 17.0001 18C17.2561 18 17.5121 17.902 17.7071 17.707C18.0981 17.316 18.0981 16.684 17.7071 16.293L13.4141 12Z"
      fill="currentColor"
    />
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="14"
      height="13"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4141 12L17.7071 7.707C18.0981 7.316 18.0981 6.684 17.7071 6.293C17.3161 5.902 16.6841 5.902 16.2931 6.293L12.0001 10.586L7.70713 6.293C7.31613 5.902 6.68413 5.902 6.29313 6.293C5.90213 6.684 5.90213 7.316 6.29313 7.707L10.5861 12L6.29313 16.293C5.90213 16.684 5.90213 17.316 6.29313 17.707C6.48813 17.902 6.74413 18 7.00013 18C7.25613 18 7.51213 17.902 7.70713 17.707L12.0001 13.414L16.2931 17.707C16.4881 17.902 16.7441 18 17.0001 18C17.2561 18 17.5121 17.902 17.7071 17.707C18.0981 17.316 18.0981 16.684 17.7071 16.293L13.4141 12Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" fill="currentColor" />
    </g>
  </svg>
)
