import { PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts'
import * as React from 'react'
import cn from 'classnames'
import { getArcWidth, getData, getPolarAngles } from './utils'
import { SkillsRadarChartProps } from './skills-radar.types'

export const SkillsRadarChart: React.VFC<SkillsRadarChartProps> = ({
  maxLevel: externalMaxLevel,
  onClick,
  series,
  size,
  className,
}) => {
  const { categoryCount, data, maxLevel } = React.useMemo(() => {
    return getData(series, externalMaxLevel)
  }, [externalMaxLevel, series])

  const arcWidth = React.useMemo(() => {
    return getArcWidth(data.length)
  }, [data])

  const polarAngles = React.useMemo(() => {
    return getPolarAngles(arcWidth, categoryCount)
  }, [arcWidth, categoryCount])

  return (
    <RadarChart
      className={cn({ '!cursor-pointer': onClick !== undefined }, className)}
      data={data}
      endAngle={-360}
      height={size + 5}
      onClick={onClick}
      outerRadius={size / 2}
      startAngle={0}
      width={size + 5}
    >
      <PolarGrid
        gridType="circle"
        outerRadius={size / 2}
        polarAngles={polarAngles}
        polarRadius={[size / 2]}
        stroke="#CECACA"
      />
      <PolarRadiusAxis
        domain={[-1, maxLevel]}
        stroke="#00000000"
        tick={false}
      />
      {Object.entries(series).map(([name, set]) => (
        <Radar
          className="stroke-theme-compliment-1 fill-theme-compliment-1"
          dataKey={name}
          dot={set.showDots}
          fillOpacity={0.5}
          key={name}
        />
      ))}
    </RadarChart>
  )
}
