import { exceptions } from 'app/packs/src/utils/exceptions'
import { createStore } from 'store/utils/create-store'
import { Notification, NotificationType } from './models'
export * from './models'

type NotificationUpdateAttributes = {
  id: string
  type: string
  read: boolean
}

type NotificationTypeFilters = {
  type?: NotificationType[]
}

type NotificiationStoreOptions = {
  Filters: NotificationTypeFilters
  Include: ['author']
  UpdateAttributes: NotificationUpdateAttributes
}

export const NotificationStore = createStore<
  typeof Notification,
  NotificiationStoreOptions
>('Notification', Notification)
  .views((store) => ({
    get allSorted() {
      return store.all.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    },
  }))
  .actions((store) => ({
    async markAllAsRead() {
      const result = await store.api.operations([
        {
          op: 'mark_all_as_read',
          data: {
            type: 'notifications',
            attributes: {
              read: true,
            },
          },
        },
      ])
      if (result.success) {
        store.all.forEach((notification) => notification.markAsRead())
        return true
      }

      const params = { ...result.extra, ...result.errors }
      exceptions.handleCustom('NotificationStoreError', {
        params,
        message: result.message,
      })
    },
  }))
