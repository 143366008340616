import { getType, Instance, resolvePath, types } from 'mobx-state-tree'
import toCamelCase from 'to-camel-case'
import { DateType } from './date-type'
import { getRootStore } from './get-root-store'
import { StoreModule } from './create-store'

export const baseModel = (name: string) =>
  types
    .model(name, {
      id: types.identifier,
      createdAt: DateType,
      updatedAt: DateType,
    })
    .views((self) => ({
      get _type() {
        return getType(self)
      },
    }))
    .actions((self) => ({
      destroy(params?: Record<string, unknown>) {
        const store = resolvePath(
          getRootStore(self),
          `/${toCamelCase(name)}`
        ) as Instance<StoreModule>

        if (params) {
          return store.destroy(self.id, params)
        } else {
          return store.destroy(self.id)
        }
      },
    }))

export interface BaseModel extends Instance<ReturnType<typeof baseModel>> {}
