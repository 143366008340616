import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { User } from '../users'
import { Team } from '../teams'

export const Membership = baseModel('memberships').props({
  team: reference(types.late((): IAnyModelType => Team)),
  user: reference(types.late((): IAnyModelType => User)),
  position: reference(types.late((): IAnyModelType => Position)),
})

export interface Membership extends Instance<typeof Membership> {}
export type TeamModelAttributes = SnapshotIn<typeof Membership>

export const MembershipStore = createStore<typeof Membership>(
  'Membership',
  Membership
)
