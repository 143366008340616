import * as React from 'react'
import type { DivPropsWithoutChildren } from '../../../types/helpers'
import { EditableFormContext } from './EditableFormContext'

export type TriggerProps = {
  /**
   * Children for the trigger, this should be a React Element so we can inject an onClick event to trigger
   * the swap to insert mode
   */
  children: React.ReactElement
} & DivPropsWithoutChildren

export const Trigger: React.VFC<TriggerProps> = (props) => {
  const { children, ...restProps } = props

  const { setMode, disabled } = React.useContext(EditableFormContext)

  const onClick = (e: React.SyntheticEvent) => {
    if (disabled) return

    e.stopPropagation()
    setMode('insert')
  }

  const childrenWithOnClick = React.cloneElement(children, {
    onClick,
    ...restProps,
  })

  return childrenWithOnClick
}
