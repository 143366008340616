import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'

export const validDragTypes = [
  'category',
  'skill',
  'skillVariant',
  'drawerSkill',
  'skillList',
  'collection',
] as const

/**
 * Function which acts as a type guard making sure the return value is an item from `validDragTypes`
 */
export const getDragType = (
  e: DragStartEvent | DragEndEvent
): (typeof validDragTypes)[number] => {
  if (!e.active?.data?.current)
    throw new Error('No current data for the active dragged item')

  const activeType = e.active.data.current.type

  if (!validDragTypes.includes(activeType)) throw new Error('Invalid drag type')

  return activeType
}

/**
 * Function for getting a drag event's active id without its prefix
 */
export const getId = (e: DragStartEvent | DragEndEvent) => {
  if (!e.active?.id) throw new Error('Active id not found in drag event')
  return idWithoutPrefix(e.active.id)
}

/**
 * Function for getting the target item id without its prefix
 */
export const getTargetId = (e: DragEndEvent): string | null => {
  return e?.over ? idWithoutPrefix(e.over.id) : null
}

/**
 * Function for getting a category id from a drag event
 */
export const getTargetContainerId = (
  e: DragOverEvent | DragEndEvent
): string | null => {
  if (!e.over) return null

  let targetContainerId: string
  const categoryRegex = new RegExp('^category_')
  const skillsListRegex = new RegExp('^skillList_')
  const hoveredId = `${e.over.id}`
  const isCategoryOrSkillsList =
    categoryRegex.test(hoveredId) || skillsListRegex.test(hoveredId)

  if (isCategoryOrSkillsList) {
    targetContainerId = hoveredId
  } else {
    targetContainerId = e.over?.data?.current?.sortable?.containerId
  }

  if (targetContainerId === 'Sortable') return null

  return targetContainerId ? idWithoutPrefix(targetContainerId) : null
}

/**
 * Function for getting the active index from a drag event
 */
export const getIndex = (
  e: DragStartEvent | DragOverEvent | DragEndEvent
): number => {
  const index = e.active?.data?.current?.sortable?.index

  if (!Number.isSafeInteger(index)) throw new Error('Index not found!')

  return index
}

/**
 * Function for getting the target index from a drag event
 */
export const getTargetIndex = (
  e: DragOverEvent | DragEndEvent
): number | undefined => {
  return e.over?.data?.current?.sortable?.index
}

/**
 * Utility for prepending an ID with a prefix
 * @example
 * idWithPrefix("1", "category")
 * // "category_1"
 */
export const idWithPrefix = (
  id: string,
  prefix: (typeof validDragTypes)[number]
) => {
  // does the string start with prefix_ already?
  const regex = new RegExp(`^(?:${validDragTypes.join('|')}_)`)
  if (regex.test(id)) return id

  return `${prefix}_${id}`
}

/**
 * Utility for removing a prefix from an id
 * @example
 * idWithoutPrefix("category_1")
 * // "1"
 */
export const idWithoutPrefix = (id: UniqueIdentifier) => {
  return validDragTypes.reduce(
    (id, type) => id.replace(`${type}_`, ''),
    id.toString()
  )
}

/**
 * Helper function for prefixing a list of string ids with a drag type - this is passed to sortable contexts for preventing naming conflicts
 */
export const prefixedIds = (
  ids: string[],
  prefix: (typeof validDragTypes)[number]
) => {
  return ids.map((id) => idWithPrefix(id, prefix))
}
