import { arrayToDictionary, sortBy } from '../../../../../utils/array-helpers'
import type {
  TCategory,
  TSkill,
  TSkillVariant,
} from '../../../../../types/entities'
import type { SkillsDictionary } from '../SkillsGrid.types'
import type { SkillVariantsDictionary } from '../SkillsGrid.types'

export const UNCATEGORISED_ID = '-1'

/**
 * Utility for creating a skills dictionary with stringified ids
 * @example
 * createSkillsDictionary([{ id: 1, name: "Pragmatism" }, { id: 2, name: "Adaptability" }])
 * // { "1": { id: 1, name: "Pragmatism" }, "2": { id: 2, name: "Adaptability" } }
 */
export const createSkillsDictionary = (
  skills: readonly TSkill[]
): SkillsDictionary => {
  const dictionary = Object.freeze(arrayToDictionary([...skills]))

  return dictionary
}

export const createSkillVariantsDictionary = (
  skillVariants: readonly TSkillVariant[]
): SkillVariantsDictionary => {
  const dictionary = Object.freeze(arrayToDictionary([...skillVariants]))

  return dictionary
}

/**
 * returns an object with category ids as keys, and skill ids as values
 * @example
 * { "category_1": ["skill_1", "skill_2"] }
 **/
export const getCategorySkillIdMap = (
  categories: readonly TCategory[],
  skills: readonly TSkill[]
) => {
  const sortedCategories = [...categories].sort((a, b) => {
    if (a.listPosition === b.listPosition) {
      return a.id - b.id
    }
    return a.listPosition - b.listPosition
  })

  const uncategorisedSkillIds = sortBy(
    skills.filter((skill) => skill.categoryId === null),
    'sortIndex'
  ).map((skill) => skill.id.toString())

  const categorySkillIdMap = new Map<string, string[]>([
    [UNCATEGORISED_ID, uncategorisedSkillIds],
  ])

  sortedCategories.reduce((categorisedSkills, category) => {
    const skillsInCategory = skills.filter((s) => s.categoryId === category.id)
    const sortedSkillsInCategory = sortBy(skillsInCategory, 'sortIndex')

    categorisedSkills.set(
      category.id.toString(),
      sortedSkillsInCategory.map((s) => s.id.toString())
    )

    return categorisedSkills
  }, categorySkillIdMap)

  return categorySkillIdMap
}

/**
 * returns an object with category ids as keys, and skill_variants ids as values
 * @example
 * { "category_1": ["skill_1", "skill_2"] }
 **/
export const getCategorySkillVariantIdMap = (
  categories: readonly TCategory[],
  skillVariants: readonly TSkillVariant[]
) => {
  const sortedCategories = sortBy([...categories], 'listPosition')

  const uncategorisedSkillVariantlIds = sortBy(
    skillVariants.filter((skillVariant) => skillVariant.categoryId === null),
    'sortIndex'
  ).map((skillVariant) => skillVariant.id.toString())

  const categorySkillVariantIdMap = new Map<string, string[]>([
    [UNCATEGORISED_ID, uncategorisedSkillVariantlIds],
  ])

  sortedCategories.reduce((categorisedSkillVariants, category) => {
    const skillsVariantsInCategory = skillVariants.filter(
      (sv) => sv.categoryId === category.id
    )
    const sortedSkillsInCategory = sortBy(skillsVariantsInCategory, 'sortIndex')

    categorisedSkillVariants.set(
      category.id.toString(),
      sortedSkillsInCategory.map((s) => s.id.toString())
    )

    return categorisedSkillVariants
  }, categorySkillVariantIdMap)

  return categorySkillVariantIdMap
}

/**
 * Simple helper function from getting a category in an array of categories. Throws an error if the category can't be found which helps type guard the category going forwards
 */
export const getCategoryInCategories = (
  categoryId: string,
  categories: readonly TCategory[]
): TCategory | null => {
  const category = categories.find(
    (category) => category.id.toString() === categoryId.toString()
  )

  return category || null
}
