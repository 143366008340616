import * as React from 'react'
import cn from 'classnames'
import styles from './Textarea.module.scss'

export type TextareaProps = {
  autoFocus?: boolean
  isInvalid?: boolean
  naked?: boolean
} & React.ComponentPropsWithoutRef<'textarea'>

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      autoFocus = false,
      className,
      isInvalid = false,
      naked = false,
      ...restProps
    } = props

    return (
      <textarea
        autoFocus={autoFocus}
        className={cn(
          styles.textarea,
          {
            [styles.isInvalid]: isInvalid,
            [styles.naked]: naked,
          },
          className
        )}
        aria-invalid={isInvalid || undefined}
        {...restProps}
        ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
      />
    )
  }
)

Textarea.displayName = 'Textarea'
