import create from 'zustand'
import type { TDiscipline } from '../../types/entities'
import { groupBy, sortBy } from '../../utils/array-helpers'
import type {
  Permissions,
  EventHandlers,
  PositionColumns,
} from './use-positions-table.types'

export type UsePositionsTableProps = {
  disciplines: TDiscipline[]
  setDisciplines: (disciplines: TDiscipline[]) => void
  reset: () => void
  permissions: Permissions
  setPermissions: (permissions: Permissions) => void
  eventHandlers: EventHandlers
  setEventHandlers: (eventHandlers: EventHandlers) => void
  hasPositionsInTable: () => boolean
  getPositionColumns: (disciplineId: number) => PositionColumns
  getMaxSeniorityLevelInDiscipline: (disciplineId: number) => number
  getSeniorityLevels: (disciplineId: number) => number[]
  maxSeniorityLevelInAnyDiscipline: () => number
  /**
   * Method to find out how many separators should be shown. This is based off the maximum number of positions in any discipline within the table, minus 1 so we don't show it after the last position
   */
  separatorCount: () => number
}

export const usePositionsTable = create<UsePositionsTableProps>((set, get) => ({
  disciplines: [],
  setDisciplines: (disciplines) => {
    const sortedDisciplines = sortBy(disciplines, 'sortOrder')

    set({ disciplines: sortedDisciplines })
  },
  reset: () => {
    set({
      disciplines: [],
      permissions: {},
    })
  },
  permissions: {},
  setPermissions: (permissions) => set({ permissions }),
  separatorCount: () => {
    return get().maxSeniorityLevelInAnyDiscipline() - 1
  },
  maxSeniorityLevelInAnyDiscipline: () => {
    return get().disciplines.reduce((maxLength, discipline) => {
      const maxSeniorityLevelInDiscipline =
        get().getMaxSeniorityLevelInDiscipline(discipline.id)

      return Math.max(maxSeniorityLevelInDiscipline, maxLength)
    }, 0)
  },
  hasPositionsInTable: () => {
    return get().disciplines.some(
      (discipline) => discipline.positions && discipline.positions.length > 0
    )
  },
  eventHandlers: {},
  setEventHandlers: (eventHandlers) => set({ eventHandlers }),
  getPositionColumns: (disciplineId) => {
    const discipline = get().disciplines.find(
      (discipline) => discipline.id === disciplineId
    ) as TDiscipline

    const positionColumns: PositionColumns = new Map()

    groupBy(discipline.positions || [], 'seniorityLevel').forEach(
      (positions, seniorityLevel) => {
        const sortedPositions = positions.sort((a, b) => {
          return a.name && b.name ? a.name.localeCompare(b.name) : -1
        })

        positionColumns.set(seniorityLevel, sortedPositions)
      }
    )

    return positionColumns
  },
  getMaxSeniorityLevelInDiscipline: (disciplineId): number => {
    const positionColumns = get().getPositionColumns(disciplineId)
    const rowLength = [...positionColumns.keys()].length

    let maxSeniorityLevel = 0

    if (rowLength === 0) maxSeniorityLevel = 3

    if (rowLength > 0) {
      const keys = [...positionColumns.keys()] as any
      maxSeniorityLevel = Math.max(...keys)

      // we want a minimum of 3 to show, so if there are less than 3
      // positions already, make it fill to 3 first
      if (maxSeniorityLevel < 3) maxSeniorityLevel = 3
      else if (get().permissions.allowCreatePosition) maxSeniorityLevel += 1
    }

    return maxSeniorityLevel
  },
  getSeniorityLevels: (disciplineId): number[] => {
    let minSeniorityLevel = 1

    const maxInAnyDiscipline = get().maxSeniorityLevelInAnyDiscipline()

    if (!get().permissions.allowCreatePosition) {
      const seniorityLevels = get().disciplines.reduce(
        (filtered: number[], discipline) => {
          discipline.positions?.forEach((position) =>
            filtered.push(position.seniorityLevel)
          )

          return filtered
        },
        []
      )

      if (seniorityLevels.length > 0) {
        minSeniorityLevel = Math.min(...seniorityLevels)
      }
    }

    return [...Array(maxInAnyDiscipline + 1).keys()].slice(minSeniorityLevel)
  },
}))
