import * as React from 'react'
import cn from 'classnames'
import { getPositionSeniorityKey } from '../../../utils/get-position-seniority-key'
import { PlusOutline } from '@easy-eva-icons/react'
import type { TDiscipline } from '../../../types/entities'
import styles from './EmptyPositionCard.module.scss'
import { Card } from '../../atoms/Card'
import * as EditableForm from '../EditableForm'
import { Tag } from '../../atoms/Tag'

export type EmptyPositionCardProps = {
  discipline: TDiscipline
  seniorityLevel: number
  allowCreatePosition?: boolean
  onCreatePosition?: (
    discipline: TDiscipline,
    seniorityLevel: number,
    name?: string
  ) => void
  showLabels?: boolean
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

export const EmptyPositionCard: React.VFC<EmptyPositionCardProps> = (props) => {
  const {
    discipline,
    seniorityLevel,
    allowCreatePosition = false,
    onCreatePosition,
    showLabels = true,
    className,
    ...restProps
  } = props

  const [name, setName] = React.useState<string>('')

  if (!allowCreatePosition) return <div className={cn(styles.spacingCard)} />

  const seniorityKey = getPositionSeniorityKey(discipline.name, seniorityLevel)

  const submitHandler = () =>
    onCreatePosition?.(discipline, seniorityLevel, name)

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation()
    setName(e.currentTarget.value)
  }

  return (
    <div
      className={cn(
        styles.emptyPositionCard,
        {
          [styles.noBorder]: !allowCreatePosition,
        },
        className
      )}
      {...restProps}
    >
      <EditableForm.Root onSubmit={submitHandler}>
        <EditableForm.VisualContent>
          <EditableForm.Trigger>
            <Card
              className={cn(styles.emptyContent, 'group')}
              transparent
              showHover={allowCreatePosition}
              tabIndex={0}
            >
              <span className="sr-only">
                Create a position in the {discipline.name} discipline with a
                seniority level of {seniorityLevel}
              </span>
              <div className={styles.body}>
                <div className={styles.text}>Add position</div>
                <PlusOutline className={styles.icon} aria-hidden="true" />
              </div>
            </Card>
          </EditableForm.Trigger>
        </EditableForm.VisualContent>
        <EditableForm.InsertContent>
          <Card
            className={cn(styles.emptyContent, styles.insertContent)}
            transparent
            showHover={allowCreatePosition}
            tabIndex={0}
          >
            {showLabels && <Tag variant="naked">{seniorityKey}</Tag>}
            <div className={styles.inputWrapper}>
              <EditableForm.Input
                placeholder="Position Title"
                value={name}
                onKeyDown={(e) => {
                  // prevent typing from propagating upstream which could conflict with drag and drop
                  e.stopPropagation()
                }}
                onChange={onChange}
                className={styles.input}
              />
              <EditableForm.Confirm type="submit" className={styles.confirm} />
            </div>
            <EditableForm.ErrorMessage />
          </Card>
        </EditableForm.InsertContent>
      </EditableForm.Root>
    </div>
  )
}
