import * as React from 'react'
import cn from 'classnames'
import styles from './IconButton.module.scss'
import { Icon, IconProps } from '../Icon'
import { PropsWithoutChildren } from '../../..'

export type IconButtonProps = {
  /**
   * Title can be passed for accessibility purposes since this component is usually used with icons
   * @example <IconButton title="Add new team member" />
   */
  title?: string
  iconClassName?: string
} & Pick<IconProps, 'size' | 'hideBorder' | 'variant' | 'children'> &
  PropsWithoutChildren<React.ComponentPropsWithRef<'button'>>

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      size,
      hideBorder,
      variant,
      className,
      children,
      title,
      iconClassName,
      type = 'button',
      ...restProps
    } = props

    return (
      <button
        ref={ref}
        title={title}
        className={cn(styles.iconButton, className)}
        type={type}
        {...restProps}
      >
        <Icon
          hideBorder={hideBorder}
          variant={variant}
          size={size}
          className={cn(
            { [styles.border]: !hideBorder },
            styles.icon,
            iconClassName
          )}
        >
          {children}
        </Icon>
      </button>
    )
  }
)

IconButton.displayName = 'IconButton'
