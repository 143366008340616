import { types } from 'mobx-state-tree'

export const FeatureGateStore = types
  .model('FeatureGateStore', {
    data: types.optional(types.map(types.boolean), {}),
  })
  .views((store) => ({
    canAccess(name: string) {
      return !!store.data.get(name)
    },
  }))
  .actions((store) => ({
    load(data: { [key: string]: boolean }) {
      store.data.replace(data)
    },
    clear() {
      store.data.clear()
    },
  }))
