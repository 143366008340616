import * as React from 'react'
import { useDrawerContext } from '../../../../contexts/drawer-context'
import { SKILLS_DRAWER_ID } from '../SkillsDrawer/utils'
import { TCategory } from '../../../../types/entities'
import { Button } from '../../../atoms/Button'

export type AddSkillsCardProps = {
  category: TCategory | null
}

export const AddSkillsCard: React.VFC<AddSkillsCardProps> = (props) => {
  const { category } = props

  const drawerContext = useDrawerContext()

  const onClick = () => {
    drawerContext?.openDrawer(SKILLS_DRAWER_ID, { category })
  }

  return (
    <div className="w-full flex">
      <Button
        variant="outline"
        colourVariant="paper"
        fullWidth={true}
        onClick={onClick}
      >
        Add skill
      </Button>
    </div>
  )
}
