import * as React from 'react'
import { ConfirmationDialogContextProvider } from './ConfirmationDialogContext/ConfirmationDialogContextProvider'
import { DrawerContextProvider } from './DrawerContext/DrawerContextProvider'
import { ModalContextProvider } from './ModalContext/ModalContextProvider'

export const RootProvider: React.VFC<{ children: React.ReactNode }> = (
  props
) => {
  const { children } = props
  return (
    <ConfirmationDialogContextProvider>
      <DrawerContextProvider>
        <ModalContextProvider>{children}</ModalContextProvider>
      </DrawerContextProvider>
    </ConfirmationDialogContextProvider>
  )
}
