import cn from 'classnames'
import * as React from 'react'
import * as InputGroup from '../../../molecules/InputGroup'
import styles from './NewSkillForm.module.scss'
import { Input } from '../../../atoms/Input'
import { Button } from '../../../atoms/Button'
import { useMounted } from '../../../../hooks/use-mounted'

export type NewSkillFormProps = Omit<
  React.ComponentProps<'form'>,
  'children' | 'onSubmit'
> & {
  /**
   * Hook called when the user submits the New Skill form
   */
  onSubmit(name: string): void | Promise<void>
}

export const NewSkillForm = React.forwardRef<
  HTMLFormElement,
  NewSkillFormProps
>((props, ref) => {
  const { onSubmit, ...restProps } = props

  const [name, setName] = React.useState('')
  const [error, setError] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const mounted = useMounted()

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault()
    if (!name?.length) return setError('Please provide a skill name')

    setLoading(true)
    try {
      await onSubmit(name)
    } catch (e) {
      const msg = 'Something went wrong. Please try again or contact support.'
      if (mounted.current) setError(msg)
    } finally {
      if (mounted.current) setLoading(false)
    }
  }
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setError(null)
    setName(e.target.value)
  }

  return (
    <form ref={ref} {...restProps} onSubmit={handleSubmit}>
      <InputGroup.Root className={styles.inputGroup}>
        <InputGroup.Label className={styles.label}>
          What&apos;s the skill&apos;s name?
          <Input
            placeholder="E.g. Communication"
            value={name}
            onChange={handleChange}
            isInvalid={!!error}
            disabled={loading}
            autoFocus
          />
          {error && (
            <InputGroup.ErrorMessage className={styles.errorMessage}>
              {error}
            </InputGroup.ErrorMessage>
          )}
        </InputGroup.Label>
        <Button disabled={loading} size="lg" className={styles.button}>
          Create Skill
        </Button>
      </InputGroup.Root>
    </form>
  )
})

NewSkillForm.displayName = 'NewSkillForm'
