import { Trash2Outline } from '@easy-eva-icons/react'
import * as React from 'react'
import { IconButton, IconButtonProps } from '../../atoms/IconButton'
import * as ConfirmationDialog from '../ConfirmationDialog'

export type DeleteButtonProps = {
  /**
   * Used for accessibility to show 'Delete something' when hovering the button, where something is the entity name
   * @example "discipline" // Delete discipline
   */
  entityName: string
  /**
   * Message to be shown in the confirmation dialog
   */
  warningMessage?: string
  /**
   * Callback to be called once the dialog has been confirmed. If the dialog is cancelled, this won't be called
   */
  onDelete?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void> | void
  /**
   * Component to use as a trigger. Defaults to a Trash IconButton
   */
  children?: JSX.Element
} & Omit<IconButtonProps, 'hideBorder' | 'children' | 'title'>

export const DeleteButton: React.VFC<DeleteButtonProps> = (props) => {
  const { entityName, size, warningMessage, onDelete, children, ...restProps } =
    props

  const internalWarningMessage =
    warningMessage ||
    `This action cannot be undone. This will permanently delete the ${entityName}.`

  return (
    <ConfirmationDialog.Root
      title="Are you absolutely sure?"
      body={internalWarningMessage}
      confirmLabel={`Yes, delete ${entityName}`}
      onConfirm={onDelete}
    >
      <ConfirmationDialog.Trigger asChild>
        {children || (
          <IconButton
            hideBorder
            {...restProps}
            size={size}
            title={`Delete ${entityName}`}
          >
            <Trash2Outline />
          </IconButton>
        )}
      </ConfirmationDialog.Trigger>
    </ConfirmationDialog.Root>
  )
}
