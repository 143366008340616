import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { OnboardingSurvey } from '../onboarding-survey'
import { reference } from 'store/utils/reference'
import { getRootStore } from 'store/utils/get-root-store'
import { User } from '../users'

export const Org = baseModel('orgs')
  .props({
    activeSubscription: types.boolean,
    avatarUrl: types.maybeNull(types.string),
    canCheckinAgainstAnyPosition: types.maybeNull(types.boolean),
    canSelfAssignPosition: types.maybeNull(types.boolean),
    showSalaries: types.boolean,
    membershipsVisible: types.maybeNull(types.boolean),
    name: types.string,
    onboardingSurvey: types.maybeNull(reference(OnboardingSurvey)),
    publicFramework: types.boolean,
    remainingUsers: types.number,
    slug: types.string,
  })
  .views((self) => ({
    get basePath() {
      return `/orgs/${self.slug}`
    },
    get editPath() {
      return `${this.basePath}/edit`
    },
    get skillsPath() {
      return `${this.basePath}/skills`
    },
    get onlyOneAdmin(): boolean {
      return (
        getRootStore(self).users.active.filter(
          (user: User) => user.org?.id === self.id && user.isAdmin
        ).length === 1
      )
    },
    invitingState(inviteesCount: number): OrgInviteState {
      if (self.activeSubscription) {
        return 'active-subscription'
      } else if (self.remainingUsers > inviteesCount) {
        return 'freemium-seats-left'
      } else if (
        self.remainingUsers === inviteesCount &&
        self.remainingUsers > 0
      ) {
        return 'freemium-will-use-all-seats'
      } else {
        return 'freemium-no-seats-left'
      }
    },
    isAtSeatsLimit(inviteesCount: number): boolean {
      return (
        this.invitingState(inviteesCount) === 'freemium-no-seats-left' ||
        this.invitingState(inviteesCount) === 'freemium-will-use-all-seats'
      )
    },
  }))

export type OrgInviteState =
  | 'freemium-seats-left'
  | 'active-subscription'
  | 'freemium-will-use-all-seats'
  | 'freemium-no-seats-left'

export interface Org extends Instance<typeof Org> {}
export type OrgModelAttributes = SnapshotIn<typeof Org>

export const OrgStore = createStore<typeof Org>('Org', Org)
