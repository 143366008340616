import * as React from 'react'
import cn from 'classnames'
import type { PropsWithRequiredChildren } from '../../../types/helpers'
import {
  Button as BaseButton,
  ButtonOwnProps as BaseButtonProps,
} from '../../atoms/Button'
import styles from './Button.module.scss'
import type { PolymorphicComponentProps } from 'react-polymorphic-box'

const defaultElement = 'button'

export type ButtonOwnProps = PropsWithRequiredChildren<
  Omit<BaseButtonProps, 'variant' | 'themed' | 'size'>
>

export type ButtonProps<E extends React.ElementType> =
  PolymorphicComponentProps<E, ButtonOwnProps>

export const Button: <E extends React.ElementType = typeof defaultElement>(
  props: ButtonProps<E>
  // eslint-disable-next-line
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    { children, className, ...restProps }: ButtonProps<E>,
    ref: typeof restProps.ref
  ) => {
    return (
      <BaseButton
        className={cn(styles.button, className)}
        ref={ref}
        {...restProps}
      >
        {children}
      </BaseButton>
    )
  }
)
