import cn from 'classnames'
import { HTMLMotionProps, motion } from 'framer-motion'
import * as React from 'react'
import styles from './Footer.module.scss'

export type FooterProps = HTMLMotionProps<'footer'> & {
  animation?: boolean
}

export const Footer: React.VFC<FooterProps> = ({
  className,
  animation,
  ...props
}) => (
  <motion.footer
    initial={animation && { opacity: 0, y: '100%' }}
    animate={animation && { opacity: 1, y: 0 }}
    exit={animation ? { opacity: 0, y: '100%' } : undefined}
    transition={{ duration: 0.25 }}
    className={cn(styles.footer, className)}
    {...props}
  />
)
