import { formatRelative } from 'src/design-system'
import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { compactArray } from 'store/utils/compact-array'
import { createStore } from 'store/utils/create-store'
import { getRootStore } from 'store/utils/get-root-store'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { SkillVariant } from '../skill-variants'
import { User } from '../users'

const sortByCreatedAt = (a: Comment, b: Comment) => {
  return a.createdAt.getTime() - b.createdAt.getTime()
}

export const Comment = baseModel('comments')
  .props({
    commentable: reference(types.union(Position, SkillVariant)),
    content: types.maybeNull(types.string),
    parent: reference(types.late((): IAnyModelType => Comment)),
    resolved: types.boolean,
    taggedUsers: compactArray(reference(User, { required: true })),
    user: reference(User, { required: true }),
  })
  .views((self) => ({
    get replies(): Comment[] {
      return getRootStore(self)
        .comments.filtered((comment: Comment) => comment.parent?.id === self.id)
        .sort(sortByCreatedAt)
    },
    get relativeDate() {
      return formatRelative(self.createdAt)
    },
    get topLevel() {
      return !self.parent
    },
    get repliesWithContent(): Comment[] {
      return this.replies.filter((reply) => !!reply.content) || []
    },
    get lastReplyWithContent(): Comment | null {
      if (!this.replies?.length) return null

      return this.repliesWithContent[this.repliesWithContent.length - 1]
    },
    get lastReply(): Comment | null {
      if (!this.replies?.length) return null

      return this.replies[this.replies.length - 1]
    },
    get threadResolved(): boolean {
      return this.lastReply?.resolved ?? false
    },
    get commentableType(): string | undefined {
      return self.commentable?._type.name
    },
    get frameworkIds(): string[] {
      switch (this.commentableType) {
        case 'positions':
          return [(self.commentable as Position).framework?.id]
        case 'skill_variants':
          return (self.commentable as SkillVariant).frameworkIds
        default:
          return []
      }
    },
  }))
  .actions((self) => ({
    resolve() {
      const { comments } = getRootStore(self)
      if (!self.commentable) return
      if (self.parent) return

      comments.create(
        {
          commentableId: self.commentable.id,
          commentableType: self.commentable._type.name,
          parent: self.id,
          resolved: !self.lastReply?.resolved,
        },
        { include: ['commentable'] }
      )
    },
  }))

export interface Comment extends Instance<typeof Comment> {}
export interface CommentAttributes extends SnapshotIn<typeof Comment> {}

export type ModifiableCommentAttributes = {
  commentableId?: string
  commentableType?: string
  content?: string
  parent?: string
  resolved?: boolean
  taggedUsers?: string[]
}

type CommentFilters = {
  commentable_id?: string | string[]
  commentable_type?: string | string[]
  unresolved?: boolean
}

type CommentStoreOptions = {
  Include: Array<'commentable'>
  CreateAttributes: ModifiableCommentAttributes
  Filter: CommentFilters
  UpdateAttributes: Pick<
    ModifiableCommentAttributes,
    'content' | 'resolved' | 'taggedUsers'
  >
}

export const CommentStore = createStore<typeof Comment, CommentStoreOptions>(
  'Comment',
  Comment,
  {
    hooks: {
      afterDestroy(self) {
        const { comments } = getRootStore(self)
        self.replies.forEach((comment) => comments.unload(comment.id))
      },
    },
  }
)
  .actions((store) => ({
    fetchUnresolvedForFramework(frameworkId: string) {
      return store.fetchAll(
        {
          filter: {
            unresolved: true,
          },
        },
        { namespace: ['frameworks', frameworkId] }
      )
    },
  }))
  .views(() => ({
    sortedParentComments(comments: Comment[]): Comment[] {
      const parentComments: Comment[] = comments.filter(
        (comment) => !comment.parent?.id
      )

      return parentComments.sort(sortByCreatedAt)
    },
    openCommentCount(comments: Comment[]): number {
      return comments.reduce((total, comment) => {
        if (comment.threadResolved || !comment.topLevel) return total

        return total + 1
      }, 0)
    },
  }))
