import * as React from 'react'
import type { TSort } from './utils'

export type SortContextProps = {
  /**
   * Current sort in place - an object containing both the id and direction of the sort. To update this you should use the `toggleSort` function which will toggle between asc/desc, or set the sort order to asc if the passed id doesn't have an active sort
   * @example { id: "name", direction: "desc" }
   */
  activeSort: TSort
  setActiveSort: (activeSort: TSort) => void
  onSortChange?: (newSort: TSort) => void
  allowDefaultSort?: false
}

export const SortContext = React.createContext({
  activeSort: null,
} as SortContextProps)
