import { types } from 'mobx-state-tree'

export const OrgLimitsStore = types
  .model('OrgLimitsStore', {
    data: types.optional(types.map(types.maybeNull(types.number)), {}),
  })
  .actions((store) => ({
    load(data: { [key: string]: number }) {
      store.data.replace(data)
    },
    clear() {
      store.data.clear()
    },
  }))
