import { deleteDB, IDBPDatabase } from 'idb'
import {
  getSnapshot,
  IJsonPatch,
  Instance,
  onPatch,
  applySnapshot,
} from 'mobx-state-tree'
import { RootState, StoreModule, storeModules } from 'store/modules/root'
import { applyState } from 'store/utils/apply-state'
import { exceptions } from '../../utils/exceptions'
import { DatabaseInitializer } from './database'

type Value = { id: string }
export type StoreSchema = {
  [key in StoreModule]: { key: string; value: Value }
}

/**
 * Persists and syncs the store to IndexedDB
 **/
export class StorePersister {
  /**
   * Change this when persistedModules changes.
   * Schemas of indiviual models can change without changing the schema version.
   */
  static schemaVersion = 4
  static storePrefix = 'progression-store'

  static async destroyDatabase() {
    const dbs = await indexedDB.databases()
    return Promise.all(
      dbs.map(async (db) => {
        if (!db.name) return
        if (db.name.startsWith(StorePersister.storePrefix)) {
          await deleteDB(db.name)
        }
      })
    )
  }

  constructor(
    private store: Instance<RootState>,
    private persistedModules: StoreModule[] = storeModules,
    private debug = false
  ) {}
  private db?: IDBPDatabase<StoreSchema>
  private databaseInitializer = new DatabaseInitializer(
    StorePersister.schemaVersion
  )

  async clearDatabase() {
    try {
      const db = await this.databaseInitializer.open(
        this.storeName,
        this.persistedModules
      )

      for (const key of this.persistedModules) {
        if (db.objectStoreNames.contains(key)) {
          await db.clear(key)
        }
      }
    } catch (e) {
      exceptions.handle(e as Error)
    }
  }

  async initialize() {
    try {
      if (this.debug) console.time(`[store-persist] opening database`)
      this.db = await this.databaseInitializer.open(
        this.storeName,
        this.persistedModules
      )
      if (this.debug) console.timeEnd(`[store-persist] opening database`)

      if (this.debug) console.time(`[store-persist] loading store`)
      await this.loadStore()
      if (this.debug) console.timeEnd(`[store-persist] loading store`)

      onPatch(this.store, this.handlePatch.bind(this))
    } catch (e) {
      exceptions.handle(e as Error)
    }
  }

  // loads data from indexedDB into the store
  private async loadStore() {
    if (!this.db) return
    const { db } = this

    const resources = await Promise.all(
      this.persistedModules.map(async (key) => {
        const data = await db.getAll(key)
        this.log(`Loading ${key}: ${data.length} items`)

        const withIds = data.map((item) => [item.id, item])
        return [key, Object.fromEntries(withIds)] as const
      })
    )

    const newState = Object.fromEntries(resources)
    try {
      applyState(this.store, newState)
    } catch (e) {
      exceptions.handle(e as Error)
      // if we fail to load the data, clearing the store
      // will cause a full bootstrap from the API
      applySnapshot(this.store, {})
      await this.clearDatabase()
    }
  }

  // takes a patch from the store and syncs indexedDB
  private async handlePatch(patch: IJsonPatch) {
    const { valid, module, id, op } = this.validPatch(patch)
    if (module && id) this.log('Patch', { module, id, op, persisted: valid })
    if (!this.db || !valid) return

    try {
      switch (op) {
        case 'remove':
          return await this.deleteItem(module, id)
        default:
          return await this.updateItem(module, id)
      }
    } catch (e) {
      exceptions.handle(e as Error)
    }
  }

  // fetches a single item from the store and syncs indexedDB
  private updateItem(module: StoreModule, id: string) {
    if (!this.db) return

    const value = this.store[module].data.get(id)
    if (!value) return this.deleteItem(module, id)

    const snapshot = getSnapshot(value)
    return this.db.put(module, snapshot)
  }

  // removes a single item from indexedDB
  private deleteItem(module: StoreModule, id: string) {
    if (!this.db) return

    return this.db.delete(module, id)
  }

  // parses a patch from the store and checks if we should sync it
  private validPatch({ path, op }: IJsonPatch) {
    const [module, dataKey, id] = path.slice(1).split('/')
    const valid =
      this.isStoreModule(module) &&
      this.persistedModules.includes(module) &&
      dataKey === 'data' &&
      !!id
    if (!valid) return { valid, module, id, op }

    return { valid, module, id, op }
  }

  private log(...args: Parameters<typeof console.debug>) {
    if (!this.debug) return
    console.debug('[store-persist]', ...args)
  }

  private isStoreModule(key: string): key is StoreModule {
    return (storeModules as string[]).includes(key)
  }

  private get storeName() {
    return `${StorePersister.storePrefix}-${window.btoa(
      `${this.store.currentUser?.org?.id}-${this.store.currentUser?.id}`
    )}`
  }
}
