import * as React from 'react'
import cn from 'classnames'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './Dropdown.module.scss'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { useDropdownContext } from './DropdownContext'
import { DEFAULT_ALIGN } from './shared'

export type LinkProps = {
  /**
   * Required children prop for the link, this can just be text like 'Dashboard'
   */
  children: React.ReactNode
  /**
   * Whether the link is active, used to indicate the current page you're on
   * @example active={location.pathname.includes("/teams")}
   */
  active?: boolean
  /**
   * Whether or not to apply external link props including `target="_blank"` and `rel="noopener noreferrer"`
   */
  isExternal?: boolean
  /**
   * Where the text should be aligned in the dropdown
   * @default left
   */
  textAlign?: React.CSSProperties['textAlign']
  /**
   * Whether or not the link should be disabled
   */
  disabled?: boolean
  /**
   * Variant for visual treatment
   * @default "default"
   */
  variant?: 'default' | 'destructive'
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'a'>>

export const Link = React.forwardRef<HTMLDivElement, LinkProps>(
  (props, ref) => {
    const {
      className,
      textAlign = 'left',
      disabled,
      onClick,
      isExternal,
      variant = 'default',
      ...restProps
    } = props

    const externalProps = { target: '_blank', rel: 'noopener noreferrer' }

    return (
      <DropdownMenu.Item
        ref={ref}
        disabled={disabled}
        className={cn(styles.link, styles[variant], {
          [styles.disabled]: disabled,
        })}
      >
        <a
          style={{ textAlign }}
          onClick={(e) => {
            if (disabled) return
            onClick?.(e)
          }}
          className={cn(
            disabled && 'pointer-events-none',
            styles.linkContent,
            className
          )}
          {...(isExternal && externalProps)}
          {...restProps}
        />
      </DropdownMenu.Item>
    )
  }
)

Link.displayName = 'DropdownLink'
