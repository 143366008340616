import { decamelize } from '@ridi/object-case-converter'
import {
  IAnyModelType,
  IAnyType,
  SnapshotIn,
  isModelType,
} from 'mobx-state-tree'
import {
  JSONAPIData,
  JSONAPIDoc,
  Relationship,
  RelationshipType,
} from '../types'
import { decodeTypes, getModelType } from './get-model-type'

export interface ISerializer<Model extends IAnyType> {
  call(attributes: SnapshotIn<Model>, id?: string): JSONAPIDoc | void
}

export class Serializer<
  Model extends IAnyType,
  Attributes extends Partial<SnapshotIn<Model>>
> implements ISerializer<Model>
{
  constructor(private readonly model: Model) {}

  private readonly modelDefinition: IAnyModelType = getModelType(this.model)

  call(attributes: Attributes, id?: string): JSONAPIDoc | void {
    if (!attributes) return

    const result: JSONAPIData = {
      type: this.modelDefinition.name,
      id,
      attributes: {},
    }

    this.serializeAttributes(result, attributes)
    return { data: decamelize(result, { recursive: true }) }
  }

  private serializeAttributes(result: JSONAPIData, attributes: Attributes) {
    for (const key in attributes) {
      if (key === 'id') continue
      const value = attributes[key]

      const relationshipType = this.relationshipType(key)
      if (relationshipType) {
        const data = this.serializeRelationship(relationshipType, value)
        result.relationships ||= {}
        result.relationships[key] = { data }
        continue
      }

      result.attributes[key] = value
    }
  }

  private relationshipType(key: string): string | undefined {
    const property = this.modelDefinition.properties[key]
    if (!property) return
    const [decodedType] = decodeTypes(property)

    if (!decodedType) return
    if (isModelType(decodedType)) return decodedType.name
  }

  private serializeRelationship(
    type: string,
    value: unknown
  ): RelationshipType | null {
    if (Array.isArray(value)) {
      return value.map(
        (item) => this.serializeRelationship(type, item) as Relationship
      )
    }
    if (typeof value !== 'string') return null

    const [id] = value.split('|')
    return { type, id }
  }
}
