import * as React from 'react'
import cn from 'classnames'
import { ColourVariantWithTheme } from '../../../types/colour-variant'
import styles from './Tag.module.scss'

export type TagProps = {
  /**
   * Children for the tag, this can be text or custom React
   * @example <Tag>My custom tag content!</Tag>
   */
  children: React.ReactNode
  /**
   * The variant for the tag, by default this will be based off the base theme colors
   * @example <Tag variant="success" />
   */
  variant?:
    | ColourVariantWithTheme
    | 'paper'
    | 'muted'
    | 'dark'
    | 'orange'
    | 'naked'
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

/*
 * Typically a block element, may be full width. Sticks around until refresh, or may be dismissible.
 */
export const Tag = React.forwardRef<HTMLDivElement, TagProps>((props, ref) => {
  const { children, variant = 'theme', className, ...restProps } = props

  return (
    <div
      ref={ref}
      className={cn(styles.tag, styles[variant], className)}
      {...restProps}
    >
      {children}
    </div>
  )
})

Tag.displayName = 'Tag'
