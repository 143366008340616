import { TUser } from '../types/entities'
import { getInitials } from '../utils/get-initials'

export class User {
  public id: number
  public inactiveText: string
  public name: string
  public data: TUser

  constructor(user: TUser) {
    this.id = Number(user.id)
    this.inactiveText = user.state === 'active' ? '' : ' (not invited yet)'
    this.name = `${user.fname} ${user.lname}`
    this.data = user
  }

  get initials() {
    return getInitials(this.name)
  }
}
