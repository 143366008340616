import * as React from 'react'

export type TabbedContentContextProps = {
  activeTab: string | number
  setActiveTab: (activeTab: string | number) => void
}

export const TabbedContentContext = React.createContext(
  {} as TabbedContentContextProps
)
