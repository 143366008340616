import { observer } from 'mobx-react-lite'
import React, { useEffect, VFC, lazy, Suspense } from 'react'
import { useStore } from 'store/context'
import { RailsContext } from './rails-context'
import { RootProvider } from 'src/design-system'

const GlobalModals = lazy(() => import('./global-modals'))

type ContextProps = {
  children?: string
} & RailsContext

/*
 * A helper which allows us to pass text content from Rails views
 * and render as children of react components.
 *
 * Adds an optional `_children` attribute to the props of the component,
 * which it then removes and renders as the child of the component.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forRails = <P extends Record<string, any>>(Component: VFC<P>) => {
  const RailsRoot: React.ComponentType<P & { _context: ContextProps }> = (
    rawProps
  ) => {
    const [serverSide, setServerSide] = React.useState(true)
    const { children: __html, ...context } = rawProps._context
    const props: P = Object.assign({}, rawProps)
    delete props._context
    const store = useStore()

    useEffect(() => {
      window.eva.replace()
      setServerSide(false)
    }, [])

    const inner = !__html ? (
      <Component {...props} />
    ) : (
      <Component {...props}>
        <div dangerouslySetInnerHTML={{ __html }} />
      </Component>
    )

    if (store.bootstrapping) return null
    return (
      <RootProvider>
        <RailsContext.Provider value={{ ...context, serverSide }}>
          {inner}
        </RailsContext.Provider>
        <Suspense fallback={null}>
          <GlobalModals />
        </Suspense>
      </RootProvider>
    )
  }
  return observer(RailsRoot)
}
