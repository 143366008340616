import * as React from 'react'

export type EyeProps = React.SVGProps<SVGSVGElement>

export const Overflow = (props: EyeProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="more-horizotnal">
        <rect width="24" height="24" opacity="0"></rect>
        <circle cx="12" cy="12" r="2"></circle>
        <circle cx="19" cy="12" r="2"></circle>
        <circle cx="5" cy="12" r="2"></circle>
      </g>
    </g>
  </svg>
)
