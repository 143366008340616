import { getInitials } from './get-initials'

/**
 * This will get the initials from a discipline name (ie Product Design = PD) and display it alongside the seniority level, for use in tags or empty position cards
 * @example const positionSeniorityKey = getPositionSeniorityKey('Product Design', 4) // PD4
 */
export const getPositionSeniorityKey = (
  disciplineName: string,
  seniorityLevel: number
): string => `${getInitials(disciplineName)}${seniorityLevel}`
