import * as React from 'react'
import cn from 'classnames'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import styles from './EntityCard.module.scss'

export type TitleProps = PropsWithRequiredChildren<
  React.ComponentPropsWithoutRef<'h4'>
>

export const Title: React.VFC<TitleProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <h4 className={cn(styles.title, className)} {...restProps}>
      {children}
    </h4>
  )
}
