import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import * as currencyCodes from '@dinero.js/currencies'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { Currency } from 'app/packs/src/models/currency'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'

export const Salary = baseModel('salaries')
  .props({
    currency: types.string,
    fromCents: types.maybeNull(types.number),
    location: types.maybeNull(types.string),
    position: reference(Position),
    toCents: types.maybeNull(types.number),
  })
  .views((self) => ({
    get from() {
      return new Currency(
        self.currency as keyof typeof currencyCodes,
        self.fromCents
      )
    },
    get to() {
      return new Currency(
        self.currency as keyof typeof currencyCodes,
        self.toCents
      )
    },
    get formatted() {
      if (!this.from?.cents && !this.to?.cents) return

      const formattedSalaryFrom = this.from.formatted()
      const formattedSalaryTo = this.to.formatted()

      const salaryStr =
        this.from.cents && this.to.cents
          ? `${formattedSalaryFrom} - ${formattedSalaryTo}`
          : formattedSalaryFrom || formattedSalaryTo

      return `${salaryStr}`
    },
  }))

export type ModifiableSalaryAttributes = {
  currency: string
  fromCents?: number | null
  toCents?: number | null
  location?: string | null
  position: string
}

type SalaryStoreOptions = {
  CreateAttributes: ModifiableSalaryAttributes
  UpdateAttributes: Partial<ModifiableSalaryAttributes>
}

export interface Salary extends Instance<typeof Salary> {}
export type SalaryModelAttributes = SnapshotIn<typeof Salary>

export const SalaryStore = createStore<typeof Salary, SalaryStoreOptions>(
  'Salary',
  Salary
)
