export type SortObject = {
  id: string
  direction: 'asc' | 'desc'
}

export type TSort = SortObject | null

export const isActiveSort = (
  activeSort: TSort,
  id: string
): activeSort is SortObject => activeSort?.id === id

export const getNextSort = (
  activeSort: TSort,
  id: string,
  allowDefaultSort?: false
): TSort => {
  if (isActiveSort(activeSort, id)) {
    if (allowDefaultSort === false) {
      return {
        id,
        direction: activeSort.direction === 'asc' ? 'desc' : 'asc',
      }
    } else {
      return activeSort.direction === 'asc' ? { id, direction: 'desc' } : null
    }
  } else {
    return { id, direction: 'asc' }
  }
}
