import * as React from 'react'
import type { PlanType, PricingFrequency } from './utils'

export type PricingContextProps = {
  /**
   * Pricing frequency to show in the plan details. Either `monthly` or `annually`
   */
  frequency: PricingFrequency
  /**
   * Function to toggle between annual/monthly pricing
   */
  toggleFrequency: () => void
  /**
   * The plan the user is currently on, this can be `free`, `freeAlreadyUsedTrial`, `business` or `organisation` - this is used to determine which CTA's appear for upgrading/downgrading etc.
   */
  currentPlan: PlanType
}

export const PricingContext = React.createContext({} as PricingContextProps)
