import * as React from 'react'
import type { TCategory } from '../../../../types/entities'
import { EditableTitle } from '../../EditableTitle'
import styles from './CategoryHeader.module.scss'
import { Lock, LockOpen } from '@phosphor-icons/react'
import { Tooltip } from '../../../atoms/Tooltip'
import { EventHandlers, Permissions } from './SkillsGrid.types'
import { AddSkillsIconButton } from './AddSkillsIconButton'

export type CategoryHeaderProps = {
  category: TCategory | null
  showAddSkills?: boolean
} & Pick<
  EventHandlers,
  'onEditCategory' | 'onDeleteCategory' | 'onToggleLockCategory'
> &
  Pick<Permissions, 'allowEdit' | 'allowLockingCategories'>

export const CategoryHeader = (props: CategoryHeaderProps) => {
  const {
    category,
    showAddSkills = false,
    allowEdit,
    allowLockingCategories,
    onDeleteCategory,
    onEditCategory,
    onToggleLockCategory,
  } = props

  const isUncategorised = !category

  // This prevents categories from being edited if they are locked and the user doesn't have permission to allow locking categories (ie they're viewing a team not a template)
  const allowEditTitle = Boolean(
    allowEdit && (!category?.locked || allowLockingCategories)
  )

  return (
    <header className={styles.header}>
      {isUncategorised ? (
        <div className="flex p-1">
          <h2 className={styles.title}>Uncategorised</h2>
        </div>
      ) : (
        <div className="flex items-start justify-between">
          <EditableTitle
            title={category.name}
            altTitle={`Edit category name: ${category.name}`}
            headingLevel={2}
            allowEdit={allowEditTitle}
            locked={category?.locked && allowEdit && !allowLockingCategories}
            lockedTooltipContent={`Locked`}
            onEdit={(newTitle, newDescription) =>
              onEditCategory?.(category, newTitle, newDescription)
            }
            deleteButtonProps={{
              onDelete: () => onDeleteCategory?.(category),
              entityName: 'category',
              warningMessage: `Warning: This will move any skills in the ${category.name} category to uncategorised`,
            }}
            showDescription={category.name !== 'Uncategorised'}
            description={category.description ?? undefined}
            descriptionPlaceholder="Enter a description for this category"
            titleClassName={styles.title}
            inputClassName={styles.title}
            descriptionClassName={styles.description}
            descriptionTextareaClassName={styles.descriptionTextarea}
          />
          <div className="flex items-center gap-1">
            {showAddSkills && <AddSkillsIconButton category={category} />}
            {allowLockingCategories && (
              <div className="">
                <Tooltip
                  className="cursor-pointer"
                  content={
                    category.locked
                      ? `Locked: can’t be edited by non-admins`
                      : `Unlocked: can be edited by non admins`
                  }
                >
                  <div className="p-1 mb-0">
                    <button onClick={() => onToggleLockCategory?.(category)}>
                      {category.locked ? (
                        <Lock
                          weight="bold"
                          className="fill-red-700 opacity-50"
                        />
                      ) : (
                        <LockOpen weight="bold" className="fill-gray-300" />
                      )}
                    </button>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  )
}
