import * as React from 'react'
import cn from 'classnames'
import styles from './Spacer.module.scss'
import { DivPropsWithoutChildren } from '../../../types/helpers'

export type SpacerProps = {
  width?: string
  height?: string
} & DivPropsWithoutChildren

export const Spacer: React.VFC<SpacerProps> = (props) => {
  const { width = '31px', height, className, ...restProps } = props

  return (
    <div
      aria-hidden
      style={{ width, height }}
      className={cn(styles.spacer, className)}
      {...restProps}
    />
  )
}
