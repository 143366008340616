import * as React from 'react'

export type Mode = 'visual' | 'insert'

export type TEditableFormContext = {
  /**
   * Current mode of the form, when in visual mode, content inside <EditableForm.VisualContent> will be shown, and when in insert mode, content inside <EditableForm.InsertContent> will be shown.
   */
  mode: Mode
  /**
   * Internal setter for the mode, this will be called when cancelling out of insert mode, or when clicking the trigger
   */
  setMode: (mode: Mode) => void
  /**
   * Function for resetting:
   * - Mode back to initial mode
   * - Form data back to initial form data
   * - Error message to an empty string
   */
  reset: () => void
  /**
   * An error message for the editable title, this is used internally as part of the ErrorMessage component
   */
  errorMessage: string
  /**
   * Internal setter for the error message, this will be called when calling onSubmit based on success/failure
   */
  setErrorMessage: (errorMessage: string) => void
  /**
   * When this is set, the interactivity aspect of the form will be disabled and only visual content will be shown. This is used so users without permission won't see any edit content
   */
  disabled: boolean
}
// we typecast this here since we know at the time of usage the value will already be filled out,
// but if we used this type as the generic we'd have to do null checks in every component.
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/#extended-example
export const EditableFormContext = React.createContext(
  {} as TEditableFormContext
)
