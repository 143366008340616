import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { CheckinSkill } from '../checkin-skills'
import { createStore } from 'store/utils/create-store'
import { DateType } from 'store/utils/date-type'
import { getRootStore } from 'store/utils/get-root-store'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'
import { User } from '../users'

export const Checkin = baseModel('checkins')
  .props({
    assessor: reference(User),
    assessorApprovedAt: types.maybeNull(DateType),
    author: reference(User, { required: true }),
    createdAt: DateType,
    description: types.maybeNull(types.string),
    finalisedAt: types.maybeNull(DateType),
    completedAt: types.maybeNull(DateType),
    notes: types.maybeNull(types.string),
    position: reference(Position),
    positionName: types.maybeNull(types.string),
    slug: types.string,
    status: types.union(
      types.literal('not_started'),
      types.literal('in_progress'),
      types.literal('pending_assessor'),
      types.literal('assessor_in_progress'),
      types.literal('pending_review'),
      types.literal('review_in_progress'),
      types.literal('finalised')
    ),
    summaryFinalLevelTotal: types.maybeNull(types.number),
    summaryPercentageDifference: types.maybeNull(types.number),
    summaryRequiredLevelTotal: types.maybeNull(types.number),
    summaryStatus: types.maybeNull(
      types.union(
        types.literal('exceeding'),
        types.literal('meeting'),
        types.literal('working_towards')
      )
    ),
    title: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setFinalisedAt(finalisedAt: Date) {
      self.finalisedAt = finalisedAt
    },
  }))
  .views((self) => ({
    get checkinSkills(): CheckinSkill[] {
      return getRootStore(self).checkinSkills.filtered(
        (checkinSkill) => checkinSkill.checkin?.id === self.id
      )
    },
    get isFinalised() {
      return self.finalisedAt !== null
    },
    get isLastFinalisedForAuthor(): boolean {
      const lastFinalised = getRootStore(
        self
      ).checkins.lastFinalisedUserCheckin(self.author.id)

      return lastFinalised?.id === self.id
    },
    get path() {
      return `/checkins/${self.slug}`
    },
    get selectedCheckinSkills(): CheckinSkill[] {
      return this.checkinSkills.filter(
        (checkinSkill) => !checkinSkill.initiallySkipped
      )
    },
    get skills(): Skill[] {
      return this.selectedCheckinSkills.map(
        (checkinSkill) => checkinSkill.skill
      )
    },
    get userIds() {
      return this.users.map((user) => user.id)
    },
    get users() {
      const users = [self.author]
      if (self.assessor) users.push(self.assessor)

      return users
    },
    actionNeededFromUser(userId: string) {
      if (self.status === 'in_progress' || self.status === 'not_started') {
        return self.author.id === userId
      }
      if (
        self.status === 'assessor_in_progress' ||
        self.status === 'pending_assessor'
      ) {
        return !!(self.assessor && self.assessor.id === userId)
      }
      return false
    },
    titleWithFallback(userId: string) {
      return (
        self.title ||
        `${
          userId === self.author.id ? 'Your' : `${self.author.fname}'s`
        } Check-in${self.positionName ? ` against ${self.positionName}` : ''}`
      )
    },
  }))

export type CheckinModelAttributes = SnapshotIn<typeof Checkin>

export interface Checkin extends Instance<typeof Checkin> {}

type CheckinCreateAttributes = {
  assessor: string
  author: string
  description?: string | null
  initiated_by: 'assessor' | 'self'
  position?: string | null
  skill_ids?: string[]
  title?: string | null
}

type CheckinUpdateAttributes = {
  assessor?: string
  notes?: string
}

export type CheckinFilters = {
  assessor_or_author_id?: string | string[]
  author_id?: string | string[]
  author_state?: string | string[]
  created_at_from?: string
  created_at_to?: string
  last_finalised?: true
  org_id?: string | string[]
  status?: string | string[]
  updated_since?: Date | null
}

type CheckinIncludes =
  | 'assessor'
  | 'author'
  | 'checkin_skills'
  | 'checkin_skills.skill'

type CheckinStoreOptions = {
  CreateAttributes: CheckinCreateAttributes
  Filter: CheckinFilters
  Include: CheckinIncludes[]
  UpdateAttributes: CheckinUpdateAttributes
}

export const CheckinStore = createStore<typeof Checkin, CheckinStoreOptions>(
  'Checkin',
  Checkin
)
  .actions((store) => ({
    fetchLastFinalisedUserCheckin(userId: string) {
      return store.fetchAll({
        filter: {
          author_id: userId,
          status: ['finalised'],
        },
        include: [
          'assessor',
          'author',
          'checkin_skills',
          'checkin_skills.skill',
        ],
        page: { size: 1 },
      })
    },
  }))
  .views((store) => ({
    get finalised() {
      return store.filtered((checkin) => checkin.isFinalised)
    },
    get forNonArchivedUsers() {
      return store.filtered((checkin) => checkin.author.isActive)
    },
    nonFinalisedSortedByUpdatedAtDescWithLimitForUser(
      limit: number,
      userId: string
    ) {
      return store
        .filtered(
          (checkin) =>
            checkin.status !== 'finalised' && checkin.userIds.includes(userId)
        )
        .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
        .slice(0, limit)
    },
    finalisedForAuthor(userId: string) {
      return store.filtered(
        (checkin) => checkin.author.id === userId && checkin.isFinalised
      )
    },
    finalisedSortedByFinalisedAtDescForAuthor(userId: string) {
      return this.finalisedForAuthor(userId).sort((a, b) => {
        if (a.finalisedAt === null || b.finalisedAt === null) return -1

        return a.finalisedAt > b.finalisedAt ? -1 : 1
      })
    },
    forAuthor(userId: string) {
      return store.filtered((checkin) => checkin.author.id === userId)
    },
    forAssessor(userId: string) {
      return store.filtered(
        (checkin) =>
          checkin.assessor?.id === userId && checkin.author.id !== userId
      )
    },
    forAuthors(userIds: string[]) {
      return store.filtered((checkin) => userIds.includes(checkin.author.id))
    },
    forAuthorAndPosition(userId: string, positionId: string) {
      return store.filtered(
        (checkin) =>
          checkin.author.id === userId && checkin.position?.id === positionId
      )
    },
    forOrg(orgId: string) {
      return store.filtered((checkin) => checkin.author.org?.id === orgId)
    },
    lastFinalisedUserCheckin(userId: string) {
      const filtered = this.finalisedSortedByFinalisedAtDescForAuthor(userId)

      if (filtered.length > 0) return filtered[0]
    },
    previousFinalisedUserCheckin(checkin: Checkin) {
      if (!checkin.finalisedAt) return null
      const filtered = this.finalisedSortedByFinalisedAtDescForAuthor(
        checkin.author.id
      ).filter(
        (c) =>
          c.finalisedAt &&
          checkin.finalisedAt &&
          c.finalisedAt < checkin.finalisedAt
      )

      if (filtered.length > 0) return filtered[0]
    },
    lastUserCheckin(userId: string) {
      return store
        .filtered((checkin) => checkin.author.id === userId)
        .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))[0]
    },
    notFinalisedForAuthor(userId: string) {
      return store.filtered(
        (checkin) => !checkin.isFinalised && checkin.author.id === userId
      )
    },
    notFinalisedForAuthorAndPosition(userId: string, positionId: string) {
      return store.filtered(
        (checkin) =>
          !checkin.isFinalised &&
          checkin.author.id === userId &&
          checkin.position?.id === positionId
      )
    },
    actionNeededFromUser(userId: string) {
      return store.filtered((checkin) => checkin.actionNeededFromUser(userId))
    },
  }))
