import { isDate, parseISO } from 'date-fns'
import { types } from 'mobx-state-tree'

// Custom MobX type which returns an actual Date object
export const DateType = types.custom<string, Date>({
  name: 'Date',
  fromSnapshot(value: string) {
    return parseISO(value)
  },
  toSnapshot(value: Date | string) {
    if (typeof value === 'string') return value
    return value?.toISOString()
  },
  isTargetType(value: unknown): value is Date {
    return isDate(value)
  },
  getValidationMessage(value: unknown) {
    if (isDate(value)) return ''
    if (typeof value === 'string' && !!parseISO(value)) return ''
    return 'Invalid date'
  },
})
