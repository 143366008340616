import { SchemaVersions } from 'app/packs/src/api/fetch-schema-versions'
import { applySnapshot, SnapshotIn, types } from 'mobx-state-tree'

export const MetaModel = types.model('_meta', {
  id: types.identifier,
  schemaVersion: types.string,
})

export const MetaStore = types
  .model('MetaStore', {
    data: types.map(MetaModel),
  })
  .actions((self) => ({
    async load(versions: SchemaVersions) {
      const data = Object.entries(versions).reduce(
        (acc, [id, schemaVersion]) => ({ ...acc, [id]: { id, schemaVersion } }),
        {} as Record<string, SnapshotIn<typeof MetaModel>>
      )
      self.data.merge(data)
    },
    clear() {
      applySnapshot(self, {})
    },
  }))

export interface MetaModel extends SnapshotIn<typeof MetaModel> {}
