import { createStore } from 'store/utils/create-store'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'

export const Reflection = baseModel('reflections').props({
  content: types.maybeNull(types.string),
})

export type ReflectionCreateAttributes = {
  content?: string
}

type ReflectionStoreOptions = {
  CreateAttributes: ReflectionCreateAttributes
}

export interface Reflection extends Instance<typeof Reflection> {}
export type ReflectionModelAttributes = SnapshotIn<typeof Reflection>

export const ReflectionStore = createStore<
  typeof Reflection,
  ReflectionStoreOptions
>('Reflection', Reflection)
