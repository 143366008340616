import * as React from 'react'
import { useDrawerContext } from '../../../../contexts/drawer-context'
import { SKILLS_DRAWER_ID } from '../SkillsDrawer/utils'
import { TCategory } from '../../../../types/entities'
import { Button } from '../../../atoms/Button'
import { Plus } from '@phosphor-icons/react'

export type AddSkillsIconButtonProps = {
  category: TCategory | null
}

export const AddSkillsIconButton: React.VFC<AddSkillsIconButtonProps> = (
  props
) => {
  const { category } = props

  const drawerContext = useDrawerContext()

  const onClick = () => {
    drawerContext?.openDrawer(SKILLS_DRAWER_ID, { category })
  }

  return (
    <div className="">
      <button onClick={onClick}>
        <Plus weight="bold" size={16} className="fill-green-500" />
      </button>
    </div>
  )
}
