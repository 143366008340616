import {
  getSnapshot,
  IAnyModelType,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { DateType } from 'store/utils/date-type'
import { Doc } from '../docs'
import { Framework } from '../frameworks'
import { getRootStore } from 'store/utils/get-root-store'
import { Org } from '../orgs'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { User } from '../users'

export const Team = baseModel('teams')
  .props({
    org: reference(Org, { required: true }),
    discardedAt: types.maybeNull(DateType),
    lockedAt: types.maybeNull(DateType),
    docs: types.array(reference(Doc, { required: true })),
    editors: types.array(
      reference(
        types.late((): IAnyModelType => User),
        { required: true }
      )
    ),
    memberships: types.array(reference(types.late((): IAnyModelType => User))),
    framework: reference(Framework),
    name: types.string,
    openPositions: types.array(
      reference(
        types.late(() => Position),
        { required: true }
      )
    ),
    showPositionLabels: types.maybeNull(types.boolean),
    slug: types.string,
    state: types.union(
      types.literal('in_progress'),
      types.literal('in_review'),
      types.literal('published'),
      types.literal('publicly_accessible')
    ),
    users: types.array(
      reference(
        types.late((): IAnyModelType => User),
        { required: true }
      )
    ),
    template: types.maybeNull(types.boolean),
    clonedFrom: reference(types.late((): IAnyModelType => Team)),
    clonedFromTemplate: reference(types.late((): IAnyModelType => Team)),
    teamType: types.string,
  })
  .views((self) => ({
    get isDraft(): boolean {
      return self.state === 'in_progress' || self.state === 'in_review'
    },
    get isTemplate(): boolean {
      return self.template === true
    },
    get isLocked(): boolean {
      return !!self.lockedAt
    },
    get baseUrl() {
      return `/teams/${self.slug}`
    },
    get frameworkPath() {
      return `${this.baseUrl}/framework`
    },
    get settingsPath() {
      return `${this.baseUrl}/edit`
    },
    get teamMembersPath() {
      return `${this.baseUrl}/members`
    },
    get readme(): Doc | undefined {
      return self.docs.find((doc) => doc.isReadme)
    },
    hasEditor(userId: string | undefined): boolean {
      return self.editors.some((user) => user.id === userId)
    },
    get friendlyState() {
      switch (self.state) {
        case 'in_progress':
          return 'Draft'
        case 'in_review':
          return 'In Review'
        case 'published':
          return 'Live'
        case 'publicly_accessible':
          return 'Live (Public)'
      }
    },
  }))

export interface Team extends Instance<typeof Team> {}
export type TeamModelAttributes = SnapshotIn<typeof Team>

type TeamIncludes =
  | 'disciplines'
  | 'docs'
  | 'editors'
  | 'framework'
  | 'framework.disciplines'
  | 'framework.positions'
  | 'framework.frameworks_skills'
  | 'framework.frameworks_skills.skill'
  | 'open_positions'
  | 'users'
  | 'users.position'
  | 'memberships'
  | 'cloned_from_template'

type TeamFilters = {
  updated_since?: Date | null
}

type TeamCreateAttributes = {
  name: string
  clonedFrom?: string
  editors?: string[]
}

type TeamUpdateAttributes = {
  discardedAt?: null | Date
  editors?: string[]
  name?: string
  users?: string[]
}

type TeamStoreOptions = {
  Include: TeamIncludes[]
  Filters: TeamFilters
  CreateAttributes: TeamCreateAttributes
  UpdateAttributes: TeamUpdateAttributes
}

export const TeamStore = createStore<typeof Team, TeamStoreOptions>(
  'Team',
  Team
)
  .views((store) => ({
    get viewableForCurrentUser(): Team[] {
      const currentUser = getRootStore(store).currentUser
      if (!currentUser) return []

      return currentUser.isAdmin
        ? this.forOrg
        : store.all.filter((team) => {
            return (
              team.org === currentUser.org &&
              !team.isTemplate &&
              (!team.isDraft || team.hasEditor(currentUser.id))
            )
          })
    },
    get editableForCurrentUser(): Team[] {
      const currentUser = getRootStore(store).currentUser
      if (!currentUser) return []

      return currentUser.isAdmin
        ? this.forOrg
        : store.all.filter((team) => {
            return (
              team.org === currentUser.org && team.hasEditor(currentUser.id)
            )
          })
    },
    get forOrg(): Team[] {
      const currentUser = getRootStore(store).currentUser

      return currentUser
        ? store.all.filter((team) => team.org === currentUser.org)
        : []
    },
    get templates(): Team[] {
      return store
        .filtered((item) => !!item.template, {
          useDefaultFilter: false,
        })
        .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
    },
    get hasPublishedFrameworks() {
      return store.all.some((team) => !team.isDraft)
    },
    get sortedForCurrentUser(): Team[] {
      const currentUser = getRootStore(store).currentUser

      return currentUser
        ? this.forOrg.sort((team) =>
            team.id === currentUser.team?.id ? -1 : 1
          )
        : []
    },
    hasUsersInPositions(opts?: { includeCurrentUser?: boolean }): boolean {
      const { includeCurrentUser = false } = opts || {}
      const root = getRootStore(store)
      return store.all.some((team) => {
        let filteredUsers: User[] = team.users
        if (!includeCurrentUser) {
          filteredUsers = filteredUsers.filter(
            (u) => u.id !== root.currentUser?.id
          )
        }

        return filteredUsers.some((u) => !!getSnapshot(u).position)
      })
    },
    hasEditors(opts?: { includeCurrentUser: boolean }): boolean {
      const { includeCurrentUser = false } = opts || {}
      const root = getRootStore(store)
      return store.all.some((team) => {
        let editors: User[] = team.editors
        if (!includeCurrentUser) {
          editors = editors.filter(
            (editor) => editor.id !== root.currentUser?.id
          )
        }
        return editors.length > 0
      })
    },
  }))
  .actions((self) => ({
    async restore(id: string) {
      await self.update(id, { discardedAt: null })
    },
  }))
