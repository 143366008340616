import {
  BriefcaseOutline,
  CheckmarkCircle2Outline,
  PlusCircleOutline,
} from '@easy-eva-icons/react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import * as React from 'react'
import cn from 'classnames'
import { CardProps } from '../../../atoms/Card'
import { MappedTags } from '../../../molecules/Tags/MappedTags'
import { pluralize } from '../../../../utils/string-manipulation'
import { Tag } from '../../../atoms/Tag'
import { TSkillVariantWithOrg, TSkillWithOrg } from '../../../../types/entities'
import * as ConfirmationDialog from '../../../molecules/ConfirmationDialog'
import * as EntityCard from '../../EntityCard'
import styles from './SkillVariantCard.module.scss'
import type { PropsWithoutChildren } from '../../../../types/helpers'
import type { SkillType } from '../skills'
import { MinusCircle } from '@phosphor-icons/react'
import { IconButton } from '../../../atoms/IconButton'

export type SkillVariantCardProps = {
  skill: TSkillWithOrg
  skillVariant: TSkillVariantWithOrg
  onAddSkillVariant?(skillVariant: TSkillVariantWithOrg): void
  onRemoveSkillVariantFromTeam?(skill: TSkillVariantWithOrg): void
  allowEdit?: boolean
  isDragOverlay?: boolean
  disableAnimation?: boolean
  skillType?: SkillType
  inCurrentFramework?: boolean
} & PropsWithoutChildren<CardProps>

export const SkillVariantCard = React.forwardRef<
  HTMLAnchorElement,
  SkillVariantCardProps
>((props, ref) => {
  const {
    skill,
    skillVariant,
    className,
    onClick,
    onAddSkillVariant,
    onRemoveSkillVariantFromTeam,
    allowEdit,
    skillType = '',
    isDragOverlay = false,
    disableAnimation = false,
    inCurrentFramework,
    ...restProps
  } = props

  const canAddSkillVariant =
    onAddSkillVariant &&
    !inCurrentFramework &&
    ['library', 'org'].includes(skillType)

  const canRemoveSkillVariantFromTeam =
    allowEdit && onRemoveSkillVariantFromTeam && skillType === 'team'

  const skillVariantAlreadyAdded =
    onAddSkillVariant &&
    inCurrentFramework &&
    ['library', 'org'].includes(skillType)

  const teamText = `Used in ${pluralize(
    skill.frameworkCount || 0,
    'team',
    'teams'
  )}`

  const positionCount = skill.positionCount || 0
  return (
    <motion.div layout={disableAnimation ? false : 'position'}>
      <EntityCard.Root
        {...restProps}
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          onClick?.(e)
        }}
        showHover={!!onClick && !skillVariantAlreadyAdded}
        href={onClick ? '#' : undefined}
        aria-label={skill.name}
        className={cn(
          styles.card,
          isDragOverlay && styles.skillOverlay,
          skillVariantAlreadyAdded && styles.skillAlreadyAdded,
          className
        )}
        {...restProps}
      >
        <div className={styles.imageContentWrapper}>
          {skill.imageUrl && (
            <div className={styles.imageContainer}>
              <LazyLoadImage className={styles.image} src={skill.imageUrl} />
            </div>
          )}
          <div className={styles.contentWrapper}>
            <EntityCard.Header className={styles.header}>
              <EntityCard.Title>{skillVariant.nameWithSkill}</EntityCard.Title>
              {skillVariantAlreadyAdded && (
                <span title="Skill already added">
                  <CheckmarkCircle2Outline className={styles.icon} />
                </span>
              )}
              {canAddSkillVariant && (
                <button
                  className={styles.addableSkill}
                  onClick={(e) => {
                    e.stopPropagation()
                    onAddSkillVariant?.(skillVariant)
                  }}
                  title="Add skill"
                >
                  <PlusCircleOutline aria-hidden className={styles.icon} />
                </button>
              )}
              {canRemoveSkillVariantFromTeam && (
                <ConfirmationDialog.Root
                  body={`Removing this skill from the team will remove requirements from ${pluralize(
                    positionCount,
                    'position',
                    'positions'
                  )}. The skill will still be available in your org skills library.`}
                  onCancel={(e) => {
                    e.stopPropagation()
                  }}
                  onConfirm={(e) => {
                    e.stopPropagation()
                    onRemoveSkillVariantFromTeam?.(skillVariant)
                  }}
                  title="Are you sure you want to remove this skill from the team?"
                >
                  <ConfirmationDialog.Trigger asChild>
                    <IconButton
                      hideBorder
                      title={`Delete`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MinusCircle
                        role="button"
                        className={styles.deleteSkill}
                      />
                      <span className="sr-only">Remove from team</span>
                    </IconButton>
                  </ConfirmationDialog.Trigger>
                </ConfirmationDialog.Root>
              )}
            </EntityCard.Header>
            {skill.description && !onAddSkillVariant && (
              <EntityCard.Body className={styles.description}>
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: skill.description }}
                />
              </EntityCard.Body>
            )}
            <EntityCard.Footer className={styles.footer}>
              {skillType == 'library' && skill.org && (
                <div>
                  By <Tag variant="paper">{skill.org.name}</Tag>
                </div>
              )}
              {skillType == 'org' && (
                <span title={teamText}>
                  <BriefcaseOutline />
                  <Tag variant="paper">{skill.frameworkCount || 0}</Tag>
                </span>
              )}
              {onAddSkillVariant && skill.tags && skill.tags.length > 0 && (
                <EntityCard.Separator />
              )}
              {onAddSkillVariant &&
                skill.tags &&
                (skillType == 'org' ? (
                  <MappedTags tags={skill.tags} characterLimit={16} />
                ) : (
                  <MappedTags
                    tags={skill.tags}
                    characterLimit={skill.org ? 16 - skill.org.name.length : 25}
                  />
                ))}
              {!onAddSkillVariant && (
                <span>
                  Used in <span className="">{positionCount}</span>{' '}
                  {positionCount === 1 ? 'position' : 'positions'}
                </span>
              )}
            </EntityCard.Footer>
          </div>
        </div>
      </EntityCard.Root>
    </motion.div>
  )
})

SkillVariantCard.displayName = 'SkillVariantCard'
