import * as React from 'react'
import { TCollection } from '../../../../types/entities'
import { CardProps } from '../../../atoms/Card'
import styles from './CollectionCard.module.scss'
import cn from 'classnames'
import { useDraggable } from '@dnd-kit/core'
import { idWithPrefix } from '../SkillsGrid/drag-and-drop'
import { CopyOutline } from '@easy-eva-icons/react'
import { Button } from '../../../atoms/Button'
import { SkillImage } from '../SkillImage'

export type CollectionCardProps = {
  source?: 'library' | 'org' | 'all'
  collection: TCollection
  onClick?(collection: TCollection): void
  onAddCollection?(collection: TCollection): void
  draggable?: boolean
  isDragOverlay?: boolean
} & Omit<CardProps, 'onClick' | 'children'>

export const CollectionCard = (props: CollectionCardProps) => {
  const { draggable = false, isDragOverlay = false, ...restProps } = props

  if (draggable && !isDragOverlay)
    return <DraggableCollectionCard {...restProps} />

  return <Card isDragOverlay={isDragOverlay} {...restProps} />
}

// TODO: move in to draggable file
const DraggableCollectionCard = (props: CollectionCardProps) => {
  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: idWithPrefix(props.collection.id.toString(), 'collection'),
    data: { type: 'collection', collection: props.collection },
  })

  return (
    <div className={styles.cardWrapper}>
      {isDragging && (
        <div className={styles.collectionPlaceholder}>
          <CopyOutline className={styles.icon} />
        </div>
      )}
      <Card
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className={cn(isDragging && styles.draggedCollection)}
        {...props}
      />
    </div>
  )
}

const Card = React.forwardRef<HTMLAnchorElement, CollectionCardProps>(
  (props, ref) => {
    const {
      source,
      collection,
      className,
      onAddCollection,
      onClick,
      isDragOverlay,
      ...restProps
    } = props

    return (
      <a
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          onClick?.(collection)
        }}
        aria-label={collection.name}
        className={cn(
          styles.root,
          isDragOverlay && styles.collectionOverlay,
          className
        )}
        {...restProps}
      >
        {collection.imageUrl && (
          <div className={styles.imageContainer}>
            <SkillImage
              avatarUrl={collection.authorAvatarUrl}
              fallbackAvatarText={collection.authorName}
              imageUrl={collection.imageUrl}
            />
          </div>
        )}
        <div className={styles.contentWrapper}>
          <h3 title={collection.name} className={styles.title}>
            {collection.name}
          </h3>
          <p className="text-gray-500 mb-0">
            Collection of {collection.skillCount}{' '}
            {collection.skillCount === 1 ? 'skill' : 'skills'}
          </p>
        </div>
        {onAddCollection && (
          <Button
            className={styles.addButton}
            size="sm"
            variant={source === 'library' ? 'outline' : 'default'}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              onAddCollection?.(collection)
            }}
          >
            {source === 'library' ? 'Add to org' : 'Add'}
          </Button>
        )}
      </a>
    )
  }
)

Card.displayName = 'CollectionCard'
