import {
  applySnapshot,
  castToSnapshot,
  IAnyModelType,
  Instance,
  SnapshotOrInstance,
  types,
} from 'mobx-state-tree'
import { ActionStore } from './actions'
import { ActivityStore } from './activities'
import { AsyncTaskStore } from './async-tasks'
import { CategoryStore } from './categories'
import { ChangeStore } from './changes'
import { CheckinSkillStore } from './checkin-skills'
import { CheckinStore } from './checkins'
import { CollectionStore } from './collections'
import { CommentStore } from './comments'
import { ContentLicenseGrantStore } from './content-license-grants'
import { DisciplineStore } from './disciplines'
import { DocStore } from './docs'
import { ExternalActivityStore } from './external-activities'
import { FeatureFlagStore } from './feature-flags'
import { FeatureGateStore } from './feature-gates'
import { FeedbackItemStore } from './feedback-item'
import { FeedbackRequestStore } from './feedback-request'
import { fetchSchemaVersions } from '../../api/fetch-schema-versions'
import { FrameworksSkillStore } from './frameworks-skills'
import { FrameworkStore } from './frameworks'
import { MetaStore } from './_meta'
import { NotificationStore } from './notifications'
import { OnboardingSurveyStore } from './onboarding-survey'
import { OrgLimitsStore } from './org-limits'
import { OrgStore } from './orgs'
import { OutcomeStore } from './outcomes'
import { PositionChangeStore } from './position-changes'
import { PositionSkillStore } from './position-skills'
import { PositionStore } from './positions'
import { ReactionStore } from './reactions'
import { reference } from 'store/utils/reference'
import { RequirementStore } from './requirements'
import { ReflectionStore } from './reflections'
import { SalaryStore } from './salaries'
import { SkillLevelStore } from './skill-levels'
import { SkillStore } from './skills'
import { SkillVariantStore } from './skill-variants'
import { StoryItemStore } from './story-items'
import { StorePersister } from 'store/persist'
import { StoryStore } from './stories'
import { TeamStore } from './teams'
import { User, UserStore } from './users'
import { UserSkillStore } from './user_skills'
import { WinCategoryStore } from './win_categories'
import { WinStore } from './wins'
import { MembershipStore } from './memberships'
import { OperationsStore } from './operations'
import { Bootstrapper } from 'store/utils/bootstrapper'

const rootStore = {
  _meta: types.optional(MetaStore, {}),
  actions: types.optional(ActionStore, {}),
  activities: types.optional(ActivityStore, {}),
  asyncTasks: types.optional(AsyncTaskStore, {}),
  categories: types.optional(CategoryStore, {}),
  changes: types.optional(ChangeStore, {}),
  checkins: types.optional(CheckinStore, {}),
  checkinSkills: types.optional(CheckinSkillStore, {}),
  collections: types.optional(CollectionStore, {}),
  comments: types.optional(CommentStore, {}),
  contentLicenseGrants: types.optional(ContentLicenseGrantStore, {}),
  disciplines: types.optional(DisciplineStore, {}),
  docs: types.optional(DocStore, {}),
  externalActivities: types.optional(ExternalActivityStore, {}),
  feedbackItems: types.optional(FeedbackItemStore, {}),
  feedbackRequests: types.optional(FeedbackRequestStore, {}),
  frameworks: types.optional(FrameworkStore, {}),
  frameworksSkills: types.optional(FrameworksSkillStore, {}),
  memberships: types.optional(MembershipStore, {}),
  notifications: types.optional(NotificationStore, {}),
  onboardingSurveys: types.optional(OnboardingSurveyStore, {}),
  orgs: types.optional(OrgStore, {}),
  outcomes: types.optional(OutcomeStore, {}),
  positionChanges: types.optional(PositionChangeStore, {}),
  positions: types.optional(PositionStore, {}),
  positionSkills: types.optional(PositionSkillStore, {}),
  reactions: types.optional(ReactionStore, {}),
  reflections: types.optional(ReflectionStore, {}),
  requirements: types.optional(RequirementStore, {}),
  salaries: types.optional(SalaryStore, {}),
  skillLevels: types.optional(SkillLevelStore, {}),
  skills: types.optional(SkillStore, {}),
  skillVariants: types.optional(SkillVariantStore, {}),
  stories: types.optional(StoryStore, {}),
  storyItems: types.optional(StoryItemStore, {}),
  teams: types.optional(TeamStore, {}),
  users: types.optional(UserStore, {}),
  userSkills: types.optional(UserSkillStore, {}),
  winCategories: types.optional(WinCategoryStore, {}),
  wins: types.optional(WinStore, {}),
} as const

export type StoreModule = keyof typeof rootStore
export const storeModules = Object.keys(rootStore) as StoreModule[]

const getPersistedModules = (flagEnabled: boolean): StoreModule[] =>
  flagEnabled
    ? [
        'disciplines',
        'docs',
        'frameworks',
        'orgs',
        'outcomes',
        'positions',
        'skillLevels',
        'skills',
        'teams',
        'users',
      ]
    : ['users']

// Model containing just state, for access by modules
export const RootState = types.model('Root', {
  ...rootStore,
  currentUser: reference(User),
  bootstrapping: types.optional(types.boolean, true),
  featureFlags: types.optional(FeatureFlagStore, {}),
  featureGates: types.optional(FeatureGateStore, {}),
  orgLimits: types.optional(OrgLimitsStore, {}),
  operations: types.optional(OperationsStore, {}),
})

export type RootState = typeof RootState

// Root state tree, including root level actions
const RootStateWithActions = RootState.views((store) => ({
  get debug() {
    if (process.env.CI) return false
    if (process.env.SKIP_STORE_DEBUG) return false
    if (['development', 'staging'].includes(process.env.NODE_ENV || ''))
      return true
    if (store.featureFlags.featureEnabled('store-debug')) return true
    return false
  },
  get nonNullCurrentUser() {
    if (!store.currentUser) throw new Error('Current user not present in store')
    return store.currentUser
  },
})).actions((store) => ({
  setCurrentUser(userId: string) {
    store.currentUser = castToSnapshot(userId)
  },
  // Bulk load in state for a module
  load(
    resource: StoreModule,
    state: Record<string, SnapshotOrInstance<IAnyModelType>>
  ) {
    store[resource].load(state)
  },
  completeBootstrapping() {
    store.bootstrapping = false
  },
  clear() {
    applySnapshot(store, {})
    Bootstrapper.reset()
    return StorePersister.destroyDatabase()
  },
  debugLog(...args: unknown[]) {
    if (!store.debug) return
    console.debug('[store]', ...args)
  },
}))

export const RootModel = RootStateWithActions.actions((store) => ({
  async bootstrap() {
    const persistedModules = getPersistedModules(
      store.featureFlags.featureEnabled('persist_store')
    )
    const persister = new StorePersister(store, persistedModules, store.debug)

    if (store.debug) console.time('bootstrapping')

    const [newSchemaVersions] = await Promise.all([
      fetchSchemaVersions(),
      persister.initialize(),
    ])

    await Promise.all(
      persistedModules.map((key) => {
        const storeModule = store[key]
        if (!('bootstrap' in storeModule)) return

        storeModule.completeRequest()
        return storeModule.bootstrap(newSchemaVersions)
      })
    )

    store._meta.load(newSchemaVersions)
    store.completeBootstrapping()
    if (store.debug) console.timeEnd('bootstrapping')
  },
}))

export type RootModel = Instance<typeof RootModel>
