import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from '../../../types/helpers'
import { useFramework } from '../../../hooks/use-framework'
import { TDiscipline } from '../../../types/entities'
import styles from './PositionsRow.module.scss'
import { PositionCard } from './PositionCard'
import * as Card from './Card'

type PositionsRowProps = {
  discipline: TDiscipline
} & DivPropsWithoutChildren

export const PositionsRow: React.VFC<PositionsRowProps> = (props) => {
  const { discipline, className, ...restProps } = props

  const [
    getMaxSkillLevel,
    getSortedPositionsByDiscipline,
    categories,
    eventHandlers,
    permissions,
    sticky,
  ] = useFramework((s) => [
    s.getMaxSkillLevel,
    s.getSortedPositionsByDiscipline,
    s.categories,
    s.eventHandlers,
    s.permissions,
    s.sticky,
  ])

  const sortedPositions = React.useMemo(
    () => getSortedPositionsByDiscipline(discipline),
    [discipline]
  )

  const maxSkillLevel = React.useMemo(
    () => getMaxSkillLevel(categories),
    [categories]
  )

  if (sortedPositions.length === 0) {
    return (
      <Card.Root
        showHover={permissions?.allowCreatePosition}
        onClick={() => {
          if (!permissions?.allowCreatePosition) return

          eventHandlers?.onAddPositionForEmptyDiscipline?.(
            discipline as TDiscipline
          )
        }}
        className={cn(
          styles.emptyPositionHeader,
          sticky && styles.collapsed,
          className
        )}
        {...restProps}
      >
        <h3>0 Positions</h3>
        {permissions?.allowCreatePosition && (
          <p className={styles.addPositionLink}>Add position</p>
        )}
      </Card.Root>
    )
  }

  return (
    <div
      className={cn(styles.positionsRow, className)}
      {...restProps}
      data-element-id="positions-row"
    >
      {sortedPositions?.map((position) => (
        <PositionCard
          allowAssignUserToPosition={permissions?.allowAssignUserToPosition}
          allowEditPosition={permissions?.allowEditPosition}
          discipline={discipline as TDiscipline}
          key={position.name}
          maxSkillLevel={maxSkillLevel}
          onAssignUserToPosition={eventHandlers?.onAssignUserToPosition}
          onClick={() =>
            eventHandlers?.positionClickHandler?.({ position, discipline })
          }
          onClickRadarChart={() => eventHandlers?.onClickRadarChart?.(position)}
          onClickTeamMember={eventHandlers?.onClickTeamMember}
          onEditPosition={eventHandlers?.onEditPosition}
          onViewPosition={eventHandlers?.onViewPosition}
          onDeletePosition={eventHandlers?.onDeletePosition}
          position={position}
          showLabels={permissions?.showLabels}
          showRadarChartTooltip={permissions?.showRadarChartTooltip}
        />
      ))}
    </div>
  )
}
