import * as React from 'react'
import type { PropsWithoutChildren } from '../../../types/helpers'
import {
  Mode,
  EditableFormContext,
  TEditableFormContext,
} from './EditableFormContext'
import * as InputGroup from '../../molecules/InputGroup'

export type RootProps = {
  /**
   * Initial mode for the input to start in. This is mainly useful for debugging, but can be used
   * to force the editable title to start in insert (input) mode
   * @example <EditableTitle.Root initialMode="insert" />
   */
  initialMode?: Mode
  /**
   * Form handler which is passed the current formData on submission. This can optionally return a string
   * which will be used as an error message
   */
  onSubmit?: () => Promise<string | void> | void
  /**
   * Function to be called either when clicking the cancel button, or when hitting escape
   */
  onReset?: () => void
  /**
   * This could be anything, but would usually be the child components like <EditableForm.Confirm />
   * If you pass a function through, this has access to the current values for the form. On first render
   * these will be empty if starting in visual mode as these are set in a useEffect hook on the input itself
   * so you'll need to manually provide a fallback when showing a placeholder
   */
  children:
    | React.ReactNode
    | ((ctx: { mode: Mode; reset: () => void }) => React.ReactNode)
  /**
   * When this is set, the interactivity aspect of the form will be disabled and only visual content will be shown. This is used so users without permission won't see any edit content
   */
  disabled?: boolean
} & Omit<
  PropsWithoutChildren<React.ComponentPropsWithoutRef<'form'>>,
  'onSubmit'
>

export const Root: React.VFC<RootProps> = (props) => {
  const {
    initialMode = 'visual',
    onSubmit,
    children,
    disabled = false,
    onReset,
    ...restProps
  } = props

  const [mode, setMode] = React.useState(initialMode)

  const [errorMessage, setErrorMessage] = React.useState('')

  const formRef = React.useRef<HTMLFormElement | null>(null)

  const reset = () => {
    setErrorMessage('')
    setMode('visual')
    onReset?.()
  }

  const save = React.useCallback(async () => {
    const error = await onSubmit?.()
    if (error) {
      setErrorMessage(error)
    } else {
      setErrorMessage('')
      setMode('visual')
    }
  }, [onSubmit])

  React.useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.code === 'Escape' && mode === 'insert') reset()
    }

    const handleClick = (e: FocusEvent) => {
      const clickedInsideForm = formRef.current?.contains(e.target as Node)

      if (clickedInsideForm) return setMode('insert')
      else if (mode === 'insert' && !clickedInsideForm) save()
    }

    window.addEventListener('keyup', handleEsc)
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('keyup', handleEsc)
      window.removeEventListener('click', handleClick)
    }
  }, [save, mode])

  const contextData: TEditableFormContext = {
    mode,
    setMode,
    reset,
    errorMessage,
    setErrorMessage,
    disabled,
  }

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    await save()
  }

  const content =
    children instanceof Function ? children({ mode, reset }) : children

  return (
    <EditableFormContext.Provider value={contextData}>
      <form onSubmit={submitHandler} ref={formRef} {...restProps}>
        <InputGroup.Root>{content}</InputGroup.Root>
      </form>
    </EditableFormContext.Provider>
  )
}
