import * as React from 'react'
import {
  ConfirmationDialogContext,
  ConfirmationDialogContextValue,
} from '../../../contexts/confirmation-dialog-context'

export const ConfirmationDialogContextProvider: React.VFC<{
  children: React.ReactNode
}> = (props) => {
  const { children } = props
  const [activeConfirmationDialogId, setActiveConfirmationDialogId] =
    React.useState<null | string>(null)
  const [activeConfirmationDialogProps, setActiveConfirmationDialogProps] =
    React.useState<Record<string, unknown>>({})

  const openConfirmationDialog: ConfirmationDialogContextValue['openConfirmationDialog'] =
    React.useCallback((id, props) => {
      setActiveConfirmationDialogId(id)
      if (props) setActiveConfirmationDialogProps({ [id]: props })
    }, [])

  const closeConfirmationDialog = React.useCallback(() => {
    setActiveConfirmationDialogId(null)
    setActiveConfirmationDialogProps({})
  }, [])

  const getConfirmationDialogProps = React.useCallback(
    function <T>(modalId: string): T {
      const props = activeConfirmationDialogProps[modalId]
      return props ? (props as T) : ({} as T)
    },
    [activeConfirmationDialogProps]
  )

  return (
    <ConfirmationDialogContext.Provider
      value={{
        activeConfirmationDialogId,
        openConfirmationDialog,
        closeConfirmationDialog,
        getConfirmationDialogProps,
      }}
    >
      {children}
    </ConfirmationDialogContext.Provider>
  )
}
