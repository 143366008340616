import { Trigger } from '@radix-ui/react-dropdown-menu'
import * as React from 'react'
import cn from 'classnames'
import { IconButton } from '../IconButton'
import { Overflow } from '../../../icons/Overflow'
import * as DropdownMenu from '../../molecules/Dropdown'
import styles from './OverflowMenu.module.scss'

type RootProps = {
  children: React.ReactNode
  className?: string
  contentClassName?: string
  testId?: string
  onOpenChange?: (open: boolean) => void
  open?: boolean
  /**
   * Try to right-align the dropdown menu
   * (the menu will avoid collision with viewport bounds in any alignment)
   * @default false
   */
  right?: boolean
}

const Root: React.VFC<RootProps> = (props) => {
  const {
    children,
    className,
    contentClassName,
    onOpenChange,
    open: externalOpen = false,
    right,
    testId = 'overflow-menu',
  } = props

  const [open, setOpen] = React.useState(externalOpen)

  React.useEffect(() => {
    setOpen(externalOpen)
  }, [externalOpen])

  return (
    <DropdownMenu.Root
      align={right ? 'right' : 'left'}
      modal={false}
      onOpenChange={onOpenChange}
      open={open}
    >
      <Trigger
        asChild
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <IconButton
          hideBorder
          className={cn(className, styles.trigger)}
          // TODO: remove this once we can interact with this component in tests
          data-testid={testId}
        >
          <p className="sr-only">More options</p>
          <Overflow />
        </IconButton>
      </Trigger>
      <DropdownMenu.Content
        className={contentClassName}
        // In certain situations focus outside can cause this menu
        // to close as soon as it opens
        onFocusOutside={(e) => e.preventDefault()}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

type ItemProps = {
  children: React.ReactNode
  /**
   * Variant for visual treatment
   * @default "default"
   */
  variant?: 'default' | 'destructive'
  disabled?: boolean
  /**
   * Hook called when user selects item
   */
  onClick?(e: Event): void
}

/**
 * @deprecated Use Link or Button instead
 */
const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ children, variant = 'default', disabled, onClick }, ref) => {
    return (
      <DropdownMenu.Item
        ref={ref}
        disabled={disabled}
        className={cn(
          styles.item,
          styles[variant],
          disabled && styles.disabled
        )}
        onClick={(e) => e.stopPropagation()}
        onSelect={(e) => onClick?.(e)}
      >
        {children}
      </DropdownMenu.Item>
    )
  }
)

Item.displayName = 'OverflowMenuItem'

export { Root, Item }

export type { RootProps, ItemProps }

export {
  SubMenu,
  SubMenuTrigger,
  Content,
  Link,
  Button,
} from '../../molecules/Dropdown'
