import { SkillVariant } from '../skill-variants'
import type { Change, ChangeResource, ChangeType } from '.'

interface ChangeStore {
  ownAndAssociatedForResource(resource: ChangeResource): Change[]
  forResourceByType(
    resourceId: string,
    resourceType: string,
    type: ChangeType
  ): Change[]
}

export const findForResource = (
  resource: ChangeResource,
  store: ChangeStore
) => {
  if (isSkillVariant(resource) && resource.default) {
    return findForDefaultSkillVariant(resource, store)
  } else {
    return store.ownAndAssociatedForResource(resource)
  }
}

const findForDefaultSkillVariant = (
  resource: SkillVariant,
  store: ChangeStore
) => {
  // Don't return the create change for the skill variant when it's the default
  const createSkillVariantChange = store.forResourceByType(
    resource.id,
    'SkillVariant',
    'create'
  )[0]

  const skillVariantChanges = store
    .ownAndAssociatedForResource(resource)
    .filter((change) => change.id !== createSkillVariantChange?.id)

  const skill = resource.skill
  if (!skill) return skillVariantChanges

  const skillChanges = store.ownAndAssociatedForResource(skill)
  return skillVariantChanges.concat(skillChanges)
}

const isSkillVariant = (resource: ChangeResource): resource is SkillVariant => {
  return resource._type.name === 'skill_variants'
}
