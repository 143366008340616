import * as React from 'react'
import cn from 'classnames'
import type { DivPropsWithoutChildren } from '../../../types/helpers'
import { EditableFormContext } from './EditableFormContext'
import styles from './EditableForm.module.scss'

export type VisualContentProps = {
  children: React.ReactNode
} & DivPropsWithoutChildren

export const VisualContent: React.VFC<VisualContentProps> = (props) => {
  const { children, className, ...restProps } = props

  const { mode, disabled } = React.useContext(EditableFormContext)

  // visual mode should always show when editable is disabled
  if (mode !== 'visual' && !disabled) return null

  return (
    <div className={cn(styles.visualContent, className)} {...restProps}>
      {children}
    </div>
  )
}
