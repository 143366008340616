import { IDBPDatabase, openDB } from 'idb'
import { StoreModule } from 'store/modules/root'
import { StoreSchema } from './index'

export class DatabaseInitializer {
  constructor(private version: number) {}

  async open(name: string, modules: StoreModule[]) {
    const db = await openDB<StoreSchema>(name, this.version, {
      upgrade: async (db) => {
        const existing = Array.from(db.objectStoreNames)
        for (const key of [...modules, ...existing] as StoreModule[]) {
          await this.initializeObjectStore(db, modules, existing, key)
        }
      },
    })

    return db
  }

  private async initializeObjectStore(
    db: IDBPDatabase<StoreSchema>,
    required: StoreModule[],
    existing: string[],
    key: StoreModule
  ) {
    if (required.includes(key)) {
      if (existing.includes(key)) return
      db.createObjectStore(key, { keyPath: 'id' })
    } else {
      db.deleteObjectStore(key)
    }
  }
}
