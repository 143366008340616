import { RequestResult } from 'app/packs/src/api/types'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { getRootStore } from 'store/utils/get-root-store'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'
import { User } from '../users'
import { Outcome } from '../outcomes'

export const UserSkill = baseModel('user_skills')
  .props({
    categoryName: types.maybeNull(types.string),
    focused: types.boolean,
    positionRequirementLevel: types.maybeNull(types.number),
    skill: reference(Skill, { required: true }),
    status: types.union(
      types.literal('exceeding'),
      types.literal('meeting'),
      types.literal('not_checked_in'),
      types.literal('working_towards')
    ),
    user: reference(User, { required: true }),
  })
  .views((self) => ({
    get outcomes(): Outcome[] {
      if (!self.positionRequirementLevel || !self.skill.defaultVariant)
        return []
      return getRootStore(self).outcomes.forLevelAndSkillVariant(
        self.positionRequirementLevel,
        self.skill.defaultVariant.id
      )
    },
  }))
  .actions((self) => ({
    async update(
      attributes: Partial<UserSkillUpdateAttributes>,
      params?: { source: string }
    ): Promise<RequestResult> {
      const root = getRootStore(self)

      const response = await root.userSkills.update(
        self.skill.id,
        {
          ...attributes,
          id: self.id,
        },
        params,
        { namespace: ['users', self.user.id] }
      )

      return response
    },
  }))

export interface UserSkill extends Instance<typeof UserSkill> {}
export interface UserSkillAttributes extends SnapshotIn<typeof UserSkill> {}
export type UserSkillStatus = UserSkillAttributes['status']

type UserSkillUpdateAttributes = { focused: boolean }

type UserSkillStoreOptions = {
  Include: Array<
    | 'skill'
    | 'user'
    | 'skill.skill_variants'
    | 'skill.skill_variants.skill_levels'
    | 'skill.skill_variants.skill_levels.outcomes'
  >
  UpdateAtrributes: UserSkillUpdateAttributes
}

export const UserSkillStore = createStore<
  typeof UserSkill,
  UserSkillStoreOptions
>('UserSkill', UserSkill)
  .actions((store) => ({
    async fetchForUser(userId: string) {
      await store.fetchAll(
        {
          include: [
            'user',
            'skill',
            'skill.skill_variants',
            'skill.skill_variants.skill_levels',
            'skill.skill_variants.skill_levels.outcomes',
          ],
        },
        { namespace: ['users', userId] }
      )
    },
    async fetchForUsers(userIds: string[]) {
      userIds.flatMap((userId) => this.fetchForUser(userId))
    },
  }))
  .views((store) => ({
    focusedForUser(userId: string) {
      return store.filtered(
        (userSkill) => userSkill.focused && userSkill.user.id === userId
      )
    },
    unfocusedForUser(userId: string) {
      return store.filtered(
        (userSkill) => !userSkill.focused && userSkill.user.id === userId
      )
    },
    focusedSkillIdsForUser(userId: string) {
      return store.all.reduce<string[]>((skillIds, userSkill) => {
        if (userSkill.focused && userSkill.user.id === userId) {
          skillIds.push(userSkill.skill.id)
        }

        return skillIds
      }, [])
    },
    forUser(userId: string) {
      return store.all.filter((userSkill) => userSkill.user.id === userId)
    },
    requiredForUser(userId: string) {
      return store.all.filter(
        (userSkill) =>
          userSkill.user.id === userId && userSkill.positionRequirementLevel
      )
    },
    forSkillAndUser(skillId: string, userId: string) {
      return store.all.find(
        (userSkill) =>
          userSkill.skill.id === skillId && userSkill.user.id === userId
      )
    },
    requiredPointsForUser(userId: string) {
      return this.requiredForUser(userId).reduce((points, userSkill) => {
        if (userSkill.positionRequirementLevel) {
          points += userSkill.positionRequirementLevel
        }

        return points
      }, 0)
    },
    sortedByFocusedForSkillsAndUser(skillIds: string[], userId: string) {
      const focusSkillIds = this.focusedSkillIdsForUser(userId)

      const userSkills = store.filtered(
        (userSkill) =>
          skillIds.includes(userSkill.skill.id) && userSkill.user.id === userId
      )

      return userSkills.sort((a, b) => {
        // Return focused first (a)
        if (
          focusSkillIds.includes(a.skill.id) &&
          !focusSkillIds.includes(b.skill.id)
        ) {
          return -1
        }

        // Return focused first (b)
        if (
          !focusSkillIds.includes(a.skill.id) &&
          focusSkillIds.includes(b.skill.id)
        ) {
          return 1
        }

        // Return name ascending if both are focused or not focused
        return a.skill.name.localeCompare(b.skill.name)
      })
    },
    sortedByListPositionForUser(userId: string) {
      const userSkills = this.forUser(userId)

      const { users } = getRootStore(store)
      const user = users.byId(userId)
      const fss = user?.frameworksSkills ?? []

      if (fss.length === 0) return userSkills

      return userSkills.sort((a, b) => {
        const afs = fss.find((fs) => fs.skill?.id === a.skill.id)
        const bfs = fss.find((fs) => fs.skill?.id === b.skill.id)
        const emptyPosition = 10000

        // ORDER BY categories.listPosition, frameworks_skills.listPosition, skills.name ASC
        return (
          (afs?.category?.listPosition ?? emptyPosition) -
            (bfs?.category?.listPosition ?? emptyPosition) ||
          (afs?.listPosition ?? emptyPosition) -
            (bfs?.listPosition ?? emptyPosition) ||
          a.skill.name.localeCompare(b.skill.name)
        )
      })
    },
    sortedByStatusForUser(userId: string) {
      const statusOrder = {
        working_towards: 0,
        not_checked_in: 1,
        meeting: 2,
        exceeding: 3,
      }
      const userSkills = this.forUser(userId)

      return userSkills.sort((a, b) => {
        return statusOrder[a.status] - statusOrder[b.status]
      })
    },
    suggestedForUser(userId: string, includeAll = false) {
      const sortedSkills = this.sortedByStatusForUser(userId).filter(
        (userSkill) =>
          !userSkill.focused && userSkill.positionRequirementLevel !== null
      )

      if (includeAll) return sortedSkills

      return sortedSkills.slice(0, 3 - this.focusedForUser(userId).length)
    },
  }))
