import * as React from 'react'
import cn from 'classnames'
import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { Overlay } from '../../atoms/Overlay'
import { Button } from '../../atoms/Button'
import styles from './ConfirmationDialog.module.scss'
import { fadeVariant } from '../../../utils/variants'
import { motion } from 'framer-motion'

export type ConfirmationDialogProps = {
  /**
   * Text to show as the title of the dialog
   */
  title?: string
  /**
   * Text to show as the body of the dialog
   */
  body?: string
  /**
   * Text to show as the label of the confirm button
   * @default "Confirm"
   */
  confirmLabel?: string
  /**
   * Text to show as the label of the cancel button
   * @default "Cancel"
   */
  cancelLabel?: string
  /**
   * Callback to be called once the dialog has been confirmed. If the dialog is cancelled, this won't be called
   */
  onConfirm?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void> | void
  /**
   * Callback to be called once the dialog has been cancelled.
   */
  onCancel?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void> | void
  /**
   * Component to use as a trigger
   */
  children?: JSX.Element
  contentClassName?: string
  showOverlay?: boolean
} & Pick<AlertDialog.DialogProps, 'onOpenChange' | 'defaultOpen' | 'open'>

export const Root: React.VFC<ConfirmationDialogProps> = (props) => {
  const {
    onOpenChange,
    open,
    defaultOpen,
    onConfirm,
    onCancel,
    title,
    body,
    children,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    contentClassName,
    showOverlay = true,
  } = props

  return (
    <AlertDialog.Root
      open={open}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
    >
      {children}
      {showOverlay && (
        <AlertDialog.Overlay asChild>
          <Overlay />
        </AlertDialog.Overlay>
      )}
      <AlertDialog.Content asChild>
        <motion.div
          variants={fadeVariant}
          initial="inactive"
          animate="active"
          exit="inactive"
          className={cn(styles.content, contentClassName)}
          transition={{ duration: 0.2, delay: 0.1 }}
        >
          {title && (
            <AlertDialog.Title className={styles.title}>
              {title}
            </AlertDialog.Title>
          )}
          {body && (
            <AlertDialog.Description className={styles.description}>
              {body}
            </AlertDialog.Description>
          )}
          <div className={styles.footer}>
            <AlertDialog.Cancel asChild>
              <Button
                variant="outline"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  onCancel?.(e)
                }
              >
                {cancelLabel}
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button
                className={styles.confirmButton}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  onConfirm?.(e)
                }
              >
                {confirmLabel}
              </Button>
            </AlertDialog.Action>
          </div>
        </motion.div>
      </AlertDialog.Content>
    </AlertDialog.Root>
  )
}

export const Trigger = AlertDialog.Trigger
