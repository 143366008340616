import * as React from 'react'
import cn from 'classnames'
import styles from './Tabs.module.scss'
import { PropsWithoutChildren } from '../../../types/helpers'
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box'
import { Count, CountProps } from '../../..'

export type TabsProps = {
  children: React.ReactNode
  variant?: 'underline' | 'pill'
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'div'>>

export const Tabs: React.VFC<TabsProps> = (props) => {
  const { className, children, variant = 'underline', ...restProps } = props

  return (
    <nav className={cn(styles.tabs, styles[variant], className)} {...restProps}>
      {children}
    </nav>
  )
}

type TabOwnProps = {
  children: React.ReactNode
  href?: string
  active?: boolean
  count?: number
  countBg?: CountProps['boxBg']
}

export type TabProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  TabOwnProps
>

const defaultElement = 'button'

// eslint-disable-next-line react/display-name
export const Tab = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      className,
      active = false,
      count,
      countBg,
      children,
      ...restProps
    }: TabProps<E>,
    innerRef: typeof restProps.ref
  ) => {
    return (
      // @ts-ignore
      <Box
        as={defaultElement}
        type="button"
        className={cn(styles.tab, { [styles.active]: active }, className)}
        aria-current={active ? 'page' : undefined}
        {...restProps}
        ref={innerRef}
      >
        <div className={styles.contentWrapper}>
          {children}

          {count !== undefined && <Count boxBg={countBg}>{count}</Count>}
        </div>
      </Box>
    )
  }
) as <E extends React.ElementType = typeof defaultElement>(
  props: TabProps<E>
) => JSX.Element
