import * as React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { DropdownContext } from './DropdownContext'
import { DEFAULT_ALIGN, DropdownAlignment } from './shared'

export type RootProps = {
  /**
   * Required children prop for the dropdown, this should ideally be composed of other dropdown components such as `Content`, `Trigger`, `Separator` and `Link`
   */
  children: React.ReactNode
  /**
   * Where the text should be aligned in the dropdown
   * @default left
   */
  align?: DropdownAlignment
} & Omit<
  PropsWithoutChildren<DropdownMenu.DropdownMenuProps>,
  'asChild' | '__scopeDropdownMenu'
>

export const Root: React.VFC<RootProps> = (props) => {
  const {
    children,
    align = DEFAULT_ALIGN,
    open: externalOpen = false,
    onOpenChange,
    ...restProps
  } = props

  const [open, setOpen] = React.useState(externalOpen)

  const setOpenAndCallHook = React.useCallback(
    (open: boolean) => {
      setOpen(open)
      onOpenChange?.(open)
    },
    [onOpenChange]
  )

  const toggle = React.useCallback(() => {
    setOpenAndCallHook(!open)
  }, [setOpenAndCallHook])

  React.useEffect(() => {
    setOpen(externalOpen)
  }, [externalOpen])

  return (
    <DropdownContext.Provider value={{ open, toggle, align }}>
      <DropdownMenu.Root
        open={open}
        onOpenChange={setOpenAndCallHook}
        {...restProps}
      >
        {children}
      </DropdownMenu.Root>
    </DropdownContext.Provider>
  )
}
