import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { createStore } from 'store/utils/create-store'
import { Skill } from '../skills'
import { baseModel } from 'store/utils/base-model'
import { Org } from '../orgs'
import { reference } from 'store/utils/reference'
import { compactArray } from 'store/utils/compact-array'

export const Collection = baseModel('collections').props({
  cloneable: types.optional(types.boolean, false, [null, undefined]),
  showInGetStarted: types.optional(types.boolean, false, [null, undefined]),
  name: types.string,
  description: types.string,
  skills: compactArray(types.late(() => reference(Skill))),
  collectionType: types.string, // TODO: union
  imageUrl: types.string,
  author: reference(Org),
  tags: types.array(types.string),
})

export interface Collection extends Instance<typeof Collection> {}
export type CollectionModelAttributes = SnapshotIn<typeof Collection>

type CollectionIncludes = 'skills'

type CollectionStoreOptions = {
  Include: Array<CollectionIncludes>
  Filters: { name?: string | null }
}

export const CollectionStore = createStore<
  typeof Collection,
  CollectionStoreOptions
>('Collection', Collection).views((self) => ({
  get showInGetStarted() {
    return self.all.filter((collection) => collection.showInGetStarted)
  },
}))
