import type { User } from '.'

// function to recurse all reports and collect unique reports
export const collectAllReports = (
  user: { directReports: User[] },
  collection: User[] = []
): User[] => {
  return user.directReports.reduce<User[]>((acc, report) => {
    if (acc.includes(report)) return acc

    acc.push(report)
    if (report !== user) {
      collectAllReports(report, acc).forEach((r) => {
        if (!acc.includes(r)) acc.push(r)
      })
    }

    return acc
  }, collection)
}
