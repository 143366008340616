import * as React from 'react'
import {
  DrawerContext,
  DrawerContextValue,
} from '../../../contexts/drawer-context'

export const DrawerContextProvider: React.VFC<{ children: React.ReactNode }> = (
  props
) => {
  const { children } = props
  const [activeDrawerId, setActiveDrawerId] = React.useState<null | string>(
    null
  )
  const [activeDrawerProps, setActiveDrawerProps] = React.useState<
    Record<string, unknown>
  >({})

  const openDrawer: DrawerContextValue['openDrawer'] = React.useCallback(
    (id, props) => {
      setActiveDrawerId(id)
      if (props) setActiveDrawerProps({ [id]: props })
    },
    []
  )

  const toggleDrawer = React.useCallback(
    (id: string) => {
      activeDrawerId ? closeDrawer() : setActiveDrawerId(id)
    },
    [activeDrawerId]
  )

  const closeDrawer = () => {
    setActiveDrawerId(null)
    setActiveDrawerProps({})
  }

  const getDrawerProps = React.useCallback(
    function <T>(activeDrawerId: string): T {
      const props = activeDrawerProps[activeDrawerId]
      return props ? (props as T) : ({} as T)
    },
    [activeDrawerProps]
  )

  return (
    <DrawerContext.Provider
      value={{
        activeDrawerId,
        activeDrawerProps,
        openDrawer,
        closeDrawer,
        toggleDrawer,
        getDrawerProps,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
