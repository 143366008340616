import { createStore } from 'store/utils/create-store'
import { IAnyModelType, Instance, types } from 'mobx-state-tree'
import { Team } from '../teams'
import { baseModel } from 'store/utils/base-model'
import { reference } from 'store/utils/reference'

export const Doc = baseModel('docs')
  .props({
    kind: types.string,
    visibility: types.string,
    content: types.string,
    team: reference(types.late((): IAnyModelType => Team)),
  })
  .views((self) => ({
    get isReadme(): boolean {
      return self.kind === 'readme'
    },
  }))

export interface Doc extends Instance<typeof Doc> {}

export const DocStore = createStore<typeof Doc>('Doc', Doc)
