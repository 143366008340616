import * as React from 'react'
import cn from 'classnames'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { TabbedContentContext } from './TabContext'
import styles from './Root.module.scss'

export type RootProps = {
  /**
   * Required id for the tab to initially focus
   */
  initialTabId: string | number
  /**
   * Content of the tab root, can optionally accept a function which receives the current active tab and can be used for setting the active tab externally
   */
  children:
    | React.ReactNode
    | ((ctx: {
        activeTab: string | number
        setActiveTab: (tabId: string | number) => void
      }) => React.ReactNode)
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'div'>>

/**
 * The TabbedContent component is a group of related utilities for showing content hidden behind active tabs. It consists of the `Root` component which houses the logic for storing the active state, the `Tabs/Tab` components which together show a grouped section of tabs with each tab requiring a unique id and finally the `Content` component which has a required `tabId` prop which maps it to the relevant tab.
 */
export const Root = React.forwardRef<HTMLDivElement, RootProps>(
  (props, ref) => {
    const { children, className, initialTabId, ...restProps } = props

    const [activeTab, setActiveTab] = React.useState(initialTabId)

    const content =
      children instanceof Function
        ? children({ activeTab, setActiveTab })
        : children

    return (
      <TabbedContentContext.Provider value={{ activeTab, setActiveTab }}>
        <div ref={ref} className={cn(styles.root, className)} {...restProps}>
          {content}
        </div>
      </TabbedContentContext.Provider>
    )
  }
)

Root.displayName = 'TabbedContent.Root'
