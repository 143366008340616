import * as React from 'react'
import { TSkill, TSkillVariant } from '../../../../../types/entities'
import type { SkillsDrawerProps, SkillType } from '../SkillsDrawer.types'

// this is what we expose via context
export type SkillsDrawerContextProps = {
  // internal methods exposed from the provider
  getSkill: (skillId: string, source: 'library' | 'org') => TSkill | undefined
  inCurrentFramework: (skill: TSkill) => boolean
  inCurrentFrameworkAnyVariant: (skillVariants: TSkillVariant[]) => boolean
  inCurrentFrameworkVariant: (variant: TSkillVariant) => boolean
} & Pick<SkillsDrawerProps, 'eventHandlers'>

export const SkillsDrawerContext = React.createContext(
  {} as SkillsDrawerContextProps
)

export const useSkillsDrawer = () => {
  const context = React.useContext(SkillsDrawerContext)

  return context
}
