import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { Pollable } from '../extensions/pollable'
import { RequestResult } from 'app/packs/src/api/types'
import { getRootStore } from 'store/utils/get-root-store'

export const SimpleStringOutput = types.model('simple_string_output', {
  raw: types.string,
  extracted: types.string,
})

export interface SimpleStringOutput
  extends Instance<typeof SimpleStringOutput> {}

export const PositionDescriptionOutput = types.model(
  'position_description_output',
  {
    raw: types.string,
    extracted: types.string,
  }
)
export interface PositionDescriptionOutput
  extends Instance<typeof PositionDescriptionOutput> {}

const ExtractedSkillExampleOutput = types.model(
  'extracted_skill_level_description',
  {
    skillName: types.string,
    skillDescription: types.string,
    skillLevelNumber: types.string,
    skillLevelDescription: types.string,
    skillExampleName: types.string,
    skillExampleDescription: types.string,
  }
)
export const SkillExampleOutput = types.model('skill_example_output', {
  raw: types.string,
  extracted: types.array(ExtractedSkillExampleOutput),
})

export const ExampleOutput = types.model('example_output', {
  skillExampleName: types.string,
  skillExampleDescription: types.string,
})
export const SkillLevelOutput = types.model('skill_level_output', {
  skillLevelDescription: types.string,
  skillLevelNumber: types.string,
  examples: types.array(ExampleOutput),
})
const ExtractedSkillContentOutput = types.model(
  'extracted_skill_level_description',
  {
    skillName: types.string,
    skillDescription: types.string,
    levels: types.array(SkillLevelOutput),
  }
)
export const SkillContentOutput = types.model(
  'skill_content_raw_extracted_output',
  {
    raw: types.string,
    extracted: types.array(ExtractedSkillContentOutput),
  }
)

export const ReflectionPoint = types.model('reflection_point', {
  content: types.string,
  names: types.array(types.string),
  category: types.union(
    types.literal('win'),
    types.literal('action'),
    types.literal('feedback')
  ),
  score: types.optional(types.number, 0),
})
export interface ReflectionPoint extends Instance<typeof ReflectionPoint> {}

export const ExtractedReflectionAnalysisOutput = types.model(
  'extracted_reflection_analysis_output',
  {
    items: types.array(ReflectionPoint),
  }
)
export interface ExtractedReflectionAnalysisOutput
  extends Instance<typeof ExtractedReflectionAnalysisOutput> {}
export const ReflectionAnalysisOutput = types.model(
  'reflection_analysis_output',
  {
    raw: types.string,
    extracted: types.array(ExtractedReflectionAnalysisOutput),
  }
)

const WinsSuggestionItem = types.model('wins_suggestion_item', {
  text: types.string,
})

const ExtractedWinsSuggestionOutput = types.model(
  'extracted_wins_suggestion_output',
  {
    items: types.array(WinsSuggestionItem),
  }
)

export const WinsSuggestionOutput = types.model('wins_suggestion_output', {
  raw: types.string,
  extracted: types.array(ExtractedWinsSuggestionOutput),
})

const ActionsSuggestionItem = types.model('actions_suggestion_item', {
  text: types.string,
  score: types.optional(types.number, 0),
})

const ExtractedActionsSuggestionOutput = types.model(
  'extracted_actions_suggestion_output',
  {
    items: types.array(ActionsSuggestionItem),
  }
)

export const ActionsSuggestionOutput = types.model(
  'actions_suggestion_output',
  {
    raw: types.string,
    extracted: types.array(ExtractedActionsSuggestionOutput),
  }
)

const FocusSkillActionsItem = types.model('focus_skill_actions_item', {
  action: types.string,
})

const ExtractedFocusSkillActionsOutput = types.model(
  'extracted_focus_skill_actions_output',
  {
    items: types.array(FocusSkillActionsItem),
  }
)

export const FocusSkillActionsOutput = types.model(
  'focus_skill_actions_output',
  {
    raw: types.string,
    extracted: types.array(ExtractedFocusSkillActionsOutput),
  }
)

export interface SkillContentOutput
  extends Instance<typeof SkillContentOutput> {}
export interface SkillExampleOutput
  extends Instance<typeof SkillExampleOutput> {}

const ExtractedSkillLevelDescription = types.model(
  'extracted_skill_level_description',
  {
    name: types.string,
    level: types.string,
    original: types.string,
  }
)
export const SkillLevelDescriptionsOutput = types.model(
  'skill_level_descriptions_output',
  {
    raw: types.string,
    extracted: types.array(ExtractedSkillLevelDescription),
  }
)
export interface SkillLevelDescriptionsOutput
  extends Instance<typeof SkillLevelDescriptionsOutput> {}

export const StorySuggestionOutput = types.model('story_suggestion_output', {
  raw: types.string,
  extracted: types.array(
    types.model({
      title: types.string,
      updates: types.array(
        types.model({ id: types.string, type: types.string })
      ),
    })
  ),
})
export interface StorySuggestionOutput
  extends Instance<typeof StorySuggestionOutput> {}

const ChatInput = types.model('chat_input', {
  content: types.string,
  role: types.string,
})

export const AsyncTaskOutput = types.model('async_task_output', {
  skillDescription: types.maybeNull(
    types.model('skill_description_task_output', {
      input: types.array(ChatInput),
      output: SimpleStringOutput,
    })
  ),
  skillLevelDescriptions: types.maybeNull(
    types.model('skill_level_descriptions_task_output', {
      input: types.array(ChatInput),
      output: SkillLevelDescriptionsOutput,
    })
  ),
  skillLevelDescription: types.maybeNull(
    types.model('skill_level_description_task_output', {
      input: types.array(ChatInput),
      output: SimpleStringOutput,
    })
  ),
  positionDescription: types.maybeNull(
    types.model('position_description_task_output', {
      input: types.array(ChatInput),
      output: SimpleStringOutput,
    })
  ),
  skillExamples: types.maybeNull(
    types.model('skill_example_task_output', {
      input: types.array(ChatInput),
      output: SkillExampleOutput,
    })
  ),
  skillContent: types.maybeNull(
    types.model('skill_content_output', {
      input: types.array(ChatInput),
      output: SkillContentOutput,
    })
  ),
  reflectionAnalysis: types.maybeNull(
    types.model('reflection_analysis_output', {
      input: types.array(ChatInput),
      output: ReflectionAnalysisOutput,
    })
  ),
  winsSuggestion: types.maybeNull(
    types.model('wins_suggestion_output', {
      input: types.array(ChatInput),
      output: WinsSuggestionOutput,
    })
  ),
  actionsSuggestion: types.maybeNull(
    types.model('actions_suggestion_output', {
      input: types.array(ChatInput),
      output: ActionsSuggestionOutput,
    })
  ),
  storySummary: types.maybeNull(
    types.model('story_summary_output', {
      input: types.array(ChatInput),
      output: SimpleStringOutput,
    })
  ),
  storyOutline: types.maybeNull(
    types.model('story_outline_output', {
      input: types.array(ChatInput),
      output: SimpleStringOutput,
    })
  ),
  focusSkillActions: types.maybeNull(
    types.model('focus_skill_actions_output', {
      input: types.array(ChatInput),
      output: FocusSkillActionsOutput,
    })
  ),
  storySuggestion: types.maybeNull(
    types.model('story_suggestion_output', {
      input: types.array(ChatInput),
      output: StorySuggestionOutput,
    })
  ),
})
export interface AsyncTaskOutput extends Instance<typeof AsyncTaskOutput> {}

// supported Open AI steps - see OpenAI::Mapper for individual step details
// and AsyncTasks::OpenAI::Creator for steps that are supported
const AISteps = [
  'skill-and-levels-generation',
  'skill-description-generation',
  'position-description-generation',
  'skill-example-generation',
  'skill-level-description-generation',
  'skill-content',
  'reflection-analysis',
  'wins-suggestion',
  'actions-suggestion',
  'story-summary',
  'story-outline',
  'focus-skill-actions',
  'story-suggestion',
] as const
type AIStep = typeof AISteps[number]

type BaseGenerationAttributes = {
  type: 'open_ai'
  attributes: {
    cached?: boolean
  }
}

type AISkillLevelsOrDescriptionGenerationAttributes = {
  step:
    | 'skill-and-levels-generation'
    | 'skill-description-generation'
    | 'skill-content'
  attributes: {
    skillName: string
  }
}

type AISkillLevelDescriptionGenerationAttributes = {
  step: 'skill-level-description-generation'
  attributes: {
    skillName: string
    skillLevel: number
    existingLevelDescriptions?: string[]
  }
}

type AIPositionGenerationAttributes = {
  step: 'position-description-generation'
  attributes: {
    positionName: string
  }
}

type AISkillExampleGenerationAttributes = {
  step: 'skill-example-generation'
  attributes: {
    count: number
    skillName: string
    skillDescription: string
    level: number
    existingExamples: object
    skillLevelDescription: string
  }
}

type AISkillContentGenerationAttributes = {
  step: 'skill-content'
  attributes: {
    skillName: string
    discipline: string
    levelCount: number
    keywords: string[]
  }
}

type ReflectionAnalysisAttributes = {
  step: 'reflection-analysis'
  attributes: {
    content: string
  }
}

type WinsSuggestionAttributes = {
  step: 'wins-suggestion'
  attributes: {
    content: string
  }
}

type ActionsSuggestionAttributes = {
  step: 'actions-suggestion'
  attributes: {
    content: string
  }
}

type StoryItemAttribute = {
  content: string
  type: string
}

type StorySummaryAttributes = {
  step: 'story-summary'
  attributes: {
    updates: StoryItemAttribute[]
  }
}

type StoryOutlineAttributes = {
  step: 'story-outline'
  attributes: {
    updates: StoryItemAttribute[]
  }
}

type FocusSkillActionsAttributes = {
  step: 'focus-skill-actions'
  attributes: {
    skillId: string
    positionId?: string
    level?: number
  }
}

type StorySuggestionAttributes = {
  step: 'story-suggestion'
  attributes: {
    suggestions_limit: number
  }
}

type AsyncTaskCreateAttributes = BaseGenerationAttributes &
  (
    | AISkillLevelsOrDescriptionGenerationAttributes
    | AIPositionGenerationAttributes
    | AISkillExampleGenerationAttributes
    | AISkillLevelDescriptionGenerationAttributes
    | AISkillContentGenerationAttributes
    | ReflectionAnalysisAttributes
    | WinsSuggestionAttributes
    | ActionsSuggestionAttributes
    | StorySummaryAttributes
    | StoryOutlineAttributes
    | FocusSkillActionsAttributes
    | StorySuggestionAttributes
  )

export const BaseAsyncTask = baseModel('async_tasks')
  .props({
    step: types.optional(
      types.enumeration<AIStep>('AIStep', Object.values(AISteps)),
      'skill-and-levels-generation'
    ),
    error: types.maybeNull(types.string),
    aasmState: types.string,
    output: types.optional(AsyncTaskOutput, {}),
  })
  .views((self) => ({
    get isFinished() {
      return self.aasmState === 'completed' || self.aasmState === 'failed'
    },
    get isSuccess() {
      return self.aasmState === 'completed'
    },
    get isFailure() {
      return self.aasmState === 'failed'
    },
    get isPending() {
      return this.isFinished === false
    },
  }))

type AsyncTaskStoreOptions = {
  CreateAttributes: AsyncTaskCreateAttributes
}

export const AsyncTask = types
  .compose(BaseAsyncTask, Pollable)
  .named('async_tasks')
  .actions((self) => ({
    async pollOne(): Promise<RequestResult> {
      return self.poll(
        () => {
          const root = getRootStore(self)
          return root.asyncTasks.fetchOne(self.id, {}, { bypassCache: true })
        },
        () => {
          const root = getRootStore(self)
          return root.asyncTasks.byId(self.id)?.isFinished || false
        },
        1000,
        60
      )
    },
  }))

export interface AsyncTask extends Instance<typeof AsyncTask> {}
export interface AsyncTaskAttributes extends SnapshotIn<typeof AsyncTask> {}

export const AsyncTaskStore = createStore<
  typeof AsyncTask,
  AsyncTaskStoreOptions
>('AsyncTask', AsyncTask)
