import { camelize } from '@ridi/object-case-converter'
import toCamelCase from 'to-camel-case'
import { JSONAPIDoc as JSONAPIDocSchema } from '../contract'
import { Attributes, JSONAPIData, JSONAPIDoc, StoreResource } from '../types'

export const deserialize = (data: unknown) => {
  if (data === null) return data
  return new Deserializer(data).deserialize()
}

export type DeserializeResult = ReturnType<typeof deserialize>

export class Deserializer {
  private doc: JSONAPIDoc
  private result: StoreResource = {}

  constructor(data: unknown) {
    this.doc = JSONAPIDocSchema.parse(data)
  }

  deserialize() {
    const { meta, links } = this.doc

    if (!this.doc.data) return { meta, links, data: null }

    if (Array.isArray(this.doc.data)) {
      this.doc.data.forEach((data) => this.deserializeData(data))
    } else {
      this.deserializeData(this.doc.data)
    }

    if (this.doc.included) {
      this.doc.included.forEach((data) => this.deserializeData(data))
    }

    return { meta, links, data: camelize<StoreResource>(this.result) }
  }

  saveObject(key: string, data: Attributes) {
    this.result[key] = {
      ...this.result[key],
      [data.id]: camelize<Attributes>(data, { recursive: true }),
    }
  }

  deserializeData(doc: JSONAPIData) {
    const { id, attributes, relationships } = doc
    if (!id) return

    const type = doc.type
    const result = { id, ...attributes } as Attributes

    if (relationships) {
      for (const key in relationships) {
        const value = relationships[key]
        if (Array.isArray(value.data)) {
          result[key] = value.data.map((item) =>
            this.relationshipReference(item.id, item.type)
          )
        } else if (value.data) {
          result[key] = this.relationshipReference(
            value.data.id,
            value.data.type
          )
        } else {
          result[key] = null
        }
      }
    }

    this.saveObject(type, result)
  }

  private relationshipReference(id: string, type: string) {
    return `${id}|${toCamelCase(type)}`
  }
}
