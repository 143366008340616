import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'

export const ContentLicenseGrant = baseModel('content_license_grants').props({
  description: types.maybeNull(types.string),
  licenseDescription: types.maybeNull(types.string),
  licenseLink: types.maybeNull(types.string),
  licenseName: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
})

export interface ContentLicenseGrant
  extends Instance<typeof ContentLicenseGrant> {}

export type ContentLicenseGrantModelAttributes = SnapshotIn<
  typeof ContentLicenseGrant
>

export const ContentLicenseGrantStore = createStore<typeof ContentLicenseGrant>(
  'ContentLicenseGrant',
  ContentLicenseGrant
)
