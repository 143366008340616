import * as React from 'react'
import cn from 'classnames'
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box'
import styles from './Button.module.scss'

export type ButtonOwnProps = {
  /**
   * Size of the button
   * @example <Button size="lg" />
   */
  size?: 'default' | 'sm' | 'lg' | 'nav'
  /**
   * Makes button full width of the container
   */
  fullWidth?: boolean
  /**
   * Which colour variant of the button to use
   * @example <Button colourVariant="danger" />
   */
  colourVariant?:
    | 'theme'
    | 'brand'
    | 'danger'
    | 'paper'
    | 'highlight'
    | 'subtle'
  /**
   * Variant of button. By default this is a regular filled button
   */
  variant?: 'default' | 'outline'
}

export type ButtonProps<E extends React.ElementType> =
  PolymorphicComponentProps<E, ButtonOwnProps>

const defaultElement = 'button'

export const Button = React.forwardRef(function Button<
  E extends React.ElementType = typeof defaultElement
>(
  {
    className,
    size = 'default',
    variant = 'default',
    colourVariant = 'theme',
    fullWidth = false,
    ...restProps
  }: ButtonProps<E>,
  innerRef: typeof restProps.ref
) {
  return (
    // @ts-ignore
    <Box
      as={defaultElement}
      className={cn(
        styles.button,
        styles[size],
        styles[colourVariant],
        fullWidth && styles.fullWidth,
        variant === 'outline' && styles.outline,
        restProps.disabled && styles.disabled,
        className
      )}
      {...restProps}
      ref={innerRef}
    />
  )
}) as <E extends React.ElementType = typeof defaultElement>(
  props: ButtonProps<E>
) => JSX.Element
