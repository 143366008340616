import * as React from 'react'
import cn from 'classnames'
import styles from './EntityCard.module.scss'
import { PropsWithRequiredChildren } from '../../../types/helpers'

export type HeaderProps = PropsWithRequiredChildren<
  React.ComponentPropsWithoutRef<'div'>
>

export const Header: React.VFC<HeaderProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <div className={cn(styles.header, className)} {...restProps}>
      {children}
    </div>
  )
}
