import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { Comment } from '../comments'
import { createStore } from 'store/utils/create-store'
import { FrameworksSkill } from '../frameworks-skills'
import { getRootStore } from 'store/utils/get-root-store'
import { reference } from 'store/utils/reference'
import { Requirement } from '../requirements'
import { Skill } from '../skills'
import { SkillLevel } from '../skill-levels'
import { Team } from '../teams'

export const SkillVariant = baseModel('skill_variants')
  .props({
    default: types.optional(types.boolean, false),
    name: types.string,
    skill: reference(types.late((): IAnyModelType => Skill)),
  })
  .views((self) => ({
    get comments(): Comment[] {
      return getRootStore(self).comments.filtered(
        (comment) => comment.commentable === self
      )
    },
    get frameworkIds(): string[] {
      return this.frameworksSkills.reduce<string[]>(
        (frameworkIds, frameworksSkill) => {
          const framework = frameworksSkill.framework

          if (framework && !frameworkIds.includes(framework.id)) {
            frameworkIds.push(framework.id)
          }

          return frameworkIds
        },
        []
      )
    },
    get frameworksSkills(): FrameworksSkill[] {
      return getRootStore(self).frameworksSkills.forSkillVariant(self.id)
    },
    get fullName() {
      return self.default
        ? self.skill.name
        : `${self.skill.name} (${self.name})`
    },
    get nameWithSkill() {
      return `${self.skill.name} - ${self.name}`
    },
    get maxLevel() {
      const skillLevels = this.skillLevels

      return skillLevels.length === 0
        ? 0
        : Math.max(...skillLevels.map((skillLevel) => skillLevel.level))
    },
    get outcomes() {
      return this.skillLevels.flatMap((skillLevel) => skillLevel.outcomes)
    },
    get requirements(): Requirement[] {
      return getRootStore(self).requirements.filtered(
        (requirement: Requirement) => requirement.skillVariant?.id === self.id
      )
    },
    get skillLevels(): SkillLevel[] {
      return getRootStore(self).skillLevels.filtered(
        (skillLevel: SkillLevel) => skillLevel.skillVariant?.id === self.id
      )
    },
    get teams(): Team[] {
      return getRootStore(self).teams.filtered(
        (team) =>
          !!team.framework && this.frameworkIds.includes(team.framework.id)
      )
    },
    get url() {
      return self.default
        ? self.skill.url
        : `${self.skill.url}?skill_variant_id=${self.id}`
    },
  }))

type SkillVariantCreateAttributes = {
  name?: string
  skill: string
}

type SkillVariantIncludes =
  | 'requirements'
  | 'skill'
  | 'skill.content_license_grant'
  | 'skill.frameworks_skills'
  | 'skill.frameworks_skills.category'
  | 'skill.frameworks_skills.framework'
  | 'skill.frameworks_skills.framework.team'
  | 'skill.frameworks_skills.framework.team.org'
  | 'skill.frameworks_skills.skill_variant'
  | 'skill_levels'
  | 'skill_levels.outcomes'

type SkillVariantUpdateAttributes = {
  name: string
}

type SkillVariantStoreOptions = {
  CreateAttributes: SkillVariantCreateAttributes
  Include: SkillVariantIncludes[]
  UpdateAttributes: SkillVariantUpdateAttributes
}

export interface SkillVariant extends Instance<typeof SkillVariant> {}
export interface SkillVariantAttributes
  extends SnapshotIn<typeof SkillVariant> {}

export const SkillVariantStore = createStore<
  typeof SkillVariant,
  SkillVariantStoreOptions
>('SkillVariant', SkillVariant, {
  hooks: {
    afterDestroy(self) {
      const root = getRootStore(self)

      self.frameworksSkills.forEach((frameworksSkill) => {
        root.frameworksSkills.unload(frameworksSkill.id)
      })
    },
  },
}).views((store) => ({
  get defaultsForOrg() {
    const currentOrgId = getRootStore(store).currentUser?.org?.id

    return currentOrgId
      ? store.filtered(
          (skillVariant) =>
            skillVariant.default && skillVariant.skill?.org?.id === currentOrgId
        )
      : []
  },
  get forOrg() {
    const currentOrgId = getRootStore(store).currentUser?.org?.id

    return currentOrgId
      ? store.filtered(
          (skillVariant) => skillVariant.skill?.org?.id === currentOrgId
        )
      : []
  },
  sortedForSkill(skillId: string) {
    return store
      .filtered((skillVariant: SkillVariant) => {
        return skillVariant.skill?.id === skillId
      })
      .sort((a, b) => {
        if (a.default) return -1
        if (b.default) return 1

        return a.name.localeCompare(b.name)
      })
  },
}))
