import { createStore } from 'store/utils/create-store'
import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { reference } from 'store/utils/reference'
import { StoryItem } from '../story-items'
import { compactArray } from 'store/utils/compact-array'

export const Story = baseModel('stories').props({
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  addingUpdatesStatus: types.union(
    types.literal('manual'),
    types.literal('auto')
  ),
  storyItems: compactArray(
    reference(types.late((): IAnyModelType => StoryItem))
  ),
})

export type StoryCreateAttributes = {
  title?: string
  content?: string
}

type StoryStoreOptions = {
  Include: Array<
    'story_items' | 'story_items.storyable' | 'story_items.storyable.skills'
  >
  CreateAttributes: StoryCreateAttributes
}

export interface Story extends Instance<typeof Story> {}
export type StoryModelAttributes = SnapshotIn<typeof Story>

export const StoryStore = createStore<typeof Story, StoryStoreOptions>(
  'Story',
  Story
)
