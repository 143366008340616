import { PersonAddOutline } from '@easy-eva-icons/react'
import cn from 'classnames'
import * as React from 'react'
import { Card, CardProps } from '../../atoms/Card'
import { IconButton } from '../../atoms/IconButton'
import { Tag } from '../../atoms/Tag'
import { TDiscipline, TPosition } from '../../../types/entities'
import { Tooltip } from '../../atoms/Tooltip'
import { usePositionsTable } from '../../../hooks/use-positions-table'
import { UserAvatarButtonGroup } from '../../molecules/UserAvatarButtonGroup'
import * as ConfirmationDialog from '../../molecules/ConfirmationDialog'
import * as OverflowMenu from '../../atoms/OverflowMenu'
import styles from './PositionCard.module.scss'

export type PositionCardProps = {
  position: TPosition
  parentDiscipline: TDiscipline
  permissions?: {
    allowAssignUserToPosition?: boolean
    allowEditPosition?: boolean
    showHiringLink?: boolean
    showLabels?: boolean
    highlightFirstAddUserTooltip?: boolean
  }
  firstOfType?: boolean
} & Omit<CardProps, 'children' | 'transparent' | 'showHover'>

export const PositionCard: React.VFC<PositionCardProps> = (props) => {
  const {
    position,
    parentDiscipline,
    className,
    permissions,
    firstOfType,
    ...restProps
  } = props

  const [eventHandlers] = usePositionsTable((s) => [s.eventHandlers])

  const showFooter: boolean =
    permissions?.allowEditPosition ||
    permissions?.allowAssignUserToPosition ||
    (position.visibleActiveUsers && position.visibleActiveUsers?.length > 0) ||
    false

  let avatarLimit = 5
  if (permissions?.allowAssignUserToPosition) --avatarLimit
  if (permissions?.allowEditPosition) --avatarLimit

  const [addUserTooltipBlurred, setAddUserTooltipBlurred] =
    React.useState(false)

  const hasDefaultLabels =
    position.defaultLabels && position.defaultLabels.length > 0

  return (
    <Card
      tabIndex={0}
      showHover
      onClick={(e) => {
        e.stopPropagation()
        eventHandlers.onClickPosition?.(position, parentDiscipline)
      }}
      className={cn(styles.positionCard, className)}
      {...restProps}
    >
      <div className={styles.filledContent}>
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.disciplinesWrapper}>
              {permissions?.showLabels && (
                <>
                  {position.customLabel && (
                    <span
                      key={position.customLabel}
                      className={styles.disciplineTag}
                    >
                      {position.customLabel}
                    </span>
                  )}
                  {!position.customLabel && hasDefaultLabels && (
                    <>
                      {position.defaultLabels?.map((label) => (
                        <span key={label} className={styles.disciplineTag}>
                          {label}
                        </span>
                      ))}
                    </>
                  )}
                  {(position.customLabel || hasDefaultLabels) && <>&middot;</>}
                </>
              )}
              <span>{position.requirements?.length || '0'} skills</span>
              {position.hiringLink && permissions?.showHiringLink && (
                <Tag className={styles.disciplineTag} variant="warning">
                  Hiring
                </Tag>
              )}
            </div>
            <ConfirmationDialog.Root
              body="This will permanently remove this position from Progression."
              onCancel={(e) => {
                e.stopPropagation()
              }}
              onConfirm={(e) => {
                e.stopPropagation()
                eventHandlers.onDeletePosition?.(position)
              }}
              title="Are you sure you want to delete this position?"
            >
              <OverflowMenu.Root
                className={styles.overflowMenu}
                contentClassName={styles.overflowMenuContent}
              >
                <OverflowMenu.Item
                  onClick={() => {
                    eventHandlers.onClickPosition?.(position, parentDiscipline)
                  }}
                >
                  View
                </OverflowMenu.Item>
                {permissions?.allowEditPosition && (
                  <>
                    <OverflowMenu.Item
                      onClick={() => {
                        eventHandlers.onDuplicatePosition?.(position)
                      }}
                    >
                      Duplicate
                    </OverflowMenu.Item>
                    <OverflowMenu.Item
                      onClick={() => {
                        eventHandlers.onMovePosition?.(position)
                      }}
                    >
                      Move to<span aria-hidden>...</span>
                    </OverflowMenu.Item>
                    <OverflowMenu.Item
                      onClick={() => {
                        eventHandlers.onAssignUserToPosition?.(position)
                      }}
                    >
                      Add people
                    </OverflowMenu.Item>
                    <ConfirmationDialog.Trigger asChild>
                      <OverflowMenu.Item variant="destructive">
                        Delete
                      </OverflowMenu.Item>
                    </ConfirmationDialog.Trigger>
                  </>
                )}
              </OverflowMenu.Root>
            </ConfirmationDialog.Root>
          </div>
          <a
            className={styles.titleButton}
            href="#"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              eventHandlers.onClickPosition?.(position, parentDiscipline)
            }}
          >
            <h4 className={styles.title}>{position?.name}</h4>
          </a>
        </div>
        {showFooter && (
          <div className={styles.footer}>
            {/* TODO: add spider chart here when this is moved into app */}
            <>&nbsp;</>
            <UserAvatarButtonGroup
              limit={avatarLimit}
              users={position?.visibleActiveUsers}
              onClickTeamMember={eventHandlers.onClickTeamMember}
            >
              {permissions?.allowAssignUserToPosition && (
                <Tooltip
                  content="Add a team member"
                  defaultOpen={
                    firstOfType &&
                    !addUserTooltipBlurred &&
                    permissions?.highlightFirstAddUserTooltip
                  }
                  onOpenChange={(open) => {
                    if (!open) setAddUserTooltipBlurred(true)
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      eventHandlers.onAssignUserToPosition?.(position)
                    }}
                  >
                    <PersonAddOutline aria-hidden />
                    <span className="sr-only">Add a team member</span>
                  </IconButton>
                </Tooltip>
              )}
            </UserAvatarButtonGroup>
          </div>
        )}
      </div>
    </Card>
  )
}
