import { getRoot, IAnyStateTreeNode } from 'mobx-state-tree'
import type { RootState } from '../modules/root'

// Access the root of the tree from any module
export const getRootStore = (store: IAnyStateTreeNode) => {
  const root = getRoot<RootState>(store)
  if (!root) {
    throw new Error('No root found: this helper can only be used in a module')
  }

  return root
}
