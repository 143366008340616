import { IAnyModelType, Instance, types } from 'mobx-state-tree'
import { sort } from 'fast-sort'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { Framework } from '../frameworks'
import { getRootStore } from 'store/utils/get-root-store'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { Team } from '../teams'

export const Discipline = baseModel('disciplines')
  .props({
    clonedFrom: reference(types.late((): IAnyModelType => Discipline)),
    framework: reference(types.late((): IAnyModelType => Framework)),
    listPosition: types.optional(types.number, 1000000, [null, undefined]),
    name: types.string,
  })
  .views((self) => ({
    get initials() {
      return self.name
        .split(' ')
        .map((word) => word[0])
        .join('')
    },
    get positions(): Position[] {
      const positions = getRootStore(self).positions.filtered(
        (position) => position.disciplineIds.includes(self.id),
        { useDefaultFilter: false }
      )

      return sort(positions).asc((p) => p.seniorityLevel)
    },
    get team(): Team | undefined {
      return self.framework?.team
    },
  }))

export type DuplicateDisciplineAttributes = {
  clonedFrom: string
}

export type ModifiableDisciplineAttributes = {
  framework?: string
  name?: string
}

type DisciplineStoreOptions = {
  CreateAttributes: DuplicateDisciplineAttributes
  UpdateAttributes: Partial<ModifiableDisciplineAttributes>
}

export interface Discipline extends Instance<typeof Discipline> {}

export const DisciplineStore = createStore<
  typeof Discipline,
  DisciplineStoreOptions
>('Discipline', Discipline)
