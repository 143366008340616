import * as React from 'react'
import cn from 'classnames'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './Dropdown.module.scss'
import { useDropdownContext } from './DropdownContext'
import { DEFAULT_ALIGN } from './shared'

export type ButtonProps = {
  /**
   * Required children prop for the button, this can just be text like 'Dashboard'
   */
  children: React.ReactNode
  /**
   * Whether the button is active, used to indicate an active state
   */
  active?: boolean
  /**
   * Where the text should be aligned in the dropdown
   * @default left
   */
  textAlign?: React.CSSProperties['textAlign']
  /**
   * Whether or not the link should be disabled
   */
  disabled?: boolean
  /**
   * Variant for visual treatment
   * @default "default"
   */
  variant?: 'default' | 'destructive'
} & Omit<React.ComponentPropsWithoutRef<'button'>, 'children' | 'disabled'>

export const Button = React.forwardRef<HTMLDivElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      active = false,
      textAlign = 'left',
      disabled,
      onClick,
      variant = 'default',
      ...restProps
    } = props

    return (
      <DropdownMenu.Item
        ref={ref}
        disabled={disabled}
        className={cn(styles.link, styles[variant], {
          [styles.active]: active,
          [styles.disabled]: disabled,
        })}
      >
        <button
          disabled={disabled}
          style={{ textAlign }}
          onClick={(e) => {
            if (disabled) return
            onClick?.(e)
          }}
          className={cn(
            disabled && 'pointer-events-none',
            styles.linkContent,
            className
          )}
          {...restProps}
        />
      </DropdownMenu.Item>
    )
  }
)

Button.displayName = 'DropdownButton'
