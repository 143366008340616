import { types } from 'mobx-state-tree'

export const FeatureFlagStore = types
  .model('FeatureFlagStore', {
    data: types.optional(types.map(types.boolean), {}),
  })
  .views((store) => ({
    featureEnabled(name: string) {
      return !!store.data.get(name)
    },
  }))
  .actions((store) => ({
    load(flags: string[]) {
      store.data.replace(
        flags.reduce((acc, flag) => ({ ...acc, [flag]: true }), {})
      )
    },
    set(name: string, value: boolean) {
      store.data.set(name, value)
    },
    async toggle(name: string) {
      const featureEnabled = store.featureEnabled(name)
      await fetch(`/api/v1/internal/feature_flags/${name}`, {
        method: 'PUT',
      })

      this.set(name, !featureEnabled)
    },
    clear() {
      store.data.clear()
    },
  }))
