import * as React from 'react'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Tooltip } from '../../../atoms/Tooltip'
import styles from './SkillImage.module.scss'

export type SkillImageProps = {
  imageUrl: string
  avatarUrl?: string | null
  /**
   * The first letter of this is used inside the fake avatar. The full text is used in the tooltip.
   */
  fallbackAvatarText?: string
  className?: string
}

export const SkillImage: React.VFC<SkillImageProps> = (props) => {
  const { imageUrl, avatarUrl, fallbackAvatarText, className } = props

  return (
    <div className={cn(styles.wrapper, className)}>
      <LazyLoadImage className={styles.image} src={imageUrl} />
      {fallbackAvatarText && (
        <div className={styles.avatar}>
          <Tooltip content={fallbackAvatarText}>
            {avatarUrl ? (
              <img src={avatarUrl} alt="" />
            ) : (
              <span className={styles.tag}>{fallbackAvatarText[0]}</span>
            )}
          </Tooltip>
        </div>
      )}
    </div>
  )
}
