import { Instance, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'

export const WinCategory = baseModel('win_categories').props({
  title: types.union(types.literal('Note'), types.literal('Win')),
})

export interface WinCategory extends Instance<typeof WinCategory> {}

export const WinCategoryStore = createStore<typeof WinCategory>(
  'WinCategory',
  WinCategory
)
