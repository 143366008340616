import * as React from 'react'

/**
 * Returns a ref which contains the current mounted status (true for mounted, false for unmounted).
 * This is useful when dealing with async callbacks which need subsequent state updates—state updates
 * should not be made on unmounted components, so this can be used to check before commiting updates.
 */
export const useMounted = () => {
  const mounted = React.useRef(true)

  React.useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}
