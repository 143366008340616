import cn from 'classnames'
import * as React from 'react'
import styles from './Card.module.scss'

export type CardProps = {
  transparent?: boolean
  /**
   * Whether or not to show hover effects (white background & border)
   */
  showHover?: boolean
  /**
   * Content to place in the footer of the card
   */
  footer?: React.ReactNode
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const Card = React.forwardRef<HTMLAnchorElement, CardProps>(
  (props, ref) => {
    const {
      children,
      className,
      showHover = false,
      transparent = false,
      footer,
      ...restProps
    } = props

    let CardTag: React.ElementType = 'article'
    if (restProps.href) CardTag = 'a'

    return (
      <CardTag
        ref={ref}
        className={cn(
          styles.card,
          {
            [styles.transparent]: transparent,
            [styles.showHover]: showHover,
          },
          className
        )}
        {...restProps}
      >
        {children}
        {footer && (
          <footer className={styles.footerWrapper}>
            <div className={styles.footer}>{footer}</div>
          </footer>
        )}
      </CardTag>
    )
  }
)

Card.displayName = 'Card'
