import * as React from 'react'
import cn from 'classnames'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { EditableFormContext } from './EditableFormContext'
import styles from './EditableForm.module.scss'
import { InputGroup } from '../../..'

export type ErrorMessageProps =
  PropsWithoutChildren<InputGroup.ErrorMessageProps>

export const ErrorMessage: React.VFC<ErrorMessageProps> = (props) => {
  const { className, ...restProps } = props

  const { errorMessage } = React.useContext(EditableFormContext)

  if (!errorMessage) return null

  return (
    <InputGroup.ErrorMessage
      className={cn(styles.errorMessage, className)}
      {...restProps}
    >
      {errorMessage}
    </InputGroup.ErrorMessage>
  )
}
