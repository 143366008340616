import * as React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cn from 'classnames'
import type { PropsWithoutChildren } from '../../../../types/helpers'
import { DragHandle } from '../../../atoms/DragHandle'
import { UNCATEGORISED_ID, useSkills } from './skills'
import { CategoryHeader } from './CategoryHeader'
import { idWithPrefix } from './drag-and-drop'
import { SkillsList } from './SkillsList'
import styles from './Grid.module.scss'

export type CategoryColumnProps = {
  categoryId: string
  isDragOverlay?: boolean
  lockedCategory?: boolean
}

/**
 * Exported category column - will render a draggable column as long as the column isn't a drag overlay and the user has permission to edit
 */
export const CategoryColumn = (props: CategoryColumnProps) => {
  const { categoryId, isDragOverlay = false, lockedCategory } = props

  const { permissions } = useSkills()

  const allowDragging =
    !isDragOverlay &&
    permissions?.allowEdit &&
    (!lockedCategory || permissions?.allowLockingCategories)
  if (allowDragging) return <DraggableColumn categoryId={categoryId} />

  return <Column isDragOverlay={isDragOverlay} categoryId={categoryId} />
}

/**
 * Wrapper around the Column presentational component used to assign draggable attributes to the outer section and inner drag handle (caught via restProps)
 */
const DraggableColumn = ({ categoryId }: CategoryColumnProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: idWithPrefix(categoryId, 'category'),
    data: { type: 'category' },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Column
      categoryId={categoryId}
      ref={setNodeRef}
      style={style}
      className={cn(isDragging && styles.draggedColumn)}
      {...attributes}
      {...listeners}
    />
  )
}

type ColumnProps = {
  /**
   * Whether or not the column is being dragged as an overlay (on top of all content)
   */
  isDragOverlay?: boolean
} & CategoryColumnProps &
  PropsWithoutChildren<React.ComponentPropsWithRef<'section'>>

/**
 * Base presentational category column
 */
const Column = React.forwardRef<HTMLDivElement, ColumnProps>((props, ref) => {
  const {
    categoryId,
    className,
    style,
    isDragOverlay = false,
    // rest props should only contain listeners/attributes at this point which are passed straight through to the drag handle
    ...restProps
  } = props

  const {
    permissions,
    getSkillVariantIdsInCategory,
    getCategory,
    eventHandlers,
  } = useSkills()

  const skillVariantIds = getSkillVariantIdsInCategory(categoryId)
  const category = getCategory(categoryId)

  const isUncategorised = categoryId === UNCATEGORISED_ID
  const showEmptyState = skillVariantIds.length === 0 && permissions?.allowEdit
  const showAddSkills =
    permissions?.allowEdit &&
    (!category?.locked || permissions?.allowLockingCategories)
  const showDragHandle =
    permissions?.allowEdit &&
    !isUncategorised &&
    (!category?.locked || permissions?.allowLockingCategories)

  return (
    <section
      id={isUncategorised ? 'uncategorisedColumn' : undefined}
      className={cn(
        styles.column,
        isDragOverlay && styles.columnOverlay,
        className
      )}
      ref={ref}
      style={style}
    >
      {showDragHandle && (
        <DragHandle show className={styles.categoryDragHandle} {...restProps} />
      )}
      <CategoryHeader
        category={category}
        allowEdit={permissions?.allowEdit}
        allowLockingCategories={permissions?.allowLockingCategories}
        onDeleteCategory={eventHandlers?.onDeleteCategory}
        onEditCategory={eventHandlers?.onEditCategory}
        onToggleLockCategory={eventHandlers?.onToggleLockCategory}
        showAddSkills={showAddSkills}
      />
      {showEmptyState && (
        <p className={styles.emptyCategoryMessage}>
          {isUncategorised
            ? 'No uncategorised skills'
            : 'No skills in this category'}
        </p>
      )}
      <SkillsList
        lockedCategory={category?.locked}
        categoryId={categoryId}
        skillVariantIds={skillVariantIds}
        showAddSkills={showAddSkills}
      />
    </section>
  )
})

Column.displayName = 'Column'
