import * as React from 'react'
import cn from 'classnames'
import { EditableFormContext } from './EditableFormContext'
import styles from './EditableForm.module.scss'
import {
  Input as RawInput,
  InputProps as RawInputProps,
} from '../../atoms/Input'

export type InputProps = RawInputProps

export const Input: React.VFC<InputProps> = (props) => {
  const { className, onChange, defaultValue, name, ...restProps } = props

  const { errorMessage, setErrorMessage } =
    React.useContext(EditableFormContext)

  return (
    <RawInput
      naked
      className={cn(styles.input, className)}
      autoFocus
      isInvalid={!!errorMessage}
      onChange={(e) => {
        setErrorMessage('')
        onChange?.(e)
      }}
      {...restProps}
    />
  )
}
