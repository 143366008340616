import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { sortBy } from 'src/design-system'
import { baseModel } from 'store/utils/base-model'
import { Category } from '../categories'
import { Comment } from '../comments'
import { compactArray } from 'store/utils/compact-array'
import { createStore } from 'store/utils/create-store'
import { Discipline } from '../disciplines'
import { FrameworksSkill } from '../frameworks-skills'
import { getRootStore } from 'store/utils/get-root-store'
import { Org } from '../orgs'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { Requirement } from '../requirements'
import { Skill } from '../skills'
import { SkillVariant } from '../skill-variants'
import { Team } from '../teams'

export const Framework = baseModel('frameworks')
  .props({
    cloneable: types.optional(types.boolean, false, [null, undefined]),
    categories: compactArray(reference(Category)),
    displayOrg: types.maybeNull(reference(Org)),
    frameworksSkills: compactArray(
      reference(types.late((): IAnyModelType => FrameworksSkill))
    ),
    imageUrl: types.maybeNull(types.string),
    name: types.string,
    origin: reference(types.late((): IAnyModelType => Framework)),
    publicUrl: types.maybeNull(types.string),
    showInGetStarted: types.optional(types.boolean, false, [null, undefined]),
    slug: types.string,
  })
  .views((self) => ({
    get disciplines(): Discipline[] {
      return sortBy(
        getRootStore(self).disciplines.filtered(
          (discipline) => discipline.framework?.id === self.id
        ),
        'listPosition'
      )
    },
    get positions(): Position[] {
      return getRootStore(self).positions.filtered(
        (position) => position.framework?.id === self.id,
        { useDefaultFilter: false }
      )
    },
    get skills() {
      return self.frameworksSkills.reduce<Skill[]>((acc, frameworksSkill) => {
        if (frameworksSkill && frameworksSkill.skill) {
          acc.push(frameworksSkill.skill)
        }
        return acc
      }, [])
    },
    get requirements() {
      return this.positions.flatMap<Requirement>(
        (position) => position.requirements
      )
    },
    get uncategorisedSkills() {
      return self.frameworksSkills.reduce<SkillVariant[]>(
        (acc, frameworksSkill) => {
          if (!frameworksSkill.category && frameworksSkill.skillVariant) {
            acc.push(frameworksSkill.skillVariant)
          }
          return acc
        },
        []
      )
    },
    categorySkills(categoryId: string) {
      return self.frameworksSkills.reduce<SkillVariant[]>(
        (acc, frameworksSkill) => {
          if (
            frameworksSkill.category?.id == categoryId &&
            frameworksSkill.skillVariant
          ) {
            acc.push(frameworksSkill.skillVariant)
          }
          return acc
        },
        []
      )
    },
    get team(): Team | undefined {
      return getRootStore(self)
        .teams.filtered(() => true, { useDefaultFilter: false })
        .find((team) => team.framework?.id === self.id)
    },
    get comments(): Comment[] {
      return getRootStore(self).comments.filtered((comment) => {
        return comment.frameworkIds.includes(self.id)
      })
    },
    get sortedCategories() {
      return self.categories.slice().sort((a, b) => {
        if (a.listPosition === b.listPosition) {
          return a.id.localeCompare(b.id)
        }
        return a.listPosition - b.listPosition
      })
    },
  }))

export interface Framework extends Instance<typeof Framework> {}
export type FrameworkModelAttributes = SnapshotIn<typeof Framework>

type FrameworkIncludes =
  | 'disciplines'
  | 'positions'
  | 'positions.requirements'
  | 'positions.disciplines'
  | 'positions.users'
  | 'categories'
  | 'frameworks_skills'
  | 'frameworks_skills.category'
  | 'frameworks_skills.skill_variant'
  | 'frameworks_skills.skill_variant.skill'
  | 'frameworks_skills.skill_variant.skill_levels'
  | 'frameworks_skills.skill_variant.skill_levels.outcomes'

type FrameworkStoreOptions = {
  Include: Array<FrameworkIncludes>
  Filters: { name?: string | null; library?: true | null }
}

export const FrameworkStore = createStore<
  typeof Framework,
  FrameworkStoreOptions
>('Framework', Framework, {
  bootstrap: {
    relationships: ['frameworks_skills', 'categories'],
  },
  hooks: {
    afterDestroy(self) {
      const root = getRootStore(self)
      self.positions.forEach((position) => {
        root.positions.unload(position.id)
      })
    },
  },
}).views((self) => ({
  get showInGetStarted() {
    return self.all.filter((collection) => collection.showInGetStarted)
  },
}))
