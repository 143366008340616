import { IAnyType, IArrayType, types } from 'mobx-state-tree'
import { NonNullable } from 'store/types'

/**
 * A MST type which stores an array of values,
 * but filters out nullable values when accessed.
 *
 * WARNING: this relies on MST internals. Here by dragons!
 */
export const compactArray = <Type extends IAnyType>(type: Type) =>
  new Proxy(types.array(type), {
    get(target, prop) {
      const orig = Reflect.get(target, prop)
      if (prop === 'getValue') {
        return new Proxy(orig, {
          apply(getValueTarget, thisArg, argumentsList) {
            const value: unknown[] = Reflect.apply(
              getValueTarget,
              thisArg,
              argumentsList
            )
            return value.filter((v) => !!v)
          },
        })
      }
      return orig
    },
  }) as unknown as IArrayType<NonNullable<Type>>
