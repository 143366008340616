import { IAnyType, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { reference } from 'store/utils/reference'
import { User } from '../users'

export const AllowedActivityRecordTypes = [
  'Action',
  'Checkin',
  'FeedbackItem',
  'FeedbackRequest',
  'PositionChange',
  'SkillsUser',
  'Win',
] as const

export type ActivityRecordType = typeof AllowedActivityRecordTypes[number]

export const createActivity = <
  Record extends IAnyType,
  RecordType extends string
>(
  record: Record,
  type: RecordType
) =>
  baseModel('activities').props({
    action: types.string,
    recordType: types.literal(type),
    creator: reference(User, { required: true }),
    record: record,
  })
