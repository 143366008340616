/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getRoot,
  IMaybeNull,
  IReferenceType,
  resolveIdentifier,
  types,
  ReferenceOptions,
  resolvePath,
  IAnyStateTreeNode,
  IAnyModelType,
  ITypeUnion,
} from 'mobx-state-tree'
import { getModelTypes } from '../../api/utils/get-model-type'

type ReferenceableType = IAnyModelType | ITypeUnion<any, any, any>

/**
 * A reference type with a custom resolver to handle polymorphic and missing relationships
 *
 * @remarks
 * Mobx state tree doesn't support missing relationships upon initialization,
 * only relationships which become missing after initialization. This type
 * safely attempts to resolve the relationship without throwing an error,
 * and types the property as potentially undefined.
 **/
export function reference<Model extends ReferenceableType>(
  model: Model,
  options: { required: true } & Partial<ReferenceOptions<Model>>
): IReferenceType<Model>

export function reference<Model extends ReferenceableType>(
  model: Model,
  options?: { required?: false } & Partial<ReferenceOptions<Model>>
): IMaybeNull<IReferenceType<Model>>

export function reference<Model extends ReferenceableType>(
  model: Model,
  {
    required,
    ...options
  }: { required?: boolean } & Partial<ReferenceOptions<Model>> = {
    required: false,
  }
) {
  // eslint-disable-next-line local-rules/disallow-types-reference
  const referenceType = types.reference(model, {
    get(identifier, parent) {
      const root = getRoot(parent)
      const [id, type] = `${identifier}`.split('|')

      const types = getModelTypes(model)
      const found = resolveModels(id, type, root, types)
      if (found || !required) return found

      throw new Error(
        `Could not resolve model ${model.name} ${
          type ? `of type \`${type}\`` : ''
        } with id ${id}`
      )
    },
    set(value) {
      return value.id
    },
    ...options,
  })

  if (required) return referenceType
  return types.maybeNull(referenceType)
}

const resolveModels = (
  id: string,
  type: string,
  root: IAnyStateTreeNode,
  model: IAnyModelType[]
) => {
  for (const m of model) {
    const found = resolveModel(id, type, root, m)
    if (found) return found
  }
  return undefined
}

const resolveModel = (
  id: string,
  type: string,
  root: IAnyStateTreeNode,
  model: IAnyModelType
) => {
  const found = type
    ? resolveWithType(id, type, root, model)
    : resolveIdentifier(model, root, id) // deprecated

  return found
}

const resolveWithType = (
  id: string,
  type: string,
  root: IAnyStateTreeNode,
  model: IAnyModelType
) => {
  try {
    const found = resolvePath(root, `/${type}/data/${id}`)
    if (found && model.is(found)) return found
    return undefined
  } catch (error) {
    return undefined
  }
}
