import * as React from 'react'
import cn from 'classnames'
import styles from './Input.module.scss'

export type InputProps = {
  isInvalid?: boolean
  naked?: boolean
} & React.ComponentPropsWithRef<'input'>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { className, isInvalid = false, naked = false, ...restProps } = props

    return (
      <input
        className={cn(
          styles.input,
          {
            [styles.isInvalid]: isInvalid,
            [styles.naked]: naked,
          },
          className
        )}
        aria-invalid={isInvalid || undefined}
        {...restProps}
        ref={ref}
      />
    )
  }
)

Input.displayName = 'Input'
