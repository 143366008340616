import * as React from 'react'
import cn from 'classnames'
import styles from './EntityCard.module.scss'
import { PropsWithoutChildren } from '../../../types/helpers'

export type SeparatorProps = PropsWithoutChildren<
  React.ComponentPropsWithoutRef<'span'>
>

export const Separator: React.VFC<SeparatorProps> = (props) => {
  const { className, ...restProps } = props
  return (
    <span className={cn(styles.separator, className)} {...restProps}>
      ·
    </span>
  )
}
