import { Variants } from 'framer-motion'

export const fadeVariant: Variants = {
  active: { opacity: 1 },
  inactive: { opacity: 0 },
}

export const slideLeftVariant: Variants = {
  active: { x: '0%' },
  inactive: { x: '-100%' },
}

export const slideRightVariant: Variants = {
  active: { x: '0%' },
  inactive: { x: '100%' },
}
