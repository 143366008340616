import * as React from 'react'

export type ModalContextValue = {
  activeModalId: null | string
  activeModalProps: null | unknown
  openModal: <Props extends Record<string, unknown>>(
    id: string,
    props?: Props
  ) => void
  closeModal(): void
  getModalProps<T extends Record<string, unknown>>(
    modalId: string
  ): T | { [k in any]: never }
}

export const ModalContext = React.createContext<ModalContextValue>(
  {} as ModalContextValue
)

export const useModalContext = () => {
  const context = React.useContext(ModalContext)

  if (!context)
    throw new Error(
      'useModalContext must be used within a ModalContextProvider or RootProvider'
    )

  return context
}
