/**
 * A utility class to format relative dates
 * @see https://blog.webdevsimplified.com/2020-07/relative-time-format/
 */
export class RelativeDateFormatter {
  static DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ]

  call(start: Date, end: Date = new Date()) {
    let duration = (start.valueOf() - end.valueOf()) / 1000

    for (let i = 0; i <= RelativeDateFormatter.DIVISIONS.length; i++) {
      const division = RelativeDateFormatter.DIVISIONS[i]
      if (Math.abs(duration) < division.amount) {
        return this.formatter.format(Math.round(duration), division.name)
      }
      duration /= division.amount
    }

    return start.toLocaleString()
  }

  private get formatter() {
    return new Intl.RelativeTimeFormat(undefined, {
      numeric: 'auto',
    })
  }
}

export const formatRelative = (
  ...args: Parameters<RelativeDateFormatter['call']>
) => {
  return new RelativeDateFormatter().call(...args)
}
