import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { getRootStore } from 'store/utils/get-root-store'
import { Outcome } from '../outcomes'
import { reference } from 'store/utils/reference'
import { SkillVariant } from '../skill-variants'
import { Skill } from '../skills'

export const SkillLevel = baseModel('skill_levels')
  .props({
    level: types.number,
    name: types.maybeNull(types.string),
    skillVariant: reference(types.late((): IAnyModelType => SkillVariant)),
  })
  .views((self) => ({
    get outcomes(): Outcome[] {
      return getRootStore(self).outcomes.forLevelAndSkillVariant(
        self.level,
        self.skillVariant.id
      )
    },
    get skill(): Skill {
      return self.skillVariant?.skill
    },
  }))

export interface SkillLevel extends Instance<typeof SkillLevel> {}
export interface SkillLevelAttributes extends SnapshotIn<typeof SkillLevel> {}

type SkillLevelFilters = {
  level?: number | number[]
  skill_variant_id?: string | string[]
  updated_since?: Date | null
}

export type ModifiableSkillLevelAttributes = {
  level: number
  name: string
  skillVariant: string
}

type SkillLevelIncludes =
  | 'outcomes'
  | 'skill'
  | 'skill.skill_variants'
  | 'skill.skill_variants.skill_levels'

type SkillLevelStoreOptions = {
  CreateAttributes: ModifiableSkillLevelAttributes
  Filters: SkillLevelFilters
  Include: SkillLevelIncludes[]
  UpdateAttributes: Partial<ModifiableSkillLevelAttributes>
}

export const SkillLevelStore = createStore<
  typeof SkillLevel,
  SkillLevelStoreOptions
>('SkillLevel', SkillLevel, {
  hooks: {
    async afterDestroy(self) {
      const { outcomes, skillLevels } = getRootStore(self)

      const skillLevelOutcomes = outcomes.forLevelAndSkillVariant(
        self.level,
        self.skillVariant?.id
      )
      skillLevelOutcomes.forEach((outcome) => outcomes.unload(outcome.id))

      self.skill.skillVariants.forEach((skillVariant) => {
        const skillLevel = skillLevels.forLevelAndSkillVariant(
          self.level,
          skillVariant.id
        )
        if (skillLevel) {
          skillLevels.unload(skillLevel.id)
        }
      })
    },
  },
}).views((store) => ({
  forLevelAndSkillVariant(level: number, skillVariantId: string) {
    return store.all.find(
      (skillLevel) =>
        skillLevel.level === level &&
        skillLevel.skillVariant?.id === skillVariantId
    )
  },
  forSkillVariant(skillVariantId: string) {
    return store.filtered(
      (skillLevel) => skillLevel.skillVariant?.id === skillVariantId
    )
  },
  sortedForSkillVariant(skillVariantId: string) {
    return this.forSkillVariant(skillVariantId).sort(
      (a, b) => a.level - b.level
    )
  },
}))
