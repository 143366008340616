import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { reference } from 'store/utils/reference'
import { Position } from '../positions'
import { FrameworksSkill } from '../frameworks-skills'
import { SkillLevel } from '../skill-levels'

export const Requirement = baseModel('requirements')
  .props({
    level: types.number,
    frameworksSkill: reference(
      types.late((): IAnyModelType => FrameworksSkill)
    ),
    position: reference(types.late((): IAnyModelType => Position)),
  })
  .views((self) => ({
    get skill() {
      return this.skillVariant?.skill
    },
    get skillVariant() {
      return self.frameworksSkill?.skillVariant
    },
    get skillLevel() {
      return this.skillVariant?.skillLevels?.find(
        (skillLevel: SkillLevel) => skillLevel.level === self.level
      )
    },
  }))

export type RequirementCreateAttributes = {
  fillRequirements?: boolean
  frameworksSkill: string
  level: number
  position: string
}

type RequirementStoreOptions = {
  CreateAttributes: RequirementCreateAttributes
}

export interface Requirement extends Instance<typeof Requirement> {}
export type RequirementModelAttributes = SnapshotIn<typeof Requirement>

export const RequirementStore = createStore<
  typeof Requirement,
  RequirementStoreOptions
>('Requirement', Requirement).views((store) => ({
  forPosition(positionId: string) {
    return store.filtered(
      (requirement) => requirement.position.id === positionId
    )
  },
}))
