import * as React from 'react'
import { HTMLMotionProps, motion } from 'framer-motion'
import { fadeVariant } from '../../../utils/variants'
import styles from './Overlay.module.scss'

export type OverlayProps = HTMLMotionProps<'div'> & {
  delay?: number
}

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  ({ delay = 0.1, ...props }, ref) => (
    <motion.div
      {...props}
      ref={ref}
      variants={fadeVariant}
      initial="inactive"
      animate="active"
      exit="inactive"
      className={styles.overlay}
      transition={{ duration: 0.2, delay }}
    />
  )
)

Overlay.displayName = 'Overlay'
