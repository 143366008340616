import { decamelize } from '@ridi/object-case-converter'
import { stringify } from 'qs'

export type RequestParams = Record<string, unknown>

export type UrlOptions = {
  type: string
  id?: string
  params?: RequestParams
  namespace?: string[]
  version?: string
}

const API_BASE = '/api'

export const url = (
  baseUrl: string,
  { type, id, params, namespace = [], version = 'v1' }: UrlOptions
) => {
  const path = [API_BASE, version, ...namespace, type, id]
    .filter(Boolean)
    .join('/')

  const url = new URL(path, baseUrl)

  if (params) {
    url.search = stringify(decamelize(params, { recursive: true }), {
      arrayFormat: 'comma',
    })
  }

  return url.toString()
}
