import * as React from 'react'
import { pluralize } from '../../../../utils/string-manipulation'
import { Tag } from '../../../atoms/Tag'

export type MappedTagProps = {
  tags: string[]
  characterLimit: number
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

export const MappedTags: React.VFC<MappedTagProps> = (props) => {
  const { tags, characterLimit } = props

  let runningTotal = 0
  const mappedTags = tags.reduce(
    (state: { show: string[]; hide: number }, tag: string) => {
      if (runningTotal + tag.length > characterLimit) {
        return { ...state, hide: state.hide + 1 }
      }

      runningTotal += tag.length
      return { ...state, show: [...state.show, tag] }
    },
    { show: [], hide: 0 }
  )

  return mappedTags.show.length > 0 ? (
    <>
      {mappedTags.show.map((tag) => (
        <Tag key={tag} variant="paper">
          {tag}
        </Tag>
      ))}
      {mappedTags.hide > 0 && <Tag variant="paper">+{mappedTags.hide}</Tag>}
    </>
  ) : mappedTags.hide > 0 ? (
    <Tag variant="paper">{pluralize(mappedTags.hide, 'tag', 'tags')}</Tag>
  ) : null
}
