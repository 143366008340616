import { Activity } from './models'
import {
  ActivityTypeFilters,
  filterByAuthorId,
  filterByFromDatetime,
  filterByRecordType,
  filterBySkillId,
  filterByToDatetime,
  filterByUserId,
  sort,
} from './filters'
import { createStore } from 'store/utils/create-store'
import { KeysToCamelCase } from 'app/packs/src/types/helpers'
export * from './models'

type ActivityStoreOptions = {
  Filters: ActivityTypeFilters
  Include: Array<
    | 'creator'
    | 'record'
    | 'record.assessor'
    | 'record.author'
    | 'record.checkin_skills'
    | 'record.reactions'
    | 'record.receiver'
    | 'record.reporter'
    | 'record.skills'
    | 'record.users'
    | 'record.win_category'
    | 'record.winners'
  >
}

export const ActivityStore = createStore<typeof Activity, ActivityStoreOptions>(
  'Activity',
  Activity
)
  .actions((store) => ({
    async fetchAllWithAllIncludes(
      size: number,
      filter?: ActivityTypeFilters,
      number?: number,
      bypassCache?: boolean
    ) {
      return await store.fetchAll(
        {
          filter,
          page: { number, size },
          include: [
            'creator',
            'record',
            'record.assessor',
            'record.author',
            'record.reactions',
            'record.receiver',
            'record.reporter',
            'record.skills',
            'record.users',
            'record.win_category',
            'record.winners',
          ],
        },
        { bypassCache }
      )
    },
  }))
  .views((store) => ({
    get sorted() {
      return sort(store.all)
    },
    filteredActivities(
      filter: KeysToCamelCase<ActivityTypeFilters>,
      limit?: number
    ) {
      // TODO: Remove limit option when we switch to cursor based pagination
      const filtered = Object.keys(filter).reduce<Activity[]>((acc, key) => {
        switch (key) {
          case 'fromDatetime':
            return acc.filter(filterByFromDatetime(filter.fromDatetime))
          case 'skillId':
            return acc.filter(filterBySkillId(filter.skillId))
          case 'recordType':
            return acc.filter(filterByRecordType(filter.recordType))
          case 'toDatetime':
            return acc.filter(filterByToDatetime(filter.toDatetime))
          case 'userId':
            return acc.filter(
              filterByUserId(filter.userId, filter.feedbackType)
            )
          case 'authorId':
            return acc.filter(filterByAuthorId(filter.authorId))
          default:
            return acc
        }
      }, store.all)

      if (!limit) return sort(filtered)

      return sort(filtered).slice(0, limit)
    },
  }))
