import { Instance, types } from 'mobx-state-tree'
import { reference } from 'store/utils/reference'
import { User } from '../users'
import { createNotification } from './factory'

export { AllowedNotificationTypes } from './factory'
export type { NotificationType } from './factory'

// Action Completed
export const ActionCompletedNotification = createNotification(
  reference(User, { required: true }),
  'ActionCompletedNotification'
)

export interface ActionCompletedNotification
  extends Instance<typeof ActionCompletedNotification> {}

// Action Created
export const ActionCreatedNotification = createNotification(
  reference(User, { required: true }),
  'ActionCreatedNotification'
)

export interface ActionCreatedNotification
  extends Instance<typeof ActionCreatedNotification> {}

// Feedback Received
export const FeedbackItemCreatedNotification = createNotification(
  reference(User, { required: true }),
  'FeedbackItemCreatedNotification'
)

export interface FeedbackItemCreatedNotification
  extends Instance<typeof FeedbackItemCreatedNotification> {}

// Feedback Requested
export const FeedbackRequestCreatedNotification = createNotification(
  reference(User, { required: true }),
  'FeedbackRequestCreatedNotification'
)

export interface FeedbackRequestCreatedNotification
  extends Instance<typeof FeedbackRequestCreatedNotification> {}

// Check-in initiated manager
export const CheckinInitiatedManagerNotification = createNotification(
  reference(User),
  'CheckinInitiatedManagerNotification'
)

export interface CheckinInitiatedManagerNotification
  extends Instance<typeof CheckinInitiatedManagerNotification> {}

// Check-in answers updated
export const CheckinAnswersUpdatedNotification = createNotification(
  reference(User),
  'CheckinAnswersUpdatedNotification'
)

export interface CheckinAnswersUpdatedNotification
  extends Instance<typeof CheckinAnswersUpdatedNotification> {}

// Check-in finalised
export const CheckinFinalisedNotification = createNotification(
  reference(User),
  'CheckinFinalisedNotification'
)

export interface CheckinFinalisedNotification
  extends Instance<typeof CheckinFinalisedNotification> {}

// Check-in finalised manager
export const CheckinFinalisedManagerNotification = createNotification(
  reference(User),
  'CheckinFinalisedManagerNotification'
)

export interface CheckinFinalisedManagerNotification
  extends Instance<typeof CheckinFinalisedManagerNotification> {}

// Check-in assessor review reminder
export const CheckinAssessorReviewReminderNotification = createNotification(
  reference(User),
  'CheckinAssessorReviewReminderNotification'
)

export interface CheckinAssessorReviewReminderNotification
  extends Instance<typeof CheckinAssessorReviewReminderNotification> {}

// Check-in joint review reminder
export const CheckinJointReviewReminderNotification = createNotification(
  reference(User),
  'CheckinJointReviewReminderNotification'
)

export interface CheckinJointReviewReminderNotification
  extends Instance<typeof CheckinJointReviewReminderNotification> {}

// Check-in assessor completed
export const CheckinStageCompleteManagerNotification = createNotification(
  reference(User, { required: true }),
  'CheckinStageCompleteManagerNotification'
)

export interface CheckinLowActivityNotification
  extends Instance<typeof CheckinLowActivityNotification> {}

// Check-in assessor completed
export const CheckinLowActivityNotification = createNotification(
  reference(User, { required: true }),
  'CheckinLowActivityNotification'
)

export interface CheckinStageCompleteManagerNotification
  extends Instance<typeof CheckinStageCompleteManagerNotification> {}

// Check-in self completed
export const CheckinStageCompleteNotification = createNotification(
  reference(User),
  'CheckinStageCompleteNotification'
)

export interface CheckinStageCompleteNotification
  extends Instance<typeof CheckinStageCompleteNotification> {}

// Reports high activity
export const ReportsHighActivityNotification = createNotification(
  reference(User),
  'ReportsHighActivityNotification'
)

export interface ReportsHighActivityNotification
  extends Instance<typeof ReportsHighActivityNotification> {}

// Reports low activity
export const ReportsLowActivityNotification = createNotification(
  reference(User),
  'ReportsLowActivityNotification'
)

export interface ReportsLowActivityNotification
  extends Instance<typeof ReportsLowActivityNotification> {}

// Win Created
export const WinCreatedNotification = createNotification(
  reference(User),
  'WinCreatedNotification'
)

export interface WinCreatedNotification
  extends Instance<typeof WinCreatedNotification> {}

// Report Win Created
export const WinCreatedManagerNotification = createNotification(
  reference(User),
  'WinCreatedManagerNotification'
)

export interface WinCreatedManagerNotification
  extends Instance<typeof WinCreatedManagerNotification> {}

// New Comment
export const CommentNotification = createNotification(
  reference(User, { required: true }),
  'CommentNotification'
)

export interface CommentNotification
  extends Instance<typeof CommentNotification> {}

// Win Nudge
export const WinNudgeNotification = createNotification(
  types.null,
  'WinNudgeNotification'
)

export interface WinNudgeNotification
  extends Instance<typeof WinNudgeNotification> {}

// Win Reaction
export const WinReactionNotification = createNotification(
  reference(User, { required: true }),
  'WinReactionNotification'
)

export interface WinReactionNotification
  extends Instance<typeof WinReactionNotification> {}

// types.union only infers up to 9 types at a time
const Notification1 = types.union(
  ActionCompletedNotification,
  ActionCreatedNotification,
  CheckinFinalisedNotification,
  CheckinStageCompleteManagerNotification,
  CheckinStageCompleteNotification,
  CommentNotification,
  WinCreatedNotification,
  WinCreatedManagerNotification,
  WinNudgeNotification
)

const Notification2 = types.union(
  WinReactionNotification,
  FeedbackItemCreatedNotification,
  FeedbackRequestCreatedNotification,
  CheckinAnswersUpdatedNotification,
  CheckinAssessorReviewReminderNotification,
  CheckinJointReviewReminderNotification,
  ReportsHighActivityNotification,
  ReportsLowActivityNotification,
  CheckinLowActivityNotification
)

const Notification3 = types.union(
  CheckinInitiatedManagerNotification,
  CheckinFinalisedManagerNotification
)

export const Notification = types.union(
  Notification1,
  Notification2,
  Notification3
)
export type Notification = Instance<typeof Notification>
