import cn from 'classnames'
import * as Dialog from '@radix-ui/react-dialog'
import {
  AnimatePresence,
  HTMLMotionProps,
  motion,
  useReducedMotion,
  Variants,
} from 'framer-motion'
import * as React from 'react'
import { PropsWithoutChildren } from '../../../types/helpers'
import {
  fadeVariant,
  slideLeftVariant,
  slideRightVariant,
} from '../../../utils/variants'
import { Overlay } from '../../atoms/Overlay'
import styles from './Drawer.module.scss'
import { Footer } from './Footer'
import { useMounted } from '../../../hooks/use-mounted'

export type DrawerContext = {
  onClose(): void
}

type InternalDrawerProps = {
  /**
   * Have the drawer open on initialisation
   */
  defaultOpen?: boolean
  /**
   * Hook called when the user opens the drawer
   */
  onOpen?(): Promise<void> | void
  /**
   * Hook called when the user closes the drawer
   */
  onClose?(): Promise<void> | void
  open?: never
}

type ExternalDrawerProps = {
  /**
   * The controlled state of the drawer
   * To mimic the default open behaviour set this to true outside the component
   * Must be used in conjunction with onOpen and onClose
   */
  open?: boolean
  /**
   * Hook called when the user opens the drawer
   */
  onOpen(): Promise<void> | void
  /**
   * Hook called when the user closes the drawer
   */
  onClose(): Promise<void> | void
  defaultOpen?: never
}

export type DrawerProps = {
  /**
   * The direction the drawer should come from
   */
  direction?: 'left' | 'right'
  /**
   * An element used to open/close the drawer
   */
  trigger?: React.ReactElement
  /**
   * A title of the content of the drawer, to announce when it opens
   */
  title: string
  /**
   * Show or hide the overlay which covers the document content while the drawer is open
   * @defaultValue `true`
   */
  overlay?: boolean
  /**
   * Content of the drawer, optionally accepts a function receiving `DrawerContext`
   */
  children: React.ReactNode | ((ctx: DrawerContext) => React.ReactNode)
  /**
   * Prevent closing the drawer when hitting escape - useful if you need it to stay open whilst hitting escape on something else ie drag and dropping from a skills drawer
   */
  preventCloseOnEsc?: boolean
  /**
   * Whether or not the overlay/drawer should be animated on open/close
   */
  animate?: boolean
} & Omit<PropsWithoutChildren<HTMLMotionProps<'div'>>, 'animate'> &
  (InternalDrawerProps | ExternalDrawerProps)

const getVariants = (
  direction: DrawerProps['direction'],
  reduceMotion: boolean
) => {
  if (reduceMotion) return fadeVariant

  return direction === 'right' ? slideRightVariant : slideLeftVariant
}

const Content = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode
    direction?: DrawerProps['direction']
    animate?: boolean
  } & PropsWithoutChildren<HTMLMotionProps<'div'>>
>(
  (
    { children, className, direction = 'right', animate = true, ...props },
    ref
  ) => {
    const reduceMotion = useReducedMotion()

    return (
      <motion.div
        {...props}
        ref={ref}
        variants={getVariants(direction, !!reduceMotion)}
        initial={animate ? 'inactive' : 'active'}
        animate="active"
        exit={animate ? 'inactive' : 'active'}
        className={cn(styles.drawer, className, styles[direction])}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    )
  }
)

Content.displayName = 'Drawer.Content'

type Drawer = React.VFC<DrawerProps> & {
  Footer: typeof Footer
}
export const Drawer: Drawer = ({
  trigger,
  title,
  children,
  overlay = true,
  defaultOpen = false,
  onOpen: onOpenCallback,
  onClose: onCloseCallback,
  open: externalOpen,
  preventCloseOnEsc = false,
  animate = true,
  ...restProps
}) => {
  const mounted = useMounted()
  const [internalOpen, setInternalOpen] = React.useState(defaultOpen)
  const [forceMount, setForceMount] = React.useState<true | undefined>(
    defaultOpen || externalOpen || undefined
  )
  const onClose = () => {
    if (!preventCloseOnEsc) setInternalOpen(false)
  }

  const content =
    children instanceof Function ? children({ onClose }) : children
  const isUsingExternalState = typeof externalOpen !== 'undefined'

  const open = externalOpen || internalOpen

  React.useEffect(() => {
    if (defaultOpen) onOpenCallback?.()
  }, [defaultOpen, onOpenCallback])

  const toggleOpen = (state: boolean) => {
    if (!isUsingExternalState) {
      setInternalOpen(state)
      if (state) setForceMount(true)
    }
    if (state) {
      onOpenCallback?.()
    } else {
      onCloseCallback?.()
    }
  }

  React.useEffect(() => {
    if (isUsingExternalState && externalOpen) setForceMount(true)
  }, [externalOpen])

  return (
    <Dialog.Root onOpenChange={toggleOpen} open={open} modal={overlay}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>

      <AnimatePresence
        onExitComplete={() => mounted.current && setForceMount(undefined)}
        initial={animate}
      >
        <Dialog.Portal>
          {open && (
            <>
              {overlay && (
                <Dialog.Overlay asChild forceMount={forceMount}>
                  <Overlay />
                </Dialog.Overlay>
              )}
              <Dialog.Content
                asChild
                forceMount={forceMount}
                aria-label={title}
                onEscapeKeyDown={onClose}
                onInteractOutside={(e) => {
                  if (!overlay) e.preventDefault()
                }}
              >
                <Content animate={animate} {...restProps}>
                  {content}
                </Content>
              </Dialog.Content>
            </>
          )}
        </Dialog.Portal>
      </AnimatePresence>
    </Dialog.Root>
  )
}

Drawer.Footer = Footer
