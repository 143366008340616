import { isWithinInterval } from 'date-fns'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { DateType } from 'store/utils/date-type'
import { getRootStore } from 'store/utils/get-root-store'
import { reference } from 'store/utils/reference'
import { Reaction } from '../reactions'
import { Skill } from '../skills'
import { User } from '../users'
import { WinCategory } from '../win_categories'

export const Win = baseModel('wins')
  .props({
    content: types.string,
    tookPlaceOn: DateType,
    reporter: reference(User, { required: true }),
    skills: types.array(reference(Skill, { required: true })),
    winners: types.array(reference(User, { required: true })),
    winCategory: reference(WinCategory),
    reactions: types.array(reference(Reaction, { required: true })),
    visibility: types.union(
      types.literal('only_me'),
      types.literal('reporting_line'),
      types.literal('org')
    ),
  })
  .views((self) => ({
    get skillIds() {
      return self.skills.map((skill) => skill.id)
    },
    get winnerIds() {
      return self.winners.map((winner) => winner.id)
    },
    get isWin() {
      return self.winCategory?.title === 'Win'
    },
    get isNote() {
      return self.winCategory?.title === 'Note'
    },
  }))

type WinCreateAttributes = {
  content: string
  reporter: string
  skills?: string[]
  visibility: Win['visibility']
  winCategory: WinCategory['title']
  winners: string[]
}

export type WinFilters = {
  private_count?: boolean
  reporter?: string
  skill_id?: string | string[]
  took_place_on_from?: string
  took_place_on_to?: string
  updated_since?: Date | null
  win_category_title?: string | string[]
  winner_id?: string | string[]
  winner_or_reporter_id?: string | string[]
}

export type Win = Instance<typeof Win>
export type WinModelAttributes = SnapshotIn<typeof Win>

type WinIncludes =
  | 'reactions'
  | 'reporter'
  | 'skills'
  | 'win_category'
  | 'winners'

type WinStoreOptions = {
  Filter: WinFilters
  CreateAttributes: WinCreateAttributes
  Include: WinIncludes[]
}

export const WinStore = createStore<typeof Win, WinStoreOptions>(
  'Win',
  Win
).views((store) => ({
  /**
   * Win views
   */
  get givenWins() {
    const { currentUser } = getRootStore(store)
    if (!currentUser) return []

    return store.filtered(
      (win) => win.isWin && win.reporter.id === currentUser.id
    )
  },
  forWinners(winnerIds: string[]) {
    return store.filtered(
      (win) =>
        win.isWin &&
        winnerIds.some((winnerId) => win.winnerIds.includes(winnerId))
    )
  },
  forSkillAndWinner(skillId: string, winnerId: string) {
    return store.filtered(
      (win) =>
        win.isWin &&
        win.skillIds.includes(skillId) &&
        win.winnerIds.includes(winnerId)
    )
  },
  forSkillsAndWinners(skillIds: string[], winnerIds: string[]) {
    return store.filtered(
      (win) =>
        win.isWin &&
        win.skillIds.some((skillId) => skillIds.includes(skillId)) &&
        win.winnerIds.some((winnerId) => winnerIds.includes(winnerId))
    )
  },
  forWinnersSinceDateTime(
    winnerIds: string[],
    dateTime: 'all time' | Date,
    skillIds?: string[],
    dateTo: Date = new Date()
  ) {
    let wins: Win[]
    if (skillIds && skillIds.length > 0) {
      wins = this.forSkillsAndWinners(skillIds, winnerIds)
    } else {
      wins = this.forWinners(winnerIds)
    }

    const winsSinceDateTime =
      dateTime === 'all time'
        ? wins
        : wins.filter((win) =>
            isWithinInterval(win.tookPlaceOn, {
              start: dateTime,
              end: dateTo,
            })
          )

    return winsSinceDateTime
  },
  givenSinceDateTime(
    reporterIds: string[],
    dateTime: 'all time' | Date,
    dateTo: Date = new Date()
  ) {
    const wins = store.filtered((win) => {
      return win.isWin && reporterIds.includes(win.reporter.id)
    })

    const winsSinceDateTime =
      dateTime === 'all time'
        ? wins
        : wins.filter((win) =>
            isWithinInterval(win.tookPlaceOn, {
              start: dateTime,
              end: dateTo,
            })
          )

    return winsSinceDateTime
  },
  /**
   * Note views
   */
  notesForUsers(userIds: string[]) {
    return store.filtered(
      (win) =>
        win.isNote && userIds.some((userId) => win.winnerIds.includes(userId))
    )
  },
  notesForSkillsAndUsers(skillIds: string[], userIds: string[]) {
    return store.filtered(
      (win) =>
        win.isNote &&
        win.skillIds.some((skillId) => skillIds.includes(skillId)) &&
        win.winnerIds.some((winnerId) => userIds.includes(winnerId))
    )
  },
  notesForUsersSinceDateTime(
    userIds: string[],
    dateTime: Date | 'all time',
    skillIds?: string[],
    dateTo: Date = new Date(),
    filters?: {
      visibility?: string | null
    }
  ) {
    let notes: Win[]

    if (skillIds && skillIds.length > 0) {
      notes = this.notesForSkillsAndUsers(skillIds, userIds)
    } else {
      notes = this.notesForUsers(userIds)
    }

    const notesSinceDateTime =
      dateTime === 'all time'
        ? notes
        : notes.filter((note) =>
            isWithinInterval(note.tookPlaceOn, {
              start: dateTime,
              end: dateTo,
            })
          )

    const filteredNotes = filters?.visibility
      ? notesSinceDateTime.filter((note) => {
          return note.visibility === filters.visibility
        })
      : notesSinceDateTime

    return filteredNotes
  },
}))
