import * as React from 'react'
import cn from 'classnames'
import styles from './Icon.module.scss'

export type IconSize = 'xs' | 'sm'

export type IconProps = {
  size?: IconSize
  hideBorder?: boolean
  variant?: 'default' | 'grey'
  children: React.ReactNode
} & Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'>

export const Icon: React.VFC<IconProps> = (props) => {
  const {
    size = 'xs',
    hideBorder = false,
    variant = 'default',
    className,
    children,
    ...restProps
  } = props

  return (
    <span
      className={cn(
        styles.icon,
        styles[size],
        styles[variant],
        !hideBorder && styles.border,
        className
      )}
      {...restProps}
    >
      {children}
    </span>
  )
}
