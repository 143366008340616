import * as React from 'react'
import { SearchObject } from './Search.types'

export type SearchContextProps = {
  /**
   * Current search object, containing query, sort, filters etc.
   * @example { query: "communication", sort: { id: "name", direction: "asc" } }
   */
  search: SearchObject
  /**
   * Event handler called when any search related content changes such as query, sort, filters etc.
   * @example onSearchChange={(search) => console.log(search.query)}
   */
  onSearchChange?: (search: SearchObject) => void
  /**
   * Internal function for updating the query inside the search object, called during onChange events on the input
   */
  updateSearch: (search: SearchObject) => void
  /**
   * Internal function for resetting the search query inside the search object, called when hitting `Esc` on a keyboard when the input is focused
   */
  resetQuery: () => void
}

export const SearchContext = React.createContext({} as SearchContextProps)
