import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { findForResource } from './utils'
import { Outcome } from '../outcomes'
import { Position } from '../positions'
import { reference } from 'store/utils/reference'
import { Requirement } from '../requirements'
import { Skill } from '../skills'
import { SkillLevel } from '../skill-levels'
import { SkillVariant } from '../skill-variants'
import { User } from '../users'

const Operation = types.model('operation', {
  attribute: types.string,
  previousValue: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
})

export const Change = baseModel('changes').props({
  associatedResource: reference(types.union(Position, SkillVariant)),
  operations: types.array(Operation),
  resource: reference(
    types.union(Outcome, Position, Requirement, Skill, SkillLevel, SkillVariant)
  ),
  resourceId: types.string,
  resourceType: types.union(
    types.literal('Outcome'),
    types.literal('Position'),
    types.literal('Requirement'),
    types.literal('Skill'),
    types.literal('SkillLevel'),
    types.literal('SkillVariant')
  ),
  type: types.union(
    types.literal('create'),
    types.literal('destroy'),
    types.literal('update')
  ),
  user: reference(User),
})

export interface Change extends Instance<typeof Change> {}
export interface ChangeAttributes extends SnapshotIn<typeof Change> {}

type ChangeFilters = {
  resource_id?: string
  resource_type?: string
}

type ChangeIncludes = 'resource' | 'resource.skill' | 'user'

export type ChangeResource =
  | Outcome
  | Position
  | Requirement
  | Skill
  | SkillLevel
  | SkillVariant

type ChangeStoreOptions = {
  Filter: ChangeFilters
  Include: ChangeIncludes[]
}

export type ChangeType = 'create' | 'destroy' | 'update'

export const ChangeStore = createStore<typeof Change, ChangeStoreOptions>(
  'Change',
  Change
)
  .actions((store) => ({
    fetchForResource(resourceId: string, resourceType: string, size: number) {
      store.fetchAll(
        {
          filter: { resource_id: resourceId, resource_type: resourceType },
          page: { size },
        },
        { bypassCache: true }
      )
    },
  }))
  .views((store) => ({
    ownAndAssociatedForResource(resource: ChangeResource) {
      return store.filtered(
        (change) =>
          (change.associatedResource?.id === resource.id &&
            change.associatedResource?._type?.name === resource._type.name) ||
          (change.resource?.id === resource.id &&
            change.resource?._type?.name === resource._type.name)
      )
    },
    forResource(resource: ChangeResource) {
      return findForResource(resource, this).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    },
    forResourceByType(
      resourceId: string,
      resourceType: string,
      type: ChangeType
    ) {
      return store.filtered(
        (change) =>
          change.resourceId === resourceId &&
          change.resourceType === resourceType &&
          change.type === type
      )
    },
  }))
