import React, { createContext } from 'react'
import { Org, User } from '../types/entities'
import { Request } from '../types/request'

export type RailsContext = {
  currentUser: User | null
  request: Request
  org: Org | null
  csrfToken: string
  serverSide: boolean
}

export const RailsContext = createContext<RailsContext>({
  currentUser: null,
  org: null,
  request: {
    baseUrl: '',
    subdomain: '',
    path: '',
    query: {},
    fullpath: '',
  },
  csrfToken: '',
  serverSide: true,
})

export const useRailsContext = (): RailsContext => {
  return React.useContext(RailsContext)
}
