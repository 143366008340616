const alphaRegex = /[^a-zA-Z]/g
const numberRegex = /[^0-9]/g

export const sortAlphaNumberValues = (a: string, b: string) => {
  const aA = a.replace(alphaRegex, '')
  const bA = b.replace(alphaRegex, '')

  if (aA === bA) {
    const aN = parseInt(a.replace(numberRegex, ''), 10)
    const bN = parseInt(b.replace(numberRegex, ''), 10)

    return aN === bN ? 0 : aN > bN ? 1 : -1
  } else {
    return aA > bA ? 1 : -1
  }
}
