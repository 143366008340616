import cn from 'classnames'
import * as React from 'react'
import { Checkmark } from '@easy-eva-icons/react'
import { Times } from '../../../icons/Times'
import { IconButton } from '../../atoms/IconButton'
import { Input } from '../../atoms/Input'
import { Textarea } from '../../atoms/Textarea'
import { DeleteButton, DeleteButtonProps } from '../../molecules/DeleteButton'
import * as InputGroup from '../../molecules/InputGroup'
import styles from './EditableTitle.module.scss'
import { Lock } from '@phosphor-icons/react'
import { Tooltip } from '../../atoms/Tooltip'

export type EditableTitleVariant = 'success' | 'warning' | 'info' | 'danger'
export type EditableTitleMode = 'visual' | 'insert'

export type EditableTitleProps = {
  /**
   * Content to put inside the editable title
   * @example <EditableTitle title="Design Management" />
   */
  title: string
  /**
   * Alt text to pass through to screen readers
   * @example <EditableTitle altTitle="Edit discipline name: Design Management" />
   */
  altTitle: string
  /**
   * The level of the header element
   */
  headingLevel?: 1 | 2 | 3 | 4 | 5
  /**
   * Whether or not to show the edit icon
   */
  allowEdit?: boolean
  /**
   * Default mode for the input to start in. This is mainly useful for debugging, but can be used
   * to force the editable title to start in insert (input) mode
   * @example <EditableTitle defaultMode="insert" />
   */
  defaultMode?: EditableTitleMode
  /**
   * Function to validate the updated title. This gets triggered on form submit, and can optionally
   * return an error message to be shown in the input
   * @example const errorMessage = validate(title)
   */
  onEdit?: (
    title: string,
    description?: string
  ) => Promise<string | void> | undefined
  /**
   * Props required for rendering a delete button
   */
  deleteButtonProps?: Pick<
    DeleteButtonProps,
    'entityName' | 'onDelete' | 'warningMessage'
  >
  /**
   * Hook called when the title switches mode, is passed the mode
   */
  onChangeMode?(mode: EditableTitleMode): void
  /**
   * Flag to show a description beneath the title
   */
  showDescription?: boolean
  /**
   * Value of the description if enabled
   */
  description?: string
  /**
   * Placeholder for an empty description input field
   */
  descriptionPlaceholder?: string
  /**
   * Class name to pass through to the title itself. The default `className` is passed to the wrapper div
   */
  titleClassName?: string
  /**
   * Class name to pass through to the input.
   */
  inputClassName?: string
  /**
   * Class name to pass through to the description.
   */
  descriptionClassName?: string
  /**
   * Class name to pass through to the description textarea.
   */
  descriptionTextareaClassName?: string
  /**
   * Whether to show a lock icon next to the title
   */
  locked?: boolean
  /**
   * Content to be shown in the tooltip if the title is locked
   */
  lockedTooltipContent?: string
} & Omit<React.HTMLAttributes<HTMLElement>, 'children' | 'title'>

export const EditableTitle: React.VFC<EditableTitleProps> = (props) => {
  const {
    title: defaultTitle,
    altTitle,
    headingLevel = 3,
    className,
    titleClassName,
    inputClassName,
    descriptionClassName,
    descriptionTextareaClassName,
    allowEdit = true,
    defaultMode = 'visual',
    onEdit,
    deleteButtonProps,
    onCopy,
    onChangeMode,
    showDescription,
    description: defaultDescription,
    descriptionPlaceholder = 'Enter a description',
    onClick,
    locked,
    lockedTooltipContent,
    ...restProps
  } = props

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const editButtonRef = React.useRef<HTMLButtonElement | null>(null)
  const titleRef = React.useRef<HTMLDivElement | null>(null)
  const titleInputRef = React.useRef<HTMLInputElement | null>(null)
  const descriptionRef = React.useRef<HTMLParagraphElement | null>(null)
  const descriptionTextareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const [mode, setMode] = React.useState<EditableTitleMode>(defaultMode)
  const [title, setTitle] = React.useState(defaultTitle)
  const [tempTitle, setTempTitle] = React.useState(defaultTitle)
  const [description, setDescription] = React.useState(defaultDescription)
  const [tempDescription, setTempDescription] =
    React.useState(defaultDescription)

  const [errorMessage, setErrorMessage] = React.useState('')

  const reset = () => {
    setErrorMessage('')
    setTempTitle(title)
    setMode('visual')
  }

  const save = React.useCallback(async () => {
    setErrorMessage('')

    if (tempTitle === title && tempDescription == description) {
      setMode('visual')
    } else {
      const error = await onEdit?.(tempTitle || '', tempDescription)
      if (typeof error === 'string') {
        setErrorMessage(error)
      } else {
        setMode('visual')
        setTitle(tempTitle)
        setDescription(tempDescription)
      }
    }
  }, [tempTitle, tempDescription, onEdit])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    save()
  }

  React.useEffect(() => {
    onChangeMode?.(mode)
  }, [mode])

  React.useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.code === 'Escape') reset()
    }

    const handleClickOutside = (e: FocusEvent) => {
      const clickedInsideForm = formRef.current?.contains(e.target as Node)
      const clickedEditButton = editButtonRef.current?.contains(
        e.target as Node
      )
      const clickedTitle = titleRef.current?.contains(e.target as Node)
      const clickedDescription = descriptionRef.current?.contains(
        e.target as Node
      )

      const clickedVisualContent =
        allowEdit && (clickedTitle || clickedDescription)

      if (clickedEditButton || clickedVisualContent) {
        setMode('insert')
        if (clickedDescription) {
          descriptionTextareaRef.current?.focus()
          // https://stackoverflow.com/a/64980622
          // needed so the cursor gets put at the end of the textarea
          descriptionTextareaRef.current?.setSelectionRange(
            descriptionTextareaRef.current?.value.length,
            descriptionTextareaRef.current?.value.length
          )
        } else {
          titleInputRef.current?.focus()
        }
      } else if (!clickedInsideForm) {
        save()
      }
    }

    window.addEventListener('keyup', handleEsc)
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('keyup', handleEsc)
      window.removeEventListener('click', handleClickOutside)
    }
  }, [mode, save])

  const CustomHeader = `h${headingLevel}` as React.ElementType

  return (
    <>
      {mode === 'visual' && (
        <div className={className}>
          <CustomHeader
            className={cn(
              styles.shared,
              styles.title,
              styles[`h${headingLevel}` as keyof typeof styles],
              titleClassName
            )}
            ref={titleRef}
            {...restProps}
          >
            <span className="">{title}</span>
            {locked && (
              <Tooltip
                className="mt-0.5"
                content={lockedTooltipContent}
                disabled={!lockedTooltipContent}
              >
                <Lock size={16} className="text-gray-600" weight="bold" />
              </Tooltip>
            )}
          </CustomHeader>
          {showDescription && description && (
            <p
              ref={descriptionRef}
              className={cn(styles.description, descriptionClassName)}
            >
              {description}
            </p>
          )}
        </div>
      )}
      {mode === 'insert' && allowEdit && (
        <form onSubmit={onSubmit} ref={formRef} className={className}>
          <InputGroup.Root className={styles.shared}>
            <div className={styles.inputWrapper}>
              <Input
                ref={titleInputRef}
                naked
                className={cn(
                  styles.input,
                  styles[`h${headingLevel}` as keyof typeof styles],
                  inputClassName
                )}
                name="title"
                defaultValue={title}
                isInvalid={!!errorMessage}
                onKeyDown={(e) => {
                  // needed to stop keyboard events from propagating upwards (drag and drop etc.)
                  e.stopPropagation()
                }}
                onChange={(e) => {
                  setErrorMessage('')
                  setTempTitle(e.target.value)
                }}
                aria-describedby={errorMessage ? 'titleError' : undefined}
              />
              <div className={styles.iconWrapper}>
                <IconButton
                  title="Submit new title"
                  hideBorder
                  className={styles.icon}
                  type="submit"
                >
                  <Checkmark />
                </IconButton>
                <IconButton
                  type="button"
                  title="Cancel editing title"
                  hideBorder
                  className={styles.icon}
                  onClick={reset}
                >
                  <Times />
                </IconButton>
                {deleteButtonProps?.onDelete && (
                  <DeleteButton {...deleteButtonProps} />
                )}
              </div>
            </div>
            {showDescription && (
              <Textarea
                ref={descriptionTextareaRef}
                naked
                name="description"
                defaultValue={description}
                onChange={(e) => {
                  setErrorMessage('')
                  setTempDescription(e.target.value)
                }}
                placeholder={descriptionPlaceholder}
                className={cn(styles.description, descriptionTextareaClassName)}
              />
            )}

            <InputGroup.ErrorMessage
              className={styles.errorMessage}
              id="titleError"
            >
              {errorMessage}
            </InputGroup.ErrorMessage>
          </InputGroup.Root>
        </form>
      )}
    </>
  )
}
