import * as React from 'react'
import cn from 'classnames'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './Dropdown.module.scss'
import dropdownStyles from '../../../styles/dropdown.module.scss'
import { useDropdownContext } from './DropdownContext'

export type ContentProps = Omit<
  DropdownMenu.DropdownMenuContentProps,
  'asChild' | '__scopeDropdownMenu'
> & { subMenu?: boolean }

export const Content: React.VFC<ContentProps> = (props) => {
  const { children, className, subMenu, loop = true, ...restProps } = props

  const Component = subMenu ? DropdownMenu.SubContent : DropdownMenu.Content
  const { align } = useDropdownContext()

  return (
    <Component
      align={align === 'right' ? 'end' : align == 'center' ? 'center' : 'start'}
      side="bottom"
      className={cn(
        styles.content,
        dropdownStyles.dropdownAnimation,
        subMenu && styles.subMenuContent,
        className
      )}
      loop={loop}
      {...restProps}
    >
      {children}
    </Component>
  )
}
