import { isWithinInterval } from 'date-fns'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { baseModel } from 'store/utils/base-model'
import { createStore } from 'store/utils/create-store'
import { FeedbackRequest } from '../feedback-request'
import { reference } from 'store/utils/reference'
import { Skill } from '../skills'
import { User } from '../users'

export const FeedbackItem = baseModel('feedback_items').props({
  author: reference(User, { required: true }),
  content: types.string,
  feedbackRequest: reference(FeedbackRequest),
  receiver: reference(User, { required: true }),
  skills: types.array(reference(Skill, { required: true })),
})

export type FeedbackItemCreateAttributes = {
  content: string
  feedbackRequest?: string
  receiver: string
  skills: string[]
  source?: string
}

export type FeedbackItemFilters = {
  created_at_from?: string
  created_at_to?: string
  receiver_id?: string | string[]
  receiver_or_author_id?: string | string[]
  updated_since?: Date | null
}

type FeedbackItemUpdateAttributes = {
  skills: string[]
}

export interface FeedbackItem extends Instance<typeof FeedbackItem> {}
export interface FeedbackItemAttributes
  extends SnapshotIn<typeof FeedbackItem> {}

type FeedbackItemIncludes = 'author' | 'receiver' | 'skills'

type FeedbackItemStoreOptions = {
  CreateAttributes: FeedbackItemCreateAttributes
  Filter?: FeedbackItemFilters
  Includes?: FeedbackItemIncludes[]
  UpdateAttributes: FeedbackItemUpdateAttributes
}

export const FeedbackItemStore = createStore<
  typeof FeedbackItem,
  FeedbackItemStoreOptions
>('FeedbackItem', FeedbackItem).views((store) => ({
  receivedFeedback(users: User[]) {
    const userIds = users.map((user) => user.id)
    return store
      .filtered((feedback) => userIds.includes(feedback.receiver.id))
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
  },
  forUsers(userIds: string[]) {
    return store.filtered((feedbackItem) =>
      userIds.includes(feedbackItem.receiver.id)
    )
  },
  forSkillsAndUsers(skillIds: string[], userIds: string[]) {
    return this.forUsers(userIds).filter((feedbackItem) => {
      return feedbackItem.skills.some((skill) => skillIds.includes(skill.id))
    })
  },
  forUsersSinceDateTime(
    userIds: string[],
    since: Date | 'all time',
    skillIds?: string[],
    dateTo: Date = new Date()
  ) {
    let feedbackItems: FeedbackItem[]

    if (skillIds && skillIds.length > 0) {
      feedbackItems = this.forSkillsAndUsers(skillIds, userIds)
    } else {
      feedbackItems = this.forUsers(userIds)
    }

    const sinceDateTime = feedbackItems.filter((feedbackItem) => {
      return since === 'all time'
        ? true
        : isWithinInterval(feedbackItem.createdAt, {
            start: since,
            end: dateTo,
          })
    })

    return sinceDateTime
  },
  givenSinceDateTime(
    authorIds: string[],
    since: Date | 'all time',
    dateTo: Date = new Date()
  ) {
    const feedbackItems = store.filtered((feedbackItem) => {
      return authorIds.includes(feedbackItem.author.id)
    })

    const feedbackItemsGivenSinceDateTime =
      since === 'all time'
        ? feedbackItems
        : feedbackItems.filter((feedbackItem) =>
            isWithinInterval(feedbackItem.createdAt, {
              start: since,
              end: dateTo,
            })
          )

    return feedbackItemsGivenSinceDateTime
  },
}))
